import { useEffect, useState,useContext,useLayoutEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link,useLocation,useNavigate,useParams } from "react-router-dom";
import axios from "axios";
import UserContext from "../../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { hasPrivilege } from "../../../components/privileges";


const AddGameClient = () =>{
    const navigate = useNavigate();
    const location = useLocation();
    const [pageTitle, setPageTitle] = useState("Add Game Client")
    const [gameName, setGameName] = useState("");
    const [assetFolder, setAssetFolder] = useState("");
    const [templateFile, setTemplateFile] = useState("");
    const [xmlConfig, setXmlConfig] = useState("");
    const [gameIconURL, setGameIconURL] = useState("");
    const [gameBgURL, setGameBgURL] = useState("");
    const [backendVersion, setBackendVersion] = useState(1);
    const [overrideWeapons, setOverrideWeapons] = useState(0);
    const [globalImagePath, setGlobalImagePath] = useState("");
    const [gamePageType,setGamePageType] = useState("new");
    const [weapons, setWeapons] = useState([]);
    const {userToken,databaseURL, privileges} = useContext(UserContext);
    const weaponIds = ["archer","freeze","witch","dragon","peacemaker","sword","bomb","thief","raid","shield"]


    const [gamesWeapons,setGameWeapons] = useState({
        weaponarcher:{weaponId: 1, isActive: 1, key: "archer", name: "Archer", imagepath: "", usagePos: "bottom"},
        weaponfreeze:{weaponId: 2, isActive: 1, key: "freeze", name: "Freeze", imagepath: "", usagePos: "bottom"},
        weaponwitch:{weaponId: 3, isActive: 1, key: "witch", name: "Mirror", imagepath: "", usagePos: "bottom"},
        weapondragon:{weaponId: 4, isActive: 1, key: "dragon", name: "Axe", imagepath: "", usagePos: "bottom"},
        weaponpeacemaker:{weaponId: 5, isActive: 1, key: "peacemaker", name: "Lock", imagepath: "", usagePos: "bottom"},
        weaponsword:{weaponId: 6, isActive: 1, key: "sword", name: "Sword", imagepath: "", usagePos: "bottom"},
        weaponbomb:{weaponId: 7, isActive: 1, key: "bomb", name: "Bomb", imagepath: "", usagePos: "bottom"},
        weaponthief:{weaponId: 8, isActive: 1, key: "thief", name: "Thief", imagepath: "", usagePos: "bottom"},
        weaponraid:{weaponId: 9, isActive: 1, key: "raid", name: "Raid", imagepath: "", usagePos: "bottom"},
        weaponshield:{weaponId: 10, isActive: 1, key: "shield", name: "Shield", imagepath: "", usagePos: "bottom"}
    });
    
    const {id} = useParams();

    useEffect(() =>{
        if(hasPrivilege(privileges,"ADMIN_GAMECLIENTS") === true){
            if(id){
                setPageTitle(`Edit Game Client ${id}`);
                setGamePageType("edit");
                getGameClientData();
            }
        }
    },[])

    if(hasPrivilege(privileges,"ADMIN_GAMECLIENTS") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/tournaments"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }


    const getGameClientData = async() =>{ 
        return await axios
            .get(`${databaseURL}/game-clients/${id}`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                console.log(response)
                if(response.status === 200){
                    setGameName(response.data.data[0].gameName)
                    setAssetFolder(response.data.data[0].assetsFolder)
                    setTemplateFile(response.data.data[0].templateFile)
                    setXmlConfig(response.data.data[0].xmlConfigFile)
                    setGameIconURL(response.data.data[0].gameIconUrl)
                    setBackendVersion(response.data.data[0].backendVersion)
                    setGameBgURL(response.data.data[0].gameBackgroundUrl)
                    if(response.data.data[0].gameWeaponDetail){
                        const weaponDetails = JSON.parse(response.data.data[0].gameWeaponDetail);
                        const weaponDetail = weaponDetails.gamesWeapons;
                        setGlobalImagePath(weaponDetails.globalImagePath)
                        console.log("weaponDetail");
                        console.log(weaponDetail);
                        const weapon = {...gamesWeapons};
           
                        for(let detail in weaponDetail){
                            let weaponName=weaponIds[weaponDetail[detail].weaponId-1];
                            
                            weapon["weapon"+weaponName].name = weaponDetail[detail].name;
                            weapon["weapon"+weaponName].isActive = weaponDetail[detail].isActive;
                            weapon["weapon"+weaponName].imagepath = weaponDetail[detail].imagepath;
                            weapon["weapon"+weaponName].usagePos = weaponDetail[detail].usagePos;
                        }
                        setGameWeapons(weapon);
                        setOverrideWeapons(1)
                    }
                    //getWeapons();
                  } 
            })
            .catch((error)=>{
                console.log("Error getting gameData");
                enqueueSnackbar("Error getting gameData", {variant: 'error'});
                return false;
            })
    }

    const getWeapons = async() =>{ 
        return await axios
            .get(`${databaseURL}/weapons`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setWeapons(response.data.data);
                  } 
            })
            .catch((error)=>{
                console.log("Error getting Weapons");
                enqueueSnackbar("Error getting Weapons", {variant: 'error'});
            
                return false;
            })
    }

    const generateBody = () =>{
        const body = (
            {
                gameName: gameName, 
                assetsFolder:assetFolder, 
                templateFile: templateFile,
                xmlConfigFile: xmlConfig, 
                gameIconUrl:gameIconURL,
                gameBackgroundUrl:gameBgURL,
                backendVersion: parseInt(backendVersion)
            
          }
        );

        if(overrideWeapons === 1){
            let gameWeaponDetail={};
            gameWeaponDetail.gamesWeapons = gamesWeapons;
            gameWeaponDetail.globalImagePath = globalImagePath
            body.gameWeaponDetail = JSON.stringify(gameWeaponDetail);
        }else{
            body.gameWeaponDetail = "";
        }

        return body;
    }

    const saveData = async () =>{
        if(gameName === ""){
            enqueueSnackbar("Please enter a 'Game Name'", {variant: 'error'});
            return;
        }
        if(assetFolder === ""){
            enqueueSnackbar("Please enter a 'asset Folder'", {variant: 'error'});
            return;
        }
        if(templateFile === ""){
            enqueueSnackbar("Please enter a 'template File'", {variant: 'error'});
            return;
        }
        if(gamePageType === "new"){
            await axios
                .post(`${databaseURL}/game-clients`, generateBody(),{headers:{
                    'Authorization':`Bearer ${userToken}`
                }})
                .then((response) => {
                    //navigate(`/games/${response.data.data[0].id}`, {state: { data: response.data.data[0] }});
                    enqueueSnackbar("Game Client created successfully", {variant: 'success'});
                    navigate('/gameclients', { replace: true, state: { from: location } });
                })
                .catch( async (error) => {
                    enqueueSnackbar("Game Client creation has failed", {variant: 'error'});
                    console.log(error);

                }) 
        }else{
            await axios
                .put(`${databaseURL}/game-clients/${id}`, generateBody(),{headers:{
                    'Authorization':`Bearer ${userToken}`
                }})
                .then((response) => {
                    //navigate(`/games/${response.data.data[0].id}`, {state: { data: response.data.data[0] }});
                    enqueueSnackbar("Game client updated successfully", {variant: 'success'});
                    navigate('/gameclients', { replace: true, state: { from: location } });

                })
                .catch( async (error) => {
                    enqueueSnackbar("Game client updated has failed", {variant: 'error'});
                    console.log(error);

                }) 
        }
    }

    const updateWeaponActive = (val, weaponName)=>{
        const weapon = {...gamesWeapons};
        weapon["weapon"+weaponName].isActive = val;
        setGameWeapons(weapon);
    }
    const updateWeaponName = (val, weaponName)=>{
        const weapon = {...gamesWeapons};
        weapon["weapon"+weaponName].name = val;
        setGameWeapons(weapon);
    }
    const updateWeaponImage = (val, weaponName)=>{
        const weapon = {...gamesWeapons};
        weapon["weapon"+weaponName].imagepath = val;
        setGameWeapons(weapon);
    }
    const updateWeaponUsagePos = (val, weaponName)=>{
        const weapon = {...gamesWeapons};
        weapon["weapon"+weaponName].usagePos = val;
        setGameWeapons(weapon);
    }

    return(
        <div className="mt-4">

            <Row className="mb-4">
                <Col xs={6} >
                    <h5>{pageTitle}</h5>
                </Col>
                <Col xs={6} >
                    <div className="text-end"><Link to="/gameclients"><Button size="sm">Back</Button></Link></div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Game Name
                </Col>
                <Col xs={9}>
                   
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setGameName(e.target.value )} value={gameName}></Form.Control>
                               
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Asset Folder
                </Col>
                <Col xs={9}>
                   
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setAssetFolder(e.target.value )} value={assetFolder}></Form.Control>
                               
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Template File
                </Col>
                <Col xs={9}>
                   
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setTemplateFile(e.target.value)} value={templateFile}></Form.Control>
                               
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    XML Config
                </Col>
                <Col xs={9}>
                   
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setXmlConfig(e.target.value )} value={xmlConfig}></Form.Control>
                               
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Game Icon URL
                </Col>
                <Col xs={9}>
                   
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setGameIconURL(e.target.value )} value={gameIconURL}></Form.Control>
                               
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Game Background URL
                </Col>
                <Col xs={9}>
                   
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setGameBgURL(e.target.value )} value={gameBgURL}></Form.Control>
                               
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Backend version
                </Col>
                <Col xs={9}>
                    <Form.Select style={{width:"auto"}} aria-label="gameType-select" size="sm" onChange={(e)=> setBackendVersion(e.target.value) }>
                        <option value={0} selected={backendVersion === 0 ? true : false}>V1</option>
                        <option value={1} selected={backendVersion === 1 ? true : false}>V2</option>
                    </Form.Select>
                   
                </Col>
            </Row>
            <Row className="mt-3 mb-3">
                <Col className="text-center"><Button onClick={saveData}>Save Game</Button> <Link to="/gameclients"><Button variant="secondary">Cancel</Button></Link></Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Weapon Names
                </Col>
                <Col xs={9}>
                    <Form.Check inline label="On" checked={overrideWeapons === 1 ? true : false} name="overrideWeaponsgroup" type="radio" id='overrideWeapons-on' onChange={(e)=> setOverrideWeapons(1)} />
                    <Form.Check inline label="Off" checked={overrideWeapons === 0 ? true : false}  name="overrideWeaponsgroup" type="radio" id='overrideWeapons-off' onChange={(e)=> setOverrideWeapons(0)} />
                    <div style={{fontSize:"10px"}} className="text-muted">Override weapons names and icons</div>

                </Col>
            </Row>
            {overrideWeapons === 1 &&
            <>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Global Image Path
                </Col>
                <Col xs={9}>
                   
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setGlobalImagePath(e.target.value )} value={globalImagePath}></Form.Control>
                               
                </Col>
                
            </Row>
            <Row className="border-top border-bottom">
                    <Col xs={2} className="fw-bold">Key</Col>
                    <Col xs={2} className="fw-bold">Active</Col>
                    <Col xs={3} className="fw-bold">Name</Col>
                    <Col xs={3} className="fw-bold">Image</Col>
                    <Col xs={2} className="fw-bold">Usage Pos</Col>
                </Row>
                {weaponIds && weaponIds.map((weapon, index) =>
                <Row key={index} className="mt-2">
                    <Col xs={2} className="text-start">
                        {weapon}
                    </Col>
                    <Col xs={2} className="mt-auto mb-auto">
                        <Form.Check inline label="On" name={`${weapon}group`} type="radio" id={`${weapon}-on`} onChange={(e)=> updateWeaponActive(1,weapon)} checked={gamesWeapons["weapon"+weapon].isActive === 1 ? true : false}/>
                        <Form.Check inline label="Off" name={`${weapon}group`} type="radio" id={`${weapon}-on`} onChange={(e)=> updateWeaponActive(0,weapon)} checked={gamesWeapons["weapon"+weapon].isActive === 0 ? true :false}/>

                    </Col>
                    <Col xs={3} className="mt-auto mb-auto">
                        <Form.Control type="text" size="sm" min={0} value={gamesWeapons["weapon"+weapon].name } onChange={e => updateWeaponName(e.target.value,weapon )}></Form.Control>
                    </Col>
                    <Col xs={3} className="mt-auto mb-auto">
                        <Form.Control type="text" size="sm" min={0} value={gamesWeapons["weapon"+weapon].imagepath } onChange={e => updateWeaponImage(e.target.value,weapon )}></Form.Control>
                    </Col>
                    <Col xs={2} className="mt-auto mb-auto">
                        <Form.Select style={{width:"auto"}} size="sm" onChange={(e)=> updateWeaponUsagePos(e.target.value,weapon) }>
                            <option value={"top"} selected={gamesWeapons["weapon"+weapon].usagePos  === "top" ? true : false}>Top</option>
                            <option value={"bottom"} selected={gamesWeapons["weapon"+weapon].usagePos === "bottom" ? true : false}>Bottom</option>
                            <option value={"off"} selected={gamesWeapons["weapon"+weapon].usagePos === "off" ? true : false}>Off</option>
                        </Form.Select>
                    </Col>
                    
                </Row>
            )}
            <Row className="mt-3 mb-3">
                <Col className="text-center"><Button onClick={saveData}>Save Game</Button> <Link to="/gameclients"><Button variant="secondary">Cancel</Button></Link></Col>
            </Row>
            </>
            }
            
        </div>
    )
}

export default AddGameClient;