import { useContext, useEffect, useLayoutEffect, useState } from "react";
import UserContext from "../../components/appContext";
import axios from "axios";
import {enqueueSnackbar } from "notistack";
import { Col, Row, Form, Button, Badge, Pagination } from "react-bootstrap";
import { getDateLocaleString, convertUctToLocalTime, convertLocalTimeToUtcDbString, getCurrentLocalTime } from "../../utils/timezone";
import { Link } from "react-router-dom";
import Paginator from "../../components/Paginator";
import { hasPrivilege } from "../../components/privileges";

const UserActions = () => {

    const [UserActions, setUserActions] = useState([]);
    const {userToken,databaseURL,privileges} = useContext(UserContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageNum,setPageNum] = useState(1);

    useEffect(() =>{ 
        if(hasPrivilege(privileges,"ADMIN_ACTIONS") === true){
            getUserActions();
        }
    },[pageNum])

    if(hasPrivilege(privileges,"ADMIN_ACTIONS") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }

    const getUserActions = async()=>{

        return await axios 
            .get(`${databaseURL}/actions?page=${pageNum}`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setUserActions(response.data.data);
                    setCurrentPage(response.data.currentPage);
                    setTotalPages(response.data.totalPages);
                } 
            })
            .catch((error)=>{
                console.log("Error getting Games");
                return false;
            })
    }

    const onPaginationClick = (num) =>{
        setPageNum(num);
    }

    return (
        <div className="mt-4">
            <Row className="mb-2">
                <Col xs={12} >
                    <h5>Manage Actions</h5>
                </Col>
                
            </Row>
            <Row className="pt-2 pb-2 border-bottom border-top">
                <Col xs={2} className="fw-bold">Action</Col>
                <Col xs={2} className="fw-bold">User</Col>
                <Col xs={2} className="fw-bold">Date</Col>
                <Col xs={6} className="fw-bold">Notes</Col>
            </Row>
            {UserActions && UserActions.map((action, index) =>               
            <Row key={index} className="pt-2 pb-2 border-bottom">
                <Col xs={2} className="mt-auto mb-auto">{action.event}</Col>
                <Col xs={2} className="mt-auto mb-auto">{action.username}</Col>
                <Col xs={2} className="mt-auto mb-auto">{getDateLocaleString(action.date)}</Col>
                <Col xs={6} className="mt-auto mb-auto">{action.message}</Col>
            </Row>
            )}
            {totalPages && totalPages > 1 &&
            <div className="pt-2"  style={{ display: "flex", justifyContent: "center" }}>
                <Paginator totalPages={totalPages} currentPage={currentPage} onPaginationClick={onPaginationClick}/>
            </div>
        }
        </div>
    );
}
    
export default UserActions;