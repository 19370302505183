import { Link,useParams } from "react-router-dom";
import { Col, Row, Form, Button } from "react-bootstrap";
import TournamentHeader from "./TournamentHeader";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import UserContext from "../../components/appContext";
import {enqueueSnackbar } from "notistack";
import Paginator from "../../components/Paginator";

const TournamentLeaderboard = () =>{
    const { id } = useParams();
    const {userToken,databaseURL, roles} = useContext(UserContext);
    const [pageNumber, setPageNumber] = useState(1);
    const [gameData, setGameData] = useState();
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [leaderboardAwards, setLeaderboardAwards] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageNum,setPageNum] = useState(1);
    
    const getLeaderBoardData = async(page) =>{
        return await axios
        .get(`${databaseURL}/games/${id}/leaderboard?page=${pageNum}`,{
          headers: {
            'Authorization':`Bearer ${userToken}`
            }
          }
        )
        .then((response) => {
            
            if(response.status === 200){
                setLeaderboardData(response.data.data[0].leaderboard.data);
                setGameData(response.data.data[0].game);
                setLeaderboardAwards(response.data.data[0].gameAwards)
                setCurrentPage(response.data.data[0].leaderboard.currentPage);
                setTotalPages(response.data.data[0].leaderboard.totalPages);
            } 

        })
        .catch(async (error) => {
            console.log(error);
            enqueueSnackbar("Error getting leaderboard details", {variant: 'error'});
            
        })
    }

    const getLeaderBoardAward = (rank) =>{
        let prize = "";
        for(let i=0;i<leaderboardAwards.length;i++){
            if(rank >= leaderboardAwards[i].minrange && rank <= leaderboardAwards[i].maxrange){
                prize = leaderboardAwards[i].awardtext;
            }
        }
        return prize;
    }

    useEffect(()=>{
        getLeaderBoardData();
    },[pageNum])
    const onPaginationClick = (num) =>{
        setPageNum(num);
    }

    return(
        <div className="mt-4">
            <Row className="mb-1">
                <Col xs={12} >
                    <TournamentHeader id={id} isRealMoney={gameData && gameData.realPlay === 1 ? true : false} isSitAndSpin={gameData && gameData.mode === 3 ? true : false}></TournamentHeader>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={12} >
                    <h5>Leaderboard {id}</h5>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col className="text-start">
                    <a href={`${databaseURL}/report/leaderboard/${id}`}><Button>Leaderboard Report CSV</Button></a> <a href={`${databaseURL}/report/players/${id}`}><Button>Players Report CSV</Button></a>
                </Col>
            </Row>
            <Row className="pt-2 pb-2 border-bottom border-top">
                {/*<Col xs={2} className="fw-bold">Status</Col>*/}
                <Col xs={1} className="fw-bold">#</Col>
                <Col xs={5} className="fw-bold">Name/token</Col>
                <Col xs={2} className="fw-bold">Pts</Col>
                <Col xs={2} className="fw-bold">Played</Col>
                <Col xs={2} className="fw-bold">Awards</Col>
            </Row>
            {leaderboardData && leaderboardData.map((data, index) =>               
                <Row key={index} className="pt-2 pb-2 border-bottom">
                    <Col xs={1} className="mt-auto mb-auto">{data.rank}</Col>
                    <Col xs={5} className="mt-auto mb-auto"><span className="fw-bold">{data.longName}</span> <span className="text-muted" style={{fontSize:"0.8rem"}}>({data.playerId})</span> <br /><span style={{fontSize:"0.8rem"}}>{data.token}</span></Col>
                    <Col xs={2} className="mt-auto mb-auto">{data.numPoints}</Col>
                    <Col xs={2} className="mt-auto mb-auto">{data.numPlays}</Col>
                    <Col xs={2} className="mt-auto mb-auto">{getLeaderBoardAward(data.rank)}</Col>
                </Row>
            )}
            {totalPages && totalPages > 1 &&
            <div className="pt-2"  style={{ display: "flex", justifyContent: "center" }}>
                <Paginator totalPages={totalPages} currentPage={currentPage} onPaginationClick={onPaginationClick}/>

            </div>
        }
        </div>
    );
}
export default TournamentLeaderboard;