import { Col, Row, Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCurrentActualLocalTimeFomatted, getCurrentUTCTimeFomatted, getCurrentLocalTimeFomatted, timezoneDefault,isDSTtime } from "../utils/timezone";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import {enqueueSnackbar } from "notistack";
import UserContext from "../components/appContext";
import axios from "axios";

const Dashboard = () => {
    const {userToken,databaseURL,privileges} = useContext(UserContext);
    const [activeGamesCount, setActiveGamesCount] = useState(0);

    useEffect(() =>{ 
        getDashboard();

        const intervalId = setInterval(() => {
            getDashboard(true); // Fetch data every 2 minutes 120000
        }, 30000);
    
        return () => clearInterval(intervalId);
        
    },[])

    const getDashboard = async() =>{ 
        return await axios
            .get(`${databaseURL}/`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.data.status === 200){
                    setActiveGamesCount(response.data.data[0].activeGamesCount);
                } 
            })
            .catch((error)=>{
                console.log("Error getting Active Game Count");
                enqueueSnackbar("Error getting Active Game Count", {variant: 'error'});
                return false;
            })
    }
    return (
        <div className="mt-4">
            <Row className="mb-2">
                <Col xs={12} >
                    <h5>Welcome to the Slotmaster Back Office</h5>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={3} className="fw-bold text-end">
                    Server Time
                </Col>
                <Col xs={9} className="">
                    {getCurrentLocalTimeFomatted()} {timezoneDefault !== "" ? `(${timezoneDefault})` : ""}
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={3} className="fw-bold text-end">
                    Daylight Saving
                </Col>
                <Col xs={9} className="">
                    {isDSTtime() === true ? "Yes" : " No"} 
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={3} className="fw-bold text-end">
                    UTC Time
                </Col>  
                <Col xs={9} className="">
                    {getCurrentUTCTimeFomatted()} 
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={3} className="fw-bold text-end">
                    Local Time
                </Col>
                <Col xs={9} className="">
                    {getCurrentActualLocalTimeFomatted()} 
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={3} className="fw-bold text-end">
                    Battles In Progress
                </Col>
                <Col xs={9} className="">
                    {activeGamesCount}
                </Col>
            </Row>
            <Row className="mt-5 mb-3 border-top">
                <Col className="pt-4 text-center"><Link to="/players"><Button >Players</Button></Link> <Link to="/tournaments"><Button variant="secondary">Tournaments</Button></Link></Col>
            </Row>
           
        </div>
    );
}
    
export default Dashboard;