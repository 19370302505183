import TournamentHeader from "./TournamentHeader";
import { useEffect, useState,useContext,useLayoutEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link,useLocation,useNavigate,useParams } from "react-router-dom";
import axios from "axios";
import UserContext from "../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { hasPrivilege } from "../../components/privileges";


const SitAndSpinStatus = () =>{
    const [pageTitle, setPageTitle] = useState("Status");
    const [awardData, setAwardData] = useState([]);
    const [gameData, setGameData] = useState();
    const [awardTypes, setAwardTypes] = useState([]);
    const {userToken,databaseURL, privileges} = useContext(UserContext);
    const { id } = useParams();

    useEffect(() =>{
        if(hasPrivilege(privileges,"ADMIN_GAMES") === true){
            if(id){
                getAwardData();
            }
        }
    },[])

    if(hasPrivilege(privileges,"ADMIN_GAMES") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/tournaments"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }

    const getAwardData = async() =>{ 
        return await axios
            .get(`${databaseURL}/games/${id}/awards`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                
                    //setCompanyKey(response.data.data[0].companyKey)
                if(response.data){
                    setPageTitle(`Status For ${response.data.game.name}`);
                    setGameData(response.data.game);
                    if(response.data.gameAwards){
                        setAwardData(response.data.gameAwards)
                    }
                }
            })
            .catch((error)=>{
                console.log("Error getting Award Data");
                enqueueSnackbar("Error getting Award Data", {variant: 'error'});
                return false;
            })
    }


    return(
        <div className="mt-4">
            <Row className="mb-1">
                <Col xs={12} >
                    <TournamentHeader id={id} isRealMoney={gameData && gameData.realPlay === 1 ? true : false} isSitAndSpin={gameData && gameData.mode === 3 ? true : false}></TournamentHeader>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={12} >
                    <h5>{pageTitle}</h5>
                </Col>
            </Row>
            <Row className="pt-3 pb-4">
                <Col xs={12} className="">Key: <span style={{backgroundColor:"greenyellow", padding:"10px"}}>Normal availability</span> <span style={{backgroundColor:"#ffc107", padding:"10px"}}>10% of prize remaining</span> <span style={{backgroundColor:"#d65b58", color:"#ffffff", padding:"10px"}}>No more prize remaining</span></Col>
                
            </Row>  
            <Row className="pt-2 pb-2 border-bottom border-top">
                <Col xs={1} className="fw-bold">#</Col>
                <Col xs={3} className="fw-bold">Prizeboard</Col>
                <Col xs={2} className="fw-bold text-center">Frequency</Col>
                <Col xs={3} className="fw-bold text-center">Available Prizes</Col>
                <Col xs={3} className="fw-bold text-center">Number Won</Col>
            </Row>
            {awardData && awardData.map((award, index) =>               
            <Row key={index} className=" pt-2 pb-2 border-bottom">
            
                <Col xs={1} className="mt-auto mb-auto ">{award.id}</Col>
                <Col xs={3} className="mt-auto mb-auto">{award.prizeboard}</Col>
                <Col xs={2} className="mt-auto mb-auto text-center">{award.frequency}</Col>
                <Col xs={3} className="mt-auto mb-auto text-center">{award.numwinners}</Col>
                {award.numcurrentwinners >= award.numwinners ? 
                    <Col xs={3} className="mt-auto mb-auto text-center" style={{backgroundColor:"#d65b58", color:"#ffffff"}}>{award.numcurrentwinners}</Col>
                :
                    <>
                    {award.numcurrentwinners >= (award.numwinners *0.9) ? 
                    <Col xs={3} className="mt-auto mb-auto text-center" style={{backgroundColor:"#ffc107"}}>{award.numcurrentwinners}</Col>
                    :
                    <Col xs={3} className="mt-auto mb-auto text-center" style={{backgroundColor:"greenyellow"}}>{award.numcurrentwinners}</Col>
                    }
                </>
                }
                
                
            </Row>
            )}
             
        </div>
    );
}
export default SitAndSpinStatus;