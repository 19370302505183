import axios from "axios";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { hasPrivilege } from "../../../components/privileges";

const Companies = () => {
    const [companyData, setCompanyData] = useState([]);
    const {userToken, databaseURL, privileges} = useContext(UserContext);
    useEffect(() =>{
        if(hasPrivilege(privileges,"ADMIN_COMPANIES") === true){
            getComanyData();
        }
    },[]);
    if(hasPrivilege(privileges,"ADMIN_COMPANIES") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }


    

    const getComanyData = async() =>{ 
        return await axios
            .get(`${databaseURL}/companies`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setCompanyData(response.data.data);
                  } 
            })
            .catch((error)=>{
                console.log("Error getting Company Data");
                enqueueSnackbar("Error getting Company Data", {variant: 'error'});
            
                return false;
            })
    }


    return (
        <div className="mt-4">
            
            <Row className="mb-2">
                <Col xs={6} >
                    <h5>Manage Companies</h5>
                </Col>
                <Col xs={6} >
                    <div className="text-end"><Link to="/addcompany"><Button size="sm">Add</Button></Link></div>
                </Col>
            </Row>
            <Row className="pt-2 pb-2 border-bottom border-top">
                {/*<Col xs={2} className="fw-bold">Status</Col>*/}
                <Col xs={10} className="fw-bold">Company Name</Col>
                <Col xs={2} className="fw-bold text-end">Actions</Col>
            </Row>
            {companyData && companyData.map((data, index) =>               
            <Row key={index} className="pt-2 pb-2 border-bottom">
            
                <Col xs={10} className="mt-auto mb-auto">{data.name}</Col>
                <Col xs={2} className="mt-auto mb-auto text-end">
                    <Link to={`/editcompany/${data.id}`} ><Button size="sm" className="mb-1 me-1" variant="secondary" >Edit</Button></Link> 
                </Col>
            </Row>
            )}
        
        </div>
    );
}
    
export default Companies;