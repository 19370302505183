// get the TZ identifier from https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
// timezone offset in milliseconds
import moment from 'moment-timezone';

export const timezoneDefault = window._slotmastersenv_ && window._slotmastersenv_.REACT_APP_TIME_ZONE ? window._slotmastersenv_.REACT_APP_TIME_ZONE : "";

if(timezoneDefault !== ""){
    moment.tz.setDefault(timezoneDefault);
}
export const getCurrentLocalTimeInMilliseconds = () => {
    return moment().valueOf;
}

export const getCurrentLocalTime = () => {
    return moment();
}

export const getCurrentLocalTimeFomatted = () => {
    return moment().format('YYYY-MM-DD HH:mm');
}

export const getCurrentUTCTimeFomatted = () => {
    return moment.utc().format('YYYY-MM-DD HH:mm');
}
export const getCurrentActualLocalTimeFomatted = () => {
    let d= new Date();
    return checkDoubleChars(d.getFullYear())+"-"+checkDoubleChars(d.getMonth())+"-"+checkDoubleChars(d.getDay())+" "+checkDoubleChars(d.getHours())+":"+checkDoubleChars(d.getMinutes());
}
export const isDSTtime = () => {
    return moment().isDST();
}
const checkDoubleChars = (val) =>{
    if(val.toString().length === 1){
        val = "0"+val;
    }
    return val
}

export const getCurrentTimeZoneOffsetJS = () => -(new Date().getTimezoneOffset()*60*1000);//(moment().utcOffset()*60*1000); //*60*1000;
export const getCurrentTimeZoneOffset = () => (moment().utcOffset()*60*1000); //(moment().utcOffset()*60*1000); //*60*1000;
export const getTimeZoneOffsetInMilliseconds = (date) => (date.utcOffset()*60*1000); //*60*1000;

// returns number of milliseconds
// example usage if date is in a very different format, convertUctToLocalTime(new Date("Fri Sep 22 10:25:58 UTC 2023"))
export const convertUctToLocalTime = (utcDateString) => {
    const momentObjectInUtc = moment.utc(utcDateString);
    const timeZoneOffset = getCurrentTimeZoneOffset();//getTimeZoneOffsetInMilliseconds(moment(utcDateString));
    return momentObjectInUtc.add(timeZoneOffset, "milliseconds");
}   

export const convertUctToLocalTimeNoTZ = (utcDateString) => {
    const momentObjectInUtc = moment.utc(utcDateString);
    return momentObjectInUtc;
}
export const convertUctToUnixTimeNoTZ = (utcDateString) => {
    const momentObjectInUtc = moment.unix(utcDateString);
    return momentObjectInUtc;
}

// returns the formmated string
export const convertLocalTimeToUtcDbString = (dateString, isDateOnly) => {
    const momentObject = convertLocalTimeToUtc(dateString);

    if(isDateOnly){
        return momentObject.format('YYYY-MM-DD');
    } else {
        return momentObject.format('YYYY-MM-DD HH:mm') + ":00.0";
    }

}

// returns moment.Moment object
// example usage if date is in a very different format, convertLocalTimeToUtcDbString(new Date("Fri Sep 22 10:25:58 UTC 2023").toISOString()))
export const convertLocalTimeToUtc = (dateString) => {
    const momentObject = moment(dateString.toString());
    const timeZoneOffset = getCurrentTimeZoneOffset();//getTimeZoneOffsetInMilliseconds(momentObject);
    return momentObject.subtract(timeZoneOffset, "milliseconds");
}



export const getDateLocaleString = (dateStringOrTimeStampInMilliseconds, format) => {
    if(!format) format = window._slotmastersenv_ && window._slotmastersenv_.REACT_APP_DEFAULT_DISPLAY_TIME_FORMAT ? window._slotmastersenv_.REACT_APP_DEFAULT_DISPLAY_TIME_FORMAT : moment.defaultFormat;
    return convertUctToLocalTime(dateStringOrTimeStampInMilliseconds).format(format);
}

export const getDateLocaleUnixString = (dateStringOrTimeStampInMilliseconds, format) => {
    if(!format) format = window._slotmastersenv_ && window._slotmastersenv_.REACT_APP_DEFAULT_DISPLAY_TIME_FORMAT ? window._slotmastersenv_.REACT_APP_DEFAULT_DISPLAY_TIME_FORMAT : moment.defaultFormat;
    return convertUctToUnixTimeNoTZ(dateStringOrTimeStampInMilliseconds).format(format);
}

export const formatTime = (time) =>{    
    return convertUctToLocalTime(time).format("HH:mm:ss");
}

