import { Link } from "react-router-dom";
import { Col, Row, Form, Button } from "react-bootstrap";
import { hasPrivilege } from "../../components/privileges";
import { useContext } from "react";
import UserContext from "../../components/appContext";

const TournamentHeader = ({id, isSitAndSpin, isRealMoney}) =>{
    const {userToken,databaseURL,privileges} = useContext(UserContext);

    return(
        <div className="text-end">
            <Link to={`/edittournament/edit/${id}`} style={{paddingRight:"5px"}}><Button size="sm">Details</Button></Link> 
            {isSitAndSpin === true ?
            <Link to={`/sitAndSpinAwards/${id}`} style={{paddingRight:"5px"}}><Button size="sm">Awards</Button></Link> 
                
            :
            <Link to={`/tournamentAwards/${id}`} style={{paddingRight:"5px"}}><Button size="sm">Awards</Button></Link> 
            }

            
            {isSitAndSpin === true ?
                <>
                <Link to={`/sitAndSpinStatus/${id}`} style={{paddingRight:"5px"}}><Button size="sm">Status</Button></Link> 
                <Link to={`/sitAndSpinTransactions/${id}`} style={{paddingRight:"5px"}}><Button size="sm">Leaderboard</Button></Link> 
           
                </>
            :
            <>
                <Link to={`/tournamentLeaderboard/${id}`} style={{paddingRight:"5px"}}><Button size="sm">Leaderboard</Button></Link> 
                <Link to={`/tournamentHistory/${id}`} style={{paddingRight:"5px"}}><Button size="sm">History</Button></Link> 
            </>
            }
            {isRealMoney === true &&
                <>
                 <Link to={`/tournamentTransactions/${id}`} style={{paddingRight:"5px"}}><Button size="sm">Transactions</Button></Link> 
                
                </>
            }
            {hasPrivilege(privileges,"ADMIN_RECONCILE") === true && isSitAndSpin !== true &&
                <Link to={`/tournamentReconcile/${id}`} style={{paddingRight:"5px"}}><Button size="sm">Reconcile</Button></Link> 
            }    
            <Link to="/tournaments"><Button size="sm">List</Button></Link> 
        </div>
    );
}
export default TournamentHeader;