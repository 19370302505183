import { useContext } from "react";
import UserContext from "../components/appContext";
import { Col, Row, Form, Button } from "react-bootstrap";

const AccountDetails = () =>{
    const {userName,firstName, lastName,userEmail,role,privileges,userId} = useContext(UserContext);

    return(
    <div className="mt-4">
        <Row className="mb-4">
            <Col xs={12} >
                <h5>Account {userName} ({userId})</h5>
            </Col>
            
        </Row>
        <Row className="mb-3">
            <Col xs={3} className="text-end">
                Email
            </Col>
            <Col xs={9}>
                {userEmail}
            </Col>
        </Row>
        <Row className="mb-3">
            <Col xs={3} className="text-end">
                First Name
            </Col>
            <Col xs={9}>
                {firstName}
            </Col>
        </Row>
        <Row className="mb-3">
            <Col xs={3} className="text-end">
                Last Name
            </Col>
            <Col xs={9}>
                {lastName}
            </Col>
        </Row>
        <Row className="mb-3">
            <Col xs={3} className="text-end">
                Role
            </Col>
            <Col xs={9}>
                {role}
            </Col>
        </Row>
        
    </div>
    )
}

export default AccountDetails;