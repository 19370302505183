import { Link,useParams } from "react-router-dom";
import { Col, Row, Form, Button } from "react-bootstrap";
import TournamentHeader from "./TournamentHeader";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import UserContext from "../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { getDateLocaleString, convertUctToLocalTime, getDateLocaleUnixString, getCurrentLocalTime } from "../../utils/timezone";
import Paginator from "../../components/Paginator";


const TournamentLeaderboard = () =>{
    const { id } = useParams();
    const {userToken,databaseURL, roles} = useContext(UserContext);
    const [pageNumber, setPageNumber] = useState(1);
    const [gamePlays, setGameplays] = useState([]);
    const [gameData, setGameData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageNum,setPageNum] = useState(1);
    
    const getGameplays = async(page) =>{
        return await axios
        .get(`${databaseURL}/games/${id}/gameplays?page=${pageNum}`,{
          headers: {
            'Authorization':`Bearer ${userToken}`
            }
          }
        )
        .then((response) => {
            
            if(response.status === 200){
                setGameplays(response.data.data[0].gameplays.data)
                setGameData(response.data.data[0].game);
                setCurrentPage(response.data.data[0].gameplays.currentPage);
                setTotalPages(response.data.data[0].gameplays.totalPages);
            } 

        })
        .catch(async (error) => {
            console.log(error);
            enqueueSnackbar("Error getting leaderboard details", {variant: 'error'});
            
        })
    }

    useEffect(()=>{
        getGameplays();
    },[pageNum])
    const onPaginationClick = (num) =>{
        setPageNum(num);
    }

    return(
        <div className="mt-4">
            <Row className="mb-1">
                <Col xs={12} >
                    <TournamentHeader id={id} isRealMoney={gameData && gameData.realPlay === 1 ? true : false} isSitAndSpin={gameData && gameData.mode === 3 ? true : false}></TournamentHeader>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={12} >
                    <h5>Game History</h5>
                </Col>
            </Row>
            <Row className="pt-2 pb-2 border-bottom border-top">
                {/*<Col xs={2} className="fw-bold">Status</Col>*/}
                <Col xs={2} className="fw-bold">#</Col>
                <Col xs={3} className="fw-bold">Game</Col>
                <Col xs={3} className="fw-bold">Winner/Points</Col>
                <Col xs={3} className="fw-bold">Date Played on</Col>
                <Col xs={1} className="fw-bold">Action</Col>
            </Row>
            {gamePlays && gamePlays.map((data, index) =>               
                <Row key={index} className="pt-2 pb-2 border-bottom">
                    <Col xs={2} className="mt-auto mb-auto">{data.id}</Col>
                    <Col xs={3} className="mt-auto mb-auto">{gameData.name}</Col>
                    <Col xs={3} className="mt-auto mb-auto"><span className="fw-bold">{data.winnerName}</span><span className="text-muted" style={{fontSize:"0.8rem"}}>({data.totalWinnings})</span></Col>
                    <Col xs={3} className="mt-auto mb-auto">{getDateLocaleUnixString(data.startTime)}</Col>
                    <Col xs={1} className="mt-auto mb-auto"><Link to={`/gamedetails/${data.id}/${id}`} ><Button size="sm" className="mb-1 me-1" >View</Button></Link> </Col>
                </Row>
            )}
            {totalPages && totalPages > 1 &&
            <div className="pt-2"  style={{ display: "flex", justifyContent: "center" }}>
                <Paginator totalPages={totalPages} currentPage={currentPage} onPaginationClick={onPaginationClick}/>

            </div>
        }
        </div>
    );
}
export default TournamentLeaderboard;