import { useContext, useEffect, useLayoutEffect, useState } from "react";
import UserContext from "../../components/appContext";
import axios from "axios";
import {enqueueSnackbar } from "notistack";
import { Col, Row, Form, Button, Badge, Pagination } from "react-bootstrap";
import { getDateLocaleString, convertUctToLocalTime, convertLocalTimeToUtcDbString, getCurrentLocalTime } from "../../utils/timezone";
import { Link } from "react-router-dom";
import Paginator from "../../components/Paginator";
import { hasPrivilege } from "../../components/privileges";

const Players = () =>{

    const [players, setPlayers] = useState([]);
    const [searchName, setSearchName] = useState("");
    const {userToken,databaseURL,privileges} = useContext(UserContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageNum,setPageNum] = useState(1);

    useEffect(() =>{ 
        if(hasPrivilege(privileges,"ADMIN_PLAYERS") === true){
            getPlayers();
        }
    },[pageNum])

    if(hasPrivilege(privileges,"ADMIN_PLAYERS") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }

    const getPlayers = async()=>{

        return await axios 
            .get(`${databaseURL}/players?page=${pageNum}`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setPlayers(response.data.data);
                    setCurrentPage(response.data.currentPage);
                    setTotalPages(response.data.totalPages);
                } 
            })
            .catch((error)=>{
                console.log("Error getting Games");
                return false;
            })
    }

    const searchPlayers = async() =>{
        setPageNum(1);
        return await axios 
            .get(`${databaseURL}/players/search-by-email?part-of-email=${searchName}&page=${pageNum}`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setPlayers(response.data.data);
                    setCurrentPage(response.data.currentPage);
                    setTotalPages(response.data.totalPages);
                } 
            })
            .catch((error)=>{
                console.log("Error getting Games");
                return false;
            })
    }
    const clearSearch = () =>{
        setPageNum(1);
        setSearchName("");
        getPlayers();
    }
    const onPaginationClick = (num) =>{
        setPageNum(num);
    }

    return(<div className="mt-4">
        <Row className="mb-2">
            <Col xs={12} sm={4} >
                <h5>Manage Players</h5>
            </Col>
            <Col xs={12} sm={8} className="text-end" >
               <Form.Control type="text" size="sm" style={{width:"70%", display:"inline-block"}} onChange={e => setSearchName(e.target.value )} value={searchName}></Form.Control> <Button size="sm" style={{display:"inline-block"}} onClick={searchPlayers}>Search</Button> <Button size="sm" style={{display:"inline-block"}} onClick={clearSearch} variant="secondary">Clear</Button>
            </Col>
        </Row>
        <Row className="mb-2">
            <Col className="text-start">
                <a href={`${databaseURL}/report/export-players`} className="pe-1"><Button>Players Export CSV</Button></a> 
            </Col>
        </Row>
        <Row className="pt-2 pb-2 border-bottom border-top">
            <Col xs={1} className="fw-bold">ID</Col>
            <Col xs={5} className="fw-bold">AccountId/Name</Col>
            <Col xs={3} className="fw-bold">Date Created</Col>
            <Col xs={2} className="fw-bold">Total Games</Col>
            <Col xs={1} className="fw-bold">Action</Col>
        </Row>
        {players && players.map((player, index) =>               
        <Row key={index} className="pt-2 pb-2 border-bottom">
            <Col xs={1} className="mt-auto mb-auto">{player.id}</Col>
            <Col xs={5} className="mt-auto mb-auto">{player.email}<br/><b>{player.name}</b></Col>
            <Col xs={3} className="mt-auto mb-auto">{player.dateCreated ? getDateLocaleString(player.dateCreated) : ""}</Col>
            <Col xs={2} className="mt-auto mb-auto">{player.totalGames}</Col>
            <Col xs={1} className="mt-auto mb-auto"><Link to={`/gameplays/${player.id}`} ><Button size="sm" className="mb-1 me-1" >Games</Button></Link> 
            </Col>
        </Row>
        )}
        {totalPages && totalPages > 1 &&
        <div className="pt-2"  style={{ display: "flex", justifyContent: "center" }}>
           
            <Paginator totalPages={totalPages} currentPage={currentPage} onPaginationClick={onPaginationClick}/>
        </div>
    }
    </div>)
}

export default Players;