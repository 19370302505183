import { useContext, useEffect, useLayoutEffect, useState } from "react";
import UserContext from "../../components/appContext";
import axios from "axios";
import {enqueueSnackbar } from "notistack";
import { Col, Row, Form, Button, Badge, Pagination } from "react-bootstrap";
import { getDateLocaleString, convertUctToLocalTime, convertLocalTimeToUtcDbString, getCurrentLocalTime } from "../../utils/timezone";
import { Link } from "react-router-dom";
import { hasPrivilege } from "../../components/privileges";

const UserRoles = () => {
    const [userRoles, setUserRoles] = useState([]);
    const {userToken,databaseURL,privileges} = useContext(UserContext);

    useEffect(() =>{ 
        if(hasPrivilege(privileges,"ADMIN_ROLES") === true){
            getUserRoles();
        }
    },[])

    if(hasPrivilege(privileges,"ADMIN_ROLES") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }


    const getUserRoles = async()=>{

        return await axios 
            .get(`${databaseURL}/roles`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setUserRoles(response.data.data);
                } 
            })
            .catch((error)=>{
                console.log("Error getting Roles");
                enqueueSnackbar("Error getting Roles", {variant: 'error'});
                return false;
            })
    }
    return (
        <div className="mt-4">
            <Row className="mb-2">
                <Col xs={6} >
                    <h5>Manage Roles</h5>
                </Col> 
                <Col xs={6} >
                    <div className="text-end"><Link to="/addrole"><Button size="sm">Add</Button></Link></div>
                </Col>  
            </Row>
            <Row className="pt-2 pb-2 border-bottom border-top">
                <Col xs={10} className="fw-bold">Company Name</Col>
                <Col xs={2} className="fw-bold text-end">Actions</Col>
            </Row>
            {userRoles && userRoles.map((role, index) =>               
            <Row key={index} className="pt-2 pb-2 border-bottom">
                <Col xs={10} className="mt-auto mb-auto">{role.name}</Col>
                <Col xs={2} className="mt-auto mb-auto text-end">
                    <Link to={`/editrole/${role.id}`} ><Button size="sm" className="mb-1 me-1" variant="secondary" >Edit</Button></Link> 
                </Col>
            </Row>
            )}
        </div>
    );
}
    
export default UserRoles;