import axios from "axios";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { hasPrivilege } from "../../../components/privileges";

const Weapons = () => {
    const [weapons, setWeapons] = useState([]);
    const {userToken, databaseURL,privileges} = useContext(UserContext)
    
    useEffect(() =>{
        if(hasPrivilege(privileges,"ADMIN_WEAPONS") === true){
            getWeapons();
        }
    },[]);

    if(hasPrivilege(privileges,"ADMIN_WEAPONS") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }

    const getWeapons = async() =>{ 
        return await axios
            .get(`${databaseURL}/weapons`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setWeapons(response.data.data);
                  } 
            })
            .catch((error)=>{
                console.log("Error getting Weapons");
                enqueueSnackbar("Error getting Weapons", {variant: 'error'});
            
                return false;
            })
    }

    return (
        <div className="mt-4">
            <Row className="mb-2">
                <Col xs={12} >
                    <h5>Manage Weapons</h5>
                </Col>
                {/*<Col xs={6} >
                    <div className="text-end"><Link to="/addgameclient"><Button size="sm">Add</Button></Link></div>
                </Col>*/}
            </Row>
            <Row className="pt-2 pb-2 border-bottom border-top">
                {/*<Col xs={2} className="fw-bold">Status</Col>*/}
                <Col xs={2} className="fw-bold">Key</Col>
                <Col xs={2} className="fw-bold">Name</Col>
                <Col xs={7} className="fw-bold">Description & Message</Col>
                <Col xs={1} className="fw-bold text-end">Actions</Col>
            </Row>
            {weapons && weapons.map((weapon, index) =>               
            <Row key={index} className="pt-2 pb-2 border-bottom">
            
                <Col xs={2} className="mt-auto mb-auto">{weapon.key}</Col>
                <Col xs={2} className="mt-auto mb-auto">{weapon.name}</Col>
                <Col xs={7} className="mt-auto mb-auto">{weapon.description}<br/>{weapon.message}</Col>
                <Col xs={1} className="mt-auto mb-auto text-end">
                    <Link to={`/editweapon/${weapon.id}`} ><Button size="sm" className="mb-1 me-1" variant="secondary" >Edit</Button></Link> 
                </Col>
            </Row>
            )}
            
        </div>
    );
}
    
export default Weapons;