import { useState, useEffect, useContext } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link,useLocation,useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import UserContext from "../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { getDateLocaleString, convertUctToLocalTime, convertLocalTimeToUtcDbString, getCurrentLocalTime,getCurrentLocalTimeFomatted } from "../../utils/timezone";
import TournamentHeader from "./TournamentHeader";
import { hasPrivilege } from "../../components/privileges";

const AddTournament = () =>{    
    const location = useLocation();
    const navigate = useNavigate();
    const [timeZone, setTimeZone] = useState(0);
    //const timeZone = -(new Date().getTimezoneOffset()*60*1000); //*60*1000
    const [title, setTitle] = useState("");
    const [company, setCompany] = useState(0);
    const [gameType, setGameType] = useState("Daily");
    const [gameMode, setGameMode] = useState(0);
    const [active, setActive] = useState(1);
    const [numPlayers, setNumPlayers] = useState(2);
    const [numWarchests, setNumWarchests] = useState(3);
    const [startDate, setStartDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endDate, setEndDate] = useState("");
    const [endTime, setEndTime] = useState("");
    const [winnings, setWinnings] = useState("points");
    const [firstPrize, setFirstPrize] = useState();
    const [secondPrize, setSecondPrize] = useState();
    const [thirdPrize, setThirdPrize] = useState();
    const [numPlays, setNumPlays] = useState(3);
    const [dailyReset, setDailyReset] = useState(0);
    const [gameTimeout, setGameTimeout] = useState(60);
    const [gameLength, setGameLength] = useState(120);
    const [countdown, setCountdown] = useState(10);
    const [spinTime, setSpinTime] = useState(2);
    const [gameClient, setGameClient] = useState("0");
    const [companies, setCompanies] = useState([]);
    const [enableBots, setEnableBots] = useState(false);
    const [botDelay, setBotDelay] = useState(0);
    const [waitForBot, setWaitForBot] = useState(0);
    const [botSpinTime, setBotSpinTime] = useState(0);
    const [endGamePopup, setEndGamePopup] = useState(false);
    const [journeyEndText, setJourneyEndText] = useState("");
    const [clickThroughURL, setClickThroughURL] = useState("");
    const [CTAText, setCTAText] = useState("");
    const [gamePageType,setGamePageType] = useState("new");
    const [pageTitle, setPageTitle] = useState("");
    const [isRealMoney, setIsRealMoney] = useState(false);
    const [buyIn, setBuyIn] = useState(0);
    const [rake, setRake] = useState(0);
    const [seed, setSeed] = useState(0);
    const [cashPot, setCashPot] = useState(0);
    const [cost, setCost] = useState(0);
    const [teamId1, setTeamId1] = useState();
    const [team1IsCustom, setTeam1IsCustom] = useState();
    const [team1Colour, setTeam1Colour] = useState();
    const [team1Name, setTeam1Name] = useState();
    const [teamId2, setTeamId2] = useState();
    const [team2IsCustom, setTeam2IsCustom] = useState();
    const [team2Colour, setTeam2Colour] = useState();
    const [team2Name, setTeam2Name] = useState();
    const [leagueTableId, setLLeagueTableId] = useState();
    const [allowRealMoney, setAllowRealMoney] = useState(false);
    const [allowSitandSpin, setAllowSitandSpin] = useState(false);
    const [allowEndGamePopup, setAllowEndGamePopup] = useState(false);
    const [enableEndGamePostEvent, setEnableEndGamePostEvent] = useState(false);
    const [disableLiveTournament, setDisableLiveTournament] = useState(false);
    const [enableCurrencyFiltering, setEnableCurrencyFiltering] = useState(false);
    const [disableForm, setDisableForm] = useState(false);
    const [fullStartDate, setFullStartDate] = useState();
    const [fullEndDate, setFullEndDate] = useState();

    const [sitAndSpinCapMode, setSitAndSpinCapMode] = useState(2);
    const [sitAndSpinEndText,setSitAndSpinEndText] = useState("");
    const [sitAndSpinEndClickThrough,setSitAndSpinEndClickThrough] = useState("");
    const [sitAndSpinctaText,setSitAndSpinctaText] = useState("");
    const [ctaGameNameWin,setCtaGameNameWin] = useState("");
    const [ctaGameCodeWin,setCtaGameCodeWin] = useState("");
    const [sitAndSpinFreespinEndText,setSitAndSpinFreespinEndText] = useState("");
    const [sitAndSpinFreespinEndClickThrough,setSitAndSpinFreespinEndClickThrough] = useState("");
    const [sitAndSpinFreespinctaText,setSitAndSpinFreespinctaText] = useState("");
    const [ctaGameNameFreeSpin,setCtaGameNameFreeSpin] = useState("");
    const [ctaGameCodeFreeSpin,setCtaGameCodeFreeSpin] = useState("");
    const [sitAndSpinLoseEndText,setSitAndSpinLoseEndText] = useState("");
    const [sitAndSpinLoseEndClickThrough,setSitAndSpinLoseEndClickThrough] = useState("");
    const [sitAndSpinLosectaText,setSitAndSpinLosectaText] = useState("");
    const [ctaGameNameLose,setCtaGameNameLose] = useState("");
    const [ctaGameCodeLose,setCtaGameCodeLose] = useState("");
    const [showPopUpTime,setShowPopUpTime] = useState(1);
    const [ctaGameName,setCtaGameName] = useState("");
    const [ctaGameCode,setCtaGameCode] = useState("");
    const [currencyFilter,setCurrencyFilter] = useState("");
 
    const [overrideWeaponDetails, setOverrideWeaponDetails] = useState();

    const sitAndSpinOptions = ["Hard cap (tournament ends when all prizes gone)","Only cap top prize","Cap all prizes, except lowest prize"]


    const weaponIds = ["","archer","freeze","witch","dragon","peacemaker","sword","bomb","thief","raid","shield"]

    const { id, pagetype } = useParams();

    const [gamesWeapons,setGameWeapons] = useState({
        weaponarcher:{weaponId: 1, isActive: 0, key: "archer", name: "Archer", lifetime: 10, totalUses: 0,showLifeTime:true},
        weaponfreeze:{weaponId: 2, isActive: 0, key: "freeze", name: "Freeze", lifetime: 10, totalUses: 0,showLifeTime:true},
        weaponwitch:{weaponId: 3, isActive: 0, key: "witch", name: "Witch", lifetime: 10, totalUses: 0,showLifeTime:true},
        weapondragon:{weaponId: 4, isActive: 0, key: "dragon", name: "dragon", lifetime: 10, totalUses: 0,showLifeTime:true},
        weaponpeacemaker:{weaponId: 5, isActive: 0, key: "peacemaker", name: "Peacemaker", lifetime: 10, totalUses: 0,showLifeTime:true},
        weaponsword:{weaponId: 6, isActive: 0, key: "sword", name: "Sword", lifetime: 0, totalUses: 0,showLifeTime:true},
        weaponbomb:{weaponId: 7, isActive: 0, key: "bomb", name: "Bomb", lifetime: 0, totalUses: 0,showLifeTime:true},
        weaponthief:{weaponId: 8, isActive: 0, key: "thief", name: "Thief", lifetime: 0, totalUses: 0,showLifeTime:true},
        weaponraid:{weaponId: 9, isActive: 0, key: "raid", name: "Raid", lifetime: 0, totalUses: 0,showLifeTime:true},
        weaponshield:{weaponId: 10, isActive: 0, key: "shield", name: "Shield", lifetime: 10, totalUses: 0,showLifeTime:true}
    });
    
    const [weaponArcher, setWeaponArcher] = useState({weaponId: 1, isActive: 0, key: "archer", name: "Archer", lifetime: 10, totalUses: 0});
    const [weaponFreeze, setWeaponFreeze] = useState({weaponId: 2, isActive: 0, key: "freeze", name: "Freeze", lifetime: 10, totalUses: 0});
    const [weaponWitch, setWeaponWitch] = useState({weaponId: 3, isActive: 0, key: "witch", name: "Witch", lifetime: 10, totalUses: 0});
    const [weaponDragon, setWeaponDragon] = useState({weaponId: 4, isActive: 0, key: "dragon", name: "dragon", lifetime: 10, totalUses: 0});
    const [weaponPeacemaker, setWeaponPeacemaker] = useState({weaponId: 5, isActive: 0, key: "peacemaker", name: "Peacemaker", lifetime: 10, totalUses: 0});
    const [weaponSword, setWeaponSword] = useState({weaponId: 6, isActive: 0, key: "sword", name: "Sword", lifetime: 10, totalUses: 0});
    const [weaponBomb, setWeaponBomb] = useState({weaponId: 7, isActive: 0, key: "bomb", name: "Bomb", lifetime: 10, totalUses: 0});
    const [weaponThief, setWeaponThief] = useState({weaponId: 8, isActive: 0, key: "thief", name: "Thief", lifetime: 10, totalUses: 0});
    const [weaponRaid, setWeaponRaid] = useState({weaponId: 9, isActive: 0, key: "raid", name: "Raid", lifetime: 10, totalUses: 0});
    const [weaponShield, setWeaponShield] = useState({weaponId: 10, isActive: 0, key: "shield", name: "Shield", lifetime: 10, totalUses: 0});

    const [gameClients, setGameClients] = useState([]);
    const [teams, setTeams] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [weaponsDefault, setWeaponsDefault] = useState([]);
    const [weapons, setWeapons] = useState([]);
    const {userToken,databaseURL, privileges} = useContext(UserContext);
    

    useEffect(() =>{
        if(hasPrivilege(privileges,"ADMIN_GAMES") === true && hasPrivilege(privileges,"ADMIN_VIEWGAMES") !== true){
           
            if(id){
                if(pagetype === "edit"){
                    setGamePageType("edit");
                }else if(pagetype === "duplicate"){
                    setGamePageType("duplicate");
                }
                
                getGameDetails();
            }else{
                setGamePageType("new");
                setPageTitle("New Tournament");
                getGameData("0","","",true);
            }
        }
    },[]);

    if(hasPrivilege(privileges,"ADMIN_GAMES") === true && hasPrivilege(privileges,"ADMIN_VIEWGAMES") === true){
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }

    const getGameDetails = async() =>{ 
        return await axios
        .get(`${databaseURL}/games/${id}`,{
          headers: {
            'Authorization':`Bearer ${userToken}`
            }
          }
        )
        .then((response) => {
            
            if(response.status === 200){
                setGame(response.data.data[0]);
                if(pagetype === "duplicate"){
                    setPageTitle("Duplicate Game");
                }else{
                    setPageTitle(response.data.data[0].name);
                }
                //const gameName = response.data.data[0].name;
                //setName(gameName);
                //userContext.setGameName(gameName);
                //userContext.setLeagueTableId(response.data.data[0].leagueTableId);
                getGameData(response.data.data[0].clientId,response.data.data[0].startDate,response.data.data[0].endDate,response.data.data[0].isActive);
            } 

        })
        .catch(async (error) => {
            console.log(error);
            enqueueSnackbar("Error getting game details", {variant: 'error'});
            // in the case of invalid or expired access token, we re-authenticate the user
            
        })
    }

    const setGame = (data) =>{
        setTitle(data.name);
        setCompany(data.companyId);
        setGameMode(data.mode);
        setActive(data.isActive);
        setNumPlayers(data.numPlayers);
        setNumWarchests(data.numWarChests);
        //setTimeZone(data.timeZone);

        let startTime = getDateLocaleString(data.startDate,'YYYY-MM-DD HH:mm'); 
        setFullStartDate(startTime);
        let startTimes = startTime.split(" "); 
        setStartDate(startTimes[0]);
        setStartTime(startTimes[1]);
        let endTime = getDateLocaleString(data.endDate,'YYYY-MM-DD HH:mm'); 
        setFullEndDate(endTime);
        let endTimes = endTime.split(" "); 
        setEndDate(endTimes[0]);
        setEndTime(endTimes[1]);
        setGameType(data.gameType);
        setWinnings(data.winnerType);
        setFirstPrize(data.prize1);
        setSecondPrize(data.prize2);
        setThirdPrize(data.prize3);
        setNumPlays(data.numPlays);
        setDailyReset(data.daily);
        setGameTimeout(data.timeout);
        setGameLength(data.lifetime);
        setCountdown(data.countdown);
        setSpinTime(data.secondsBetweenSpins);
        setGameClient(data.clientId);
        setEnableBots(data.secondsWaitForBot > 0 ? true : false);
        setBotDelay(data.botDelay);
        setSitAndSpinCapMode(data.sitandspincapmode);
        setWaitForBot(data.secondsWaitForBot);
        setBotSpinTime(data.secondsBetweenBotSpins);
        setEndGamePopup(data.journeyEndClickThrough ? true : false);
        setJourneyEndText(data.journeyEndText ? data.journeyEndText : "");
        setClickThroughURL(data.journeyEndClickThrough ?  data.journeyEndClickThrough : null);
        setCTAText(data.ctaText ? data.ctaText : "");
        setIsRealMoney(data.realPlay === 1 ? true : false);

        setSitAndSpinEndText(data.sitAndSpinEndText ? data.sitAndSpinEndText : "");
        setSitAndSpinEndClickThrough(data.sitAndSpinEndClickThrough ? data.sitAndSpinEndClickThrough : "");
        setSitAndSpinctaText(data.sitAndSpinctaText ? data.sitAndSpinctaText : "");
        setSitAndSpinFreespinEndText(data.sitAndSpinFreespinEndText ? data.sitAndSpinFreespinEndText : "");
        setSitAndSpinFreespinEndClickThrough(data.sitAndSpinFreespinEndClickThrough ? data.sitAndSpinFreespinEndClickThrough : "");
        setSitAndSpinLoseEndClickThrough(data.sitAndSpinLoseEndClickThrough ? data.sitAndSpinLoseEndClickThrough : "");
        setSitAndSpinLoseEndText(data.sitAndSpinLoseEndText ? data.sitAndSpinLoseEndText : "");

        setCtaGameName(data.ctaGameName ? data.ctaGameName : "");
        setCtaGameCode(data.ctaGameCode ? data.ctaGameCode : "");
        setCtaGameNameWin(data.ctaGameNameWin ? data.ctaGameNameWin : "");
        setCtaGameCodeWin(data.ctaGameCodeWin ? data.ctaGameCodeWin : "");
        setCtaGameNameFreeSpin(data.ctaGameNameFreeSpin ? data.ctaGameNameFreeSpin : "");
        setCtaGameCodeFreeSpin(data.ctaGameCodeFreeSpin ? data.ctaGameCodeFreeSpin : "");
        setCtaGameNameLose(data.ctaGameNameLose ? data.ctaGameNameLose : "");
        setCtaGameCodeLose(data.ctaGameCodeLose ? data.ctaGameCodeLose : "");
        setShowPopUpTime(data.showPopUpTime ? data.showPopUpTime : "");
        
        setSeed(data.seed ? data.seed : 0);
        setRake(data.rake ? data.rake : 0);
        setBuyIn(data.buyIn ? data.buyIn : 0);
        setCurrencyFilter(data.currencyFilter);

        if(data.weapons){
            const weapon = {...gamesWeapons};
            for(let i=0;i<data.weapons.length;i++){
                let weaponName=weaponIds[data.weapons[i].weaponId];
                weapon["weapon"+weaponName].lifetime = data.weapons[i].lifetime;
                weapon["weapon"+weaponName].isActive = data.weapons[i].isActive;
                weapon["weapon"+weaponName].totalUses = data.weapons[i].totalUses;
            }
            setGameWeapons(weapon);
        }

    }

    const getGameData = async(gameClientID,startDate,endDate,tournamentisActive) =>{ 
        return await axios
            .get(`${databaseURL}/games/game-form-data`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setCompanies(response.data.data[0].companies);
                    setGameClients(response.data.data[0].gameClients);
                    setTeams(response.data.data[0].teams); 
                    setLeagues(response.data.data[0].leagues);
                    if(response.data.data[0].siteConfigs && response.data.data[0].siteConfigs.data){
                        response.data.data[0].siteConfigs.data.forEach(config =>{
                           if(config.configKey === "enableSitAndSpin"){
                                setAllowSitandSpin(config.configValue === "1" ? true : false);
                            }else if(config.configKey === "enableRealMoney"){
                                setAllowRealMoney(config.configValue === "1" ? true : false);
                            }else if(config.configKey === "enableEndGamePopup"){
                                setAllowEndGamePopup(config.configValue === "1" ? true : false);
                            }else if(config.configKey === "enableEndGamePostEvent"){
                                setEnableEndGamePostEvent(config.configValue === "1" ? true : false);
                            }else if(config.configKey === "disableLiveTournament"){
                                setDisableLiveTournament(config.configValue === "1" ? true : false);
                                /*if(endDate !== "" && startDate !== ""){
                                    let startTime = getDateLocaleString(startDate,'YYYY-MM-DD HH:mm'); 
                                    let endTime = getDateLocaleString(endDate,'YYYY-MM-DD HH:mm'); 

                                    if(( getCurrentLocalTimeFomatted()> startTime ) && (getCurrentLocalTimeFomatted()< endTime ) && tournamentisActive){
                                        setDisableForm(true);
                                    }
                                }*/
                            }else if(config.configKey === "enableCurrencyFiltering"){
                                setEnableCurrencyFiltering(config.configValue === "1" ? true : false);
                            }
                            
                        })
                    } 
                    let defWeapons
                    if(response.data.data[0].weapons){
                        defWeapons = response.data.data[0].weapons;
                        for(let i=0;i<defWeapons.length;i++){
                            defWeapons[i].imagepath = process.env.PUBLIC_URL + `/assets/images/weapons/${defWeapons[i].key}Off.png`;
                            defWeapons[i].show = true;
                        }
                        setWeaponsDefault(defWeapons);
                        let newWeapons = [...defWeapons]
                        setWeapons(newWeapons);
                    }
                    if(gameClientID && gameClientID !== "0"){
                        let tmpGameClients = response.data.data[0].gameClients;
                        let overrides = null;
                        for(let i=0;i<tmpGameClients.length;i++){
                            if(gameClientID === tmpGameClients[i].id){
                                if(tmpGameClients[i].gameWeaponDetail !== ""){
                                    overrides = JSON.parse(tmpGameClients[i].gameWeaponDetail);
                                }
                                break;
                            }
                        }
                        
                        if(overrides){
                            let newWeapons = [];
                            for(let j=0;j<defWeapons.length;j++){
                                for(let details in overrides.gamesWeapons){
                                    if(defWeapons[j].id === overrides.gamesWeapons[details].weaponId){
                                        let weapon = {};
                                        weapon.description = defWeapons[j].description
                                        weapon.id = defWeapons[j].id
                                        weapon.key = defWeapons[j].key
                                        weapon.message = defWeapons[j].message
                                        weapon.imagepath = defWeapons[j].imagepath
                
                                        weapon.name = overrides.gamesWeapons[details].name
                                        if(overrides.gamesWeapons[details].imagepath !== ""){
                                            weapon.imagepath = overrides.globalImagePath + overrides.gamesWeapons[details].imagepath
                                        }
                                        weapon.show = overrides.gamesWeapons[details].isActive === 1 ? true : false;
                                        newWeapons.push(weapon);
                                        break;
                                    }
                                }
                            }
                            setWeapons(newWeapons);
                           
                        }
                    }
                  } 
            })
            .catch((error)=>{
                console.log("Error getting gameData");
                enqueueSnackbar("Error getting gameData", {variant: 'error'});
                return false;
            })
    }

    const inputKeydown =(evt)=>{
        switch(evt.key){
            case "e":
            case "E":
            case "-":
            case "+":
            case "/":
            case "*":
            case ".":
                evt.preventDefault();
                return false;
        }

    }

    const updateWeaponActive = (val, weaponName) =>{
        if(val === 1){
            let weaponCount = 0;
            for(let item in gamesWeapons){
                if(gamesWeapons[item].isActive){
                    weaponCount += 1;
                }
            }
            if(weaponCount > 7){
                enqueueSnackbar("Only 8 weapons are allowed!", {variant: 'error'});
                return;
            }
        }

        const weapon = {...gamesWeapons};
        weapon["weapon"+weaponName].isActive = val;
        setGameWeapons(weapon);

    }
    const updateWeaponLifeTime = (val, weaponName) =>{
        
        const weapon = {...gamesWeapons};
        weapon["weapon"+weaponName].lifetime = val;
        setGameWeapons(weapon);

        
    }
    const updateWeaponUse = (val, weaponName) =>{
        const weapon = {...gamesWeapons};
        weapon["weapon"+weaponName].totalUses = val;
        setGameWeapons(weapon);
    }

    const getWeaponActive = (weaponName) =>{
        return gamesWeapons["weapon"+weaponName].isActive;
    }
    const saveData = async() =>{
        if(title === ""){
            enqueueSnackbar("Please enter a 'Game Name'", {variant: 'error'});
            return;
        }
        if(startDate === ""){
            enqueueSnackbar("Please enter a 'Start Date'", {variant: 'error'});
            return;
        }
        if(startTime === ""){
            enqueueSnackbar("Please enter a 'Start Time'", {variant: 'error'});
            return;
        }
        if(endDate === ""){
            enqueueSnackbar("Please enter a 'End Date'", {variant: 'error'});
            return;
        }
        if(endTime === ""){
            enqueueSnackbar("Please enter a 'End Time'", {variant: 'error'});
            return;
        }
        
        let sDate = new Date(`${startDate}T${startTime}`).getTime();
        let eDate = new Date(`${endDate}T${endTime}`).getTime();

        if(sDate > eDate){
            enqueueSnackbar("Please set the end date after the start date", {variant: 'error'});
            return;
        }

        if(numPlays === ""){
            enqueueSnackbar("Please enter 'Number of Plays'", {variant: 'error'});
            return;
        }

        if(spinTime === ""){
            enqueueSnackbar("Please enter a 'spin Time'", {variant: 'error'});
            return;
        }
        if(countdown === ""){
            enqueueSnackbar("Please enter a 'Countdown'", {variant: 'error'});
            return;
        }
        if(gameLength === ""){
            enqueueSnackbar("Please enter a 'Game Length'", {variant: 'error'});
            return;
        }
        if(gameTimeout === ""){
            setGameTimeout(0);
        }

        if(gameClient === "0"){
            enqueueSnackbar("Please select a 'Game Client'", {variant: 'error'});
            return;
        }

        if(gamePageType === "edit"){
            await axios
            .put(`${databaseURL}/games/${id}`, generateBody(),{headers:{
                'Authorization':`Bearer ${userToken}`
            }})
                .then((response) => {
                    enqueueSnackbar("Game edited successfully", {variant: 'success'});
                    //navigate('/tournaments', { replace: true, state: { from: location } });

                })
                .catch( async (error) => {
                    enqueueSnackbar("Game edition has failed", {variant: 'error'});
                    console.log(error);
                
                })
            
        }else{
            await axios
            .post(`${databaseURL}/games`, generateBody(),{headers:{
                'Authorization':`Bearer ${userToken}`
            }})
            .then((response) => {
                
                enqueueSnackbar("Game created successfully", {variant: 'success'});
                //navigate('/tournaments', { replace: true, state: { from: location } });
                navigate(`/edittournament/edit/${response.data.data[0].id}`, { replace: true, state: { from: location } });
                setGamePageType("edit");
            })
            .catch( async (error) => {
                enqueueSnackbar("Game creation has failed", {variant: 'error'});
                console.log(error);

            }) 
            
        }

    }

    const checkWeapon = () =>{
        const weapon = {...gamesWeapons};


        for(let item in weapon){
            switch(weapon[item].weaponId){
                case 6:
                case 7:
                case 8:
                case 9:
                    console.log("weaponxx="+weapon[item].weaponId);
                    weapon[item].lifetime = 0;
                    console.log("weaponxx="+weapon[item].lifetime);
                    break;
            }
        }
    }

    const generateBody = () =>{

        const weapon = {...gamesWeapons};

        for(let item in weapon){
            switch(weapon[item].weaponId){
                case 6:
                case 7:
                case 8:
                case 9:
                    weapon[item].lifetime = 0;
                    break;
            }
        }
        setGameWeapons(weapon);
 
        const body = (
            {
            name: title, 
            companyId: company, 
            textPrizes: "",
            isActive: active, 
            numPlayers: parseInt(numPlayers), 
            mode: gameMode,
            numWarChests:parseInt(numWarchests), 
            gameType: gameType,
            startDate: convertLocalTimeToUtcDbString(`${startDate} ${startTime}`),
            endDate: convertLocalTimeToUtcDbString(`${endDate} ${endTime}`),
            winnerType: winnings,
            numPlays: parseInt(numPlays),
            daily: dailyReset ? 1 : 0,
            secondsBetweenSpins: parseInt(spinTime),
            botDelay: botDelay ? 1 : 0,
            secondsWaitForBot: enableBots === true ? waitForBot: 0,
            secondsBetweenBotSpins: parseInt(botSpinTime),
            countdown: parseInt(countdown),
            clientId: parseInt(gameClient),
            timeout: parseInt(gameTimeout),
            lifetime: parseInt(gameLength),
            prize1: firstPrize ? parseInt(firstPrize) :0,
            prize2: secondPrize ? parseInt(secondPrize):0,
            prize3: thirdPrize ? parseInt(thirdPrize):0,
            bannerUrl: "",
            journeyEndText: journeyEndText,
            journeyEndClickThrough: clickThroughURL,
            ctaText: CTAText,
            gameWeapons: [
              {...weapon["weaponarcher"]},
              {...weapon["weaponfreeze"]},
              {...weapon["weaponwitch"]},
              {...weapon["weapondragon"]},
              {...weapon["weaponpeacemaker"]},
              {...weapon["weaponsword"]},
              {...weapon["weaponbomb"]},
              {...weapon["weaponthief"]},
              {...weapon["weaponraid"]},
              {...weapon["weaponshield"]},
            ],
            sitandspincapmode:sitAndSpinCapMode,
            teamId1:"",
            teamId2:"",
            isTeam1Custom:false,
            isTeam2Custom:false,
            realPlay:isRealMoney === true ? 1 : 0,
            rake:isRealMoney === false ? 0 : rake,
            buyIn:isRealMoney === false ? 0 : buyIn,
            seed:isRealMoney === false ? 0 : seed,
            sitAndSpinEndText:sitAndSpinEndText,
            sitAndSpinEndClickThrough:sitAndSpinEndClickThrough,
            sitAndSpinctaText:sitAndSpinctaText,
            sitAndSpinFreespinEndText:sitAndSpinFreespinEndText,
            sitAndSpinFreespinEndClickThrough:sitAndSpinFreespinEndClickThrough,
            sitAndSpinLoseEndText: sitAndSpinLoseEndText,
            sitAndSpinLoseEndClickThrough: sitAndSpinLoseEndClickThrough,
            ctaGameName:ctaGameName,
            ctaGameCode:ctaGameCode,
            ctaGameNameWin:ctaGameNameWin,
            ctaGameCodeWin:ctaGameCodeWin,
            ctaGameNameFreeSpin:ctaGameNameFreeSpin,
            ctaGameCodeFreeSpin:ctaGameCodeFreeSpin,
            ctaGameNameLose:ctaGameNameLose,
            ctaGameCodeLose:ctaGameCodeLose,
            showPopUpTime:showPopUpTime,
            currencyFilter:currencyFilter
          }
        );

        if(pagetype === "duplicate"){
            body.duplicateFromId = id;
        }


        return body;
    }

    const setGameClientDetails = (id) =>{
        setGameClient(id);
        let newID = parseInt(id);
        let overrides = null;

        for(let i=0;i<gameClients.length;i++){
            if(newID === gameClients[i].id){
                overrides = JSON.parse(gameClients[i].gameWeaponDetail);
                break;
            }
        }
        if(overrides){
            let newWeapons = [];
            for(let j=0;j<weaponsDefault.length;j++){
                for(let details in overrides.gamesWeapons){
                    if(weaponsDefault[j].id === overrides.gamesWeapons[details].weaponId){
                        let weapon = {};
                        weapon.description = weaponsDefault[j].description
                        weapon.id = weaponsDefault[j].id
                        weapon.key = weaponsDefault[j].key
                        weapon.message = weaponsDefault[j].message
                        weapon.imagepath = weaponsDefault[j].imagepath

                        weapon.name = overrides.gamesWeapons[details].name
                        if(overrides.gamesWeapons[details].imagepath !== ""){
                            weapon.imagepath = overrides.globalImagePath + overrides.gamesWeapons[details].imagepath
                        }
                        weapon.show = overrides.gamesWeapons[details].isActive === 1 ? true : false;
                        newWeapons.push(weapon);
                        break;
                    }
                }
            }
            setWeapons(newWeapons);
           
        }else{
            setWeapons(weaponsDefault);
        }

    }
    
    return(
        <div className="mt-4">
            {gamePageType === "edit" &&
            <Row className="mb-1">
                <Col xs={12} >
                    <TournamentHeader id={id} isRealMoney={isRealMoney === true ? true : false} isSitAndSpin={gameMode === 3 ? true : false}/>
                </Col>
            </Row>
            }   
            <Row className="mb-4">
                <Col xs={6} >
                    <h5>{pageTitle}</h5>
                </Col>
                <Col xs={6} >
                {gamePageType === "new" &&
                    <div className="text-end"><Link to="/tournaments"><Button size="sm">Back</Button></Link></div>
                }
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Active
                </Col>
                <Col xs={9}>
                    
                    <Form.Check inline label="On" checked={active === 1 ? true : false} name="activegroup" type="radio" id='acive-on' onChange={(e)=> setActive(1)} disabled={disableForm}/>
                    <Form.Check inline label="Off" checked={active === 0 ? true : false} name="activegroup" type="radio" id='active-off' onChange={(e)=> setActive(0)} disabled={disableForm} />
                    <div style={{fontSize:"10px"}} className="text-muted">The players cannot see inactive games, but they are still saved in the database for your reference</div>
                    
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Game Name
                </Col>
                <Col xs={9}>
                   
                    <Form.Control maxLength="25" type="text" size="sm" style={{width:"300px"}} onChange={e => setTitle(e.target.value )} value={title} disabled={disableForm}></Form.Control>
                    <div style={{fontSize:"10px"}} className="text-muted">* Please note title length - recommended size ~22 characters. (Due to kerning, this is not an absolute limit)</div>
                
                
                </Col>
            </Row>
            {allowRealMoney === true &&
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Real Money 
                </Col>
                <Col xs={9}>
                    
                    <Form.Check inline label="On" checked={isRealMoney === true ? true : false} name="isRealMoneygroup" type="radio" id='isRealMoney-on' onChange={(e)=> setIsRealMoney(true)}  disabled={disableForm}/>
                    <Form.Check inline label="Off" checked={isRealMoney === false ? true : false}  name="isRealMoneygroup" type="radio" id='isRealMoney-off' onChange={(e)=> setIsRealMoney(false)} disabled={disableForm} />
                    {isRealMoney === true &&
                    <>
                        <Row className="mt-3 mb-3">
                            <Col xs={3} className="text-end">
                                Buy In
                            </Col>
                            <Col xs={9}>
                                <Form.Control style={{maxWidth:"200px"}} className="mb-1" maxLength="25" type="number" min={0} onKeyDown={e => inputKeydown(e)} size="sm" onChange={e => setBuyIn(e.target.value )} value={buyIn} disabled={disableForm}></Form.Control>
                                <div style={{fontSize:"10px"}} className="text-muted">Buy-in amount for tournament.</div>
                            
                            </Col>
                            
                        </Row>
                        <Row className="mb-3">
                            <Col xs={3} className="text-end">
                                Rake
                            </Col>
                            <Col xs={9}>
                                <Form.Control style={{maxWidth:"200px"}} className="mb-1" maxLength="25" type="number" min={0} onKeyDown={e => inputKeydown(e)} size="sm" onChange={e => setRake(e.target.value )} value={rake} disabled={disableForm}></Form.Control>
                                <div style={{fontSize:"10px"}} className="text-muted">Rake amount (as percentage)</div>
                            </Col>
                            
                        </Row>
                        <Row className="mb-3">
                            <Col xs={3} className="text-end">
                                Seed
                            </Col>
                            <Col xs={9}>
                                <Form.Control style={{maxWidth:"200px"}} className="mb-1" maxLength="25" type="number" min={0} onKeyDown={e => inputKeydown(e)} size="sm" onChange={e => setSeed(e.target.value )} value={seed} disabled={disableForm}></Form.Control>
                                <div style={{fontSize:"10px"}} className="text-muted">Seed Amount</div>
                            </Col>
                            
                        </Row>
                        </>
                    }
                </Col>
            </Row>
            }
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Company
                </Col>
                <Col xs={9}>
                        <Form.Select style={{width:"auto", marginRight:"10px", display:"inline"}} aria-label="company-select" size="sm" onChange={(e)=> setCompany(e.target.value) } disabled={disableForm}>
                            <option value={0}>All</option>
                            {companies && companies.map((data,index) =>
                                <option value={data.id} key={index} selected={company === data.id ? true : false} >{data.name}</option>   
                            )}
                        </Form.Select> 
                        
                </Col>
            </Row>
            {enableCurrencyFiltering === true &&
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Currency Filter
                </Col>
                <Col xs={9}>
                   
                    <Form.Control maxLength="25" type="text" size="sm" style={{width:"300px"}} onChange={e => setCurrencyFilter(e.target.value )} value={currencyFilter} disabled={disableForm}></Form.Control>
                    <div style={{fontSize:"10px"}} className="text-muted">* set this to filter the tournament by a currrency. Leave empty for all.</div>
                    
                
                </Col>
            </Row>
            }
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Game Type
                </Col>
                <Col xs={9}>
                    <Form.Select style={{width:"auto"}} aria-label="gameType-select" size="sm" onChange={(e)=> setGameMode(Number(e.target.value)) } disabled={disableForm}>
                        <option value={0} selected={gameMode === 0 ? true : false}>Standard</option>
                        {allowSitandSpin === true &&
                        <option value={3} selected={gameMode === 3 ? true : false}>Sit and Spin</option>
                        }
                    </Form.Select>
                    {gameMode === 3 && allowSitandSpin === true &&
                <>
                    <Row className="mt-3 mb-3">
                        <Col xs={3} className="text-end">
                        Sit & Spin Cap Mode
                        </Col>
                        <Col xs={9}>
                            <Form.Select style={{width:"auto"}} aria-label="gameType-select" size="sm" onChange={(e)=> setSitAndSpinCapMode(e.target.value) } disabled={disableForm}>
                            {sitAndSpinOptions && sitAndSpinOptions.map((opt,index) =>
                                <option value={index} key={index} selected={sitAndSpinCapMode === index ? true : false} >{opt}</option>   
                            )}
                            </Form.Select>
                        
                        </Col>
                    </Row>
                    {allowEndGamePopup === true &&
                    <>
                    <Row className="mt-3 mb-3">
                        <Col xs={12} className="text-start">
                            <h6>Upsell messages</h6>
                        </Col>

                    </Row>
                
                    <Row className="mb-3">
                        <Col xs={3} className="text-end">
                            Win Cash URL
                        </Col>
                        <Col xs={9}>
                            <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setSitAndSpinEndClickThrough(e.target.value )} value={sitAndSpinEndClickThrough} disabled={disableForm}></Form.Control>
                        </Col>
                    </Row>
                    {enableEndGamePostEvent === true?
                    <Row className="mb-3">
                        <Col xs={3} className="text-end">
                            Win Game Name
                        </Col>
                        <Col xs={3}>
                            <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setCtaGameNameWin(e.target.value )} value={ctaGameNameWin} disabled={disableForm}></Form.Control>
                        </Col>
                        <Col xs={3} className="text-end">
                            Win Game ID
                        </Col>
                        <Col xs={3}>
                            <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setCtaGameCodeWin(e.target.value )} value={ctaGameCodeWin} disabled={disableForm}></Form.Control>
                        </Col>
                    </Row>
                    :
                    <Row className="mt-3 mb-3">
                        <Col xs={3} className="text-end">
                            Win Cash message
                        </Col>
                        <Col xs={9}>
                            <Form.Control style={{maxWidth:"100%"}} className="mb-1" min={0} type="text" size="sm" onChange={e => setSitAndSpinEndText(e.target.value )}   value={sitAndSpinEndText} disabled={disableForm}></Form.Control>
                        </Col>
                        
                    </Row>
                    }
                    
                    <Row className="mb-3">
                        <Col xs={3} className="text-end">
                            Win FS URL
                        </Col>
                        <Col xs={9}>
                            <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setSitAndSpinFreespinEndClickThrough(e.target.value )} value={sitAndSpinFreespinEndClickThrough} disabled={disableForm}></Form.Control>
                            
                        
                        </Col>
                        
                    </Row>
                    {enableEndGamePostEvent === true ?
                    <Row className="mb-3">
                        <Col xs={3} className="text-end">
                            FS Game Name
                        </Col>
                        <Col xs={3}>
                            <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setCtaGameNameFreeSpin(e.target.value )} value={ctaGameNameFreeSpin} disabled={disableForm}></Form.Control>
                        </Col>
                        <Col xs={3} className="text-end">
                            FS Game ID
                        </Col>
                        <Col xs={3}>
                            <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setCtaGameCodeFreeSpin(e.target.value )} value={ctaGameCodeFreeSpin} disabled={disableForm}></Form.Control>
                        </Col>
                    </Row>
                    :
                    <Row className="mt-3 mb-3">
                        <Col xs={3} className="text-end">
                            Win FS message
                        </Col>
                        <Col xs={9}>
                            <Form.Control style={{maxWidth:"100%"}} className="mb-1" min={0} type="text" size="sm" onChange={e => setSitAndSpinFreespinEndText(e.target.value )}   value={sitAndSpinFreespinEndText} disabled={disableForm}></Form.Control>
                        </Col>
                        
                    </Row>
                    }
                    
                    
                    <Row className="mb-3">
                        <Col xs={3} className="text-end">
                            Lose URL
                        </Col>
                        <Col xs={9}>
                            <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setSitAndSpinLoseEndClickThrough(e.target.value )} value={sitAndSpinLoseEndClickThrough} disabled={disableForm}></Form.Control>
                            
                        
                        </Col>
                        
                    </Row>
                    {enableEndGamePostEvent === true ?
                    <Row className="mb-3">
                        <Col xs={3} className="text-end">
                            Lose Game Name
                        </Col>
                        <Col xs={3}>
                            <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setCtaGameNameLose(e.target.value )} value={ctaGameNameLose} disabled={disableForm}></Form.Control>
                        </Col>
                        <Col xs={3} className="text-end">
                            Lose Game ID
                        </Col>
                        <Col xs={3}>
                            <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setCtaGameCodeLose(e.target.value )} value={ctaGameCodeLose} disabled={disableForm}></Form.Control>
                        </Col>
                    </Row>
                    :
                    <Row className="mt-3 mb-3">
                        <Col xs={3} className="text-end">
                            Lose message
                        </Col>
                        <Col xs={9}>
                            <Form.Control style={{maxWidth:"100%"}} className="mb-1" min={0} type="text" size="sm" onChange={e => setSitAndSpinLoseEndText(e.target.value )}   value={sitAndSpinLoseEndText} disabled={disableForm}></Form.Control>
                        </Col>
                        
                    </Row>
                    }
                    {enableEndGamePostEvent === true &&
                    <Row className="mb-3">
                        <Col xs={3} className="text-end">
                            Popup Delay (secs)
                        </Col>
                        <Col xs={9}>
                            <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setShowPopUpTime(e.target.value )} value={showPopUpTime} disabled={disableForm}></Form.Control>
                            
                        
                        </Col>
                        
                    </Row>
                    }
                    </>
                    }
                    
                </>
            }
                </Col>
                
            </Row>
            
            
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Number of Players
                </Col>
                <Col xs={9}>
                <Form.Check inline label="1" checked={numPlayers === 1 ? true : false} name="numPlayersgroup" type="radio" id='player-1' onChange={(e)=> setNumPlayers(1)} disabled={disableForm} />
                <Form.Check inline label="2" checked={numPlayers === 2 ? true : false} name="numPlayersgroup" type="radio" id='player-2' onChange={(e)=> setNumPlayers(2)} disabled={disableForm} />
                <Form.Check inline label="3" checked={numPlayers === 3 ? true : false} name="numPlayersgroup" type="radio" id='player-3' onChange={(e)=> setNumPlayers(3)} disabled={disableForm} />
                  
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Number Warchests
                </Col>
                <Col xs={9}>
                <Form.Check inline label="1" checked={numWarchests === 1 ? true : false}  name="numWarchestsgroup" type="radio" id='warchest-1' onChange={(e)=> setNumWarchests(1)} disabled={disableForm} />
                <Form.Check inline label="2" checked={numWarchests === 2 ? true : false} name="numWarchestsgroup" type="radio" id='warchest-2' onChange={(e)=> setNumWarchests(2)} disabled={disableForm} />
                <Form.Check inline label="3" checked={numWarchests === 3 ? true : false} name="numWarchestsgroup" type="radio" id='warchest-3' onChange={(e)=> setNumWarchests(3)} disabled={disableForm} />
                <Form.Check inline label="4" checked={numWarchests === 4 ? true : false} name="numWarchestsgroup" type="radio" id='warchest-4' onChange={(e)=> setNumWarchests(4)} disabled={disableForm} />
                <Form.Check inline label="5" checked={numWarchests === 5 ? true : false} name="numWarchestsgroup" type="radio" id='warchest-5' onChange={(e)=> setNumWarchests(5)} disabled={disableForm} />
                  
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Start Date & Time
                </Col>
                <Col xs={9}>
                    <Form.Control style={{width:"160px", marginRight:"10px", display:"inline"}} type="date" size="sm" value={startDate} onChange={e => setStartDate(e.target.value )} disabled={disableForm}></Form.Control>
                    <Form.Control style={{width:"130px", display:"inline"}} type="time" size="sm" value={startTime} onChange={e => setStartTime(e.target.value )} disabled={disableForm}></Form.Control>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    End Date & Time
                </Col>
                <Col xs={9}>
                    <Form.Control style={{width:"160px", marginRight:"10px", display:"inline"}} type="date" value={endDate} size="sm" onChange={e => setEndDate(e.target.value )} disabled={disableForm}></Form.Control>
                    <Form.Control style={{width:"130px", display:"inline"}} type="time" size="sm" value={endTime} onChange={e => setEndTime(e.target.value )} disabled={disableForm}></Form.Control>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Winnings
                </Col>
                <Col xs={9}>
                    <Form.Select style={{width:"auto"}} aria-label="winnings-select" size="sm" onChange={(e)=> setWinnings(e.target.value) } disabled={disableForm}>
                        <option value={"points"} selected={winnings === "points" ? true : false}>Winner gets Game Points only</option>
                        <option value={"prizes"} selected={winnings === "prizes" ? true : false}>Winner gets Bonus Points only</option>
                        <option value={"both"} selected={winnings === "both" ? true : false}>Winner gets Game and Bonus Points</option>
                    </Form.Select>
                    {winnings !== "points" && 
                    <>               
                        <Row className="mb-1 mt-2">
                            <Col xs={12}>Prizes</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12} md={"auto"}>
                                <Form.Control style={{maxWidth:"200px"}} className="mb-1" placeholder="1st Prize" maxLength="25" type="number" size="sm" onKeyDown={e => inputKeydown(e)} onChange={e => setFirstPrize(e.target.value )} value={firstPrize} disabled={disableForm}></Form.Control>
                            
                            </Col>
                            <Col xs={12} md={"auto"}>
                                <Form.Control style={{maxWidth:"200px"}} className="mb-1" placeholder="2nd Prize" maxLength="25" type="number" size="sm" onKeyDown={e => inputKeydown(e)} onChange={e => setSecondPrize(e.target.value )} value={secondPrize} disabled={disableForm}></Form.Control>
                            
                            </Col>
                            <Col xs={12} md={"auto"}>
                                <Form.Control style={{maxWidth:"200px"}} className="mb-1" placeholder="3rd Prize" maxLength="25" type="number" size="sm" onKeyDown={e => inputKeydown(e)} onChange={e => setThirdPrize(e.target.value )} value={thirdPrize} disabled={disableForm}></Form.Control>
                            
                            </Col>
                        </Row>
                    </>  
                    }
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Number of Plays
                </Col>
                <Col xs={9}>
                    <Form.Control style={{maxWidth:"200px"}} className="mb-1" type="number" min={0} maxLength="5" size="sm" onKeyDown={e => inputKeydown(e)} onChange={e => setNumPlays(e.target.value )} value={numPlays} disabled={disableForm}></Form.Control>
                    <div style={{fontSize:"10px"}} className="text-muted">Player is allowed this number of games in any 24 hour period if configured (midnight to midnight). Set to 0 for unlimited goes</div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Daily Reset
                </Col>
                <Col xs={9}>
                    <Form.Check inline label="On" checked={dailyReset === 1 ? true : false} name="dialyResetgroup" type="radio" id='dialyReset-on' onKeyDown={e => inputKeydown(e)} onChange={(e)=> setDailyReset(1)} disabled={disableForm} />
                    <Form.Check inline label="Off" checked={dailyReset === 0 ? true : false}  name="dialyResetgroup" type="radio" id='dialyReset-off' onKeyDown={e => inputKeydown(e)} onChange={(e)=> setDailyReset(0)} disabled={disableForm} />
                    <div style={{fontSize:"10px"}} className="text-muted">If enabled, player battle count resets back to the above value daily. If disabled, this value is to be used across the entire tournament.</div>

                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Spin Time
                </Col>
                <Col xs={9}>
                    <Form.Control style={{maxWidth:"200px"}} className="mb-1" type="number" min={0} size="sm" onKeyDown={e => inputKeydown(e)} onChange={e => setSpinTime(e.target.value )} value={spinTime} disabled={disableForm}></Form.Control>
                    <div style={{fontSize:"10px"}} className="text-muted">Stops players making too many spins via the API</div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Countdown
                </Col>
                <Col xs={9}>
                    <Form.Control style={{maxWidth:"200px"}} className="mb-1" type="number" min={0} onKeyDown={e => inputKeydown(e)} size="sm" onChange={e => setCountdown(e.target.value )} value={countdown} disabled={disableForm}></Form.Control>
                    <div style={{fontSize:"10px"}} className="text-muted">Time (in seconds) to wait before game starts</div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Game Length
                </Col>
                <Col xs={9}>
                    <Form.Control style={{maxWidth:"200px"}} className="mb-1" type="number" min={0} onKeyDown={e => inputKeydown(e)} size="sm" onChange={e => setGameLength(e.target.value )} value={gameLength} disabled={disableForm}></Form.Control>
                    <div style={{fontSize:"10px"}} className="text-muted">Length of game (in seconds)</div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Timeout
                </Col>
                <Col xs={9}>
                    <Form.Control style={{maxWidth:"200px"}} className="mb-1" type="number" min={0} onKeyDown={e => inputKeydown(e)} size="sm" onChange={e => setGameTimeout(e.target.value )} value={gameTimeout} disabled={disableForm}></Form.Control>
                    <div style={{fontSize:"10px"}} className="text-muted">Number of seconds you wait for other players before the game is abandoned and you are returned to lobby. Set to 0 to make player wait forever</div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Game client
                </Col>
                <Col xs={9}>
              
                    <Form.Select style={{width:"auto"}} aria-label="company-select" size="sm" onChange={(e)=> setGameClientDetails(e.target.value) } disabled={disableForm}>
                        <option value={0}>Please Select</option>
                        {gameClients && gameClients.map((client,index) =>
                            <option value={client.id} key={index} selected={gameClient === client.id ? true : false} >{client.gameName}</option>   
                        )}
                    </Form.Select>
                
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Enable Bots
                </Col>
                <Col xs={9}>
                    <Form.Check inline label="On" checked={enableBots === true ? true : false}  name="enableBotsgroup" type="radio" id='dialyReset-on' onChange={(e)=> setEnableBots(true)} disabled={disableForm} />
                    <Form.Check inline label="Off" checked={enableBots === false ? true : false} name="enableBotsgroup" type="radio" id='dialyReset-off' onChange={(e)=> setEnableBots(false)} disabled={disableForm} />
                    <span style={{fontSize:"10px"}} className="text-muted">Bot enable switch</span>
                    {enableBots === true &&
                        <>
                        <Row className="mt-3 mb-3">
                            <Col xs={3} className="text-end">
                                Wait for bot
                            </Col>
                            <Col xs={9}>
                                <Form.Control style={{maxWidth:"200px"}} className="mb-1" maxLength="25" type="number" min={0} onKeyDown={e => inputKeydown(e)} size="sm" onChange={e => setWaitForBot(e.target.value )} value={waitForBot} disabled={disableForm}></Form.Control>
                                <div style={{fontSize:"10px"}} className="text-muted">Player have to wait this number of seconds before the gamebot joins the game. Set to 0 for no gamebot ever.</div>
                            
                            </Col>
                            
                        </Row>
                        <Row className="mb-3">
                            <Col xs={3} className="text-end">
                                Bot Spin time
                            </Col>
                            <Col xs={9}>
                                <Form.Control style={{maxWidth:"200px"}} className="mb-1" maxLength="25" type="number" min={0} onKeyDown={e => inputKeydown(e)} size="sm" onChange={e => setBotSpinTime(e.target.value )} value={botSpinTime} disabled={disableForm}></Form.Control>
                                <div style={{fontSize:"10px"}} className="text-muted">Gamebot waits for this number of seconds between spins</div>
                            </Col>
                            
                        </Row>
                        <Row className="mb-3">
                            <Col xs={3} className="text-end">
                                Bot Delay
                            </Col>
                            <Col xs={9}>
                            <Form.Check inline label="On" name="botDelaygroup" type="radio" id='botDelay-on' onChange={(e)=> setBotDelay(1)} checked={botDelay === 1 ? true : false} disabled={disableForm}/>
                            <Form.Check inline label="Off" name="botDelaygroup" type="radio" id='botDelay-off' onChange={(e)=> setBotDelay(0)} checked={botDelay === 0 ? true : false} disabled={disableForm} />
                            <div style={{fontSize:"10px"}} className="text-muted">Random delay between warchest awarded to bot and weapon applied. Range (4-10) seconds.</div>
                            </Col>
                            
                        </Row>
                        </>
                    }
                </Col>
            </Row>
            {allowEndGamePopup === true &&
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    End Game Popup
                </Col>
                <Col xs={9}>
                    <Form.Check inline label="On" checked={endGamePopup === true ? true : false} name="ctagroup" type="radio" id='cta-on' onChange={(e)=> setEndGamePopup(true)}  disabled={disableForm}/>
                    <Form.Check inline label="Off" checked={endGamePopup === false ? true : false} name="ctagroup" type="radio" id='cta-off' onChange={(e)=> setEndGamePopup(false)} disabled={disableForm} />
                    {endGamePopup === true &&
                        <>
                            
                            <Row className="mt-3 mb-3">
                                <Col xs={3} className="text-end">
                                    Click-through URL
                                </Col>
                                <Col xs={9}>
                                    <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setClickThroughURL(e.target.value )} value={clickThroughURL} disabled={disableForm}></Form.Control>
                                    
                                
                                </Col>
                                
                            </Row>
                            
                            
                            {enableEndGamePostEvent === true ?
                            <Row className="mb-3">
                                <Col xs={3} className="text-end">
                                    Game Name
                                </Col>
                                <Col xs={3}>
                                    <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setCtaGameName(e.target.value )} value={ctaGameName} disabled={disableForm}></Form.Control>
                                </Col>
                                <Col xs={3} className="text-end">
                                    Game ID
                                </Col>
                                <Col xs={3}>
                                    <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setCtaGameCode(e.target.value )} value={ctaGameCode} disabled={disableForm}></Form.Control>
                                </Col>
                            </Row>
                            :
                            <>
                            <Row className="mt-3 mb-3">
                                <Col xs={3} className="text-end">
                                    Journey End Text
                                </Col>
                                <Col xs={9}>
                                    <Form.Control style={{maxWidth:"100%"}} className="mb-1" min={0} type="text" size="sm" onChange={e => setJourneyEndText(e.target.value )}   value={journeyEndText} disabled={disableForm}></Form.Control>
                                    
                                
                                </Col>
                                
                            </Row>
                            <Row className="mt-3 mb-3">
                                <Col xs={3} className="text-end">
                                    CTA Text
                                </Col>
                                <Col xs={9}>
                                    <Form.Control style={{maxWidth:"100%"}} className="mb-1" type="text" min={0} size="sm" onChange={e => setCTAText(e.target.value )} value={CTAText} disabled={disableForm}></Form.Control>
                                    
                                </Col>
                                
                            </Row>
                            </>
                            }
                        </>
                    }
                </Col>
            </Row>     
            }
            <Row className="mt-3 mb-3">
                <Col className="text-center"><Button onClick={saveData}>Save Game</Button> <Button variant="secondary">Cancel</Button></Col>
             </Row>

            <Row className="mt-2 mb-2">
                <Col style={{fontSize:"20px"}} className="fw-bold">Weapons <span style={{fontSize:"10px", paddingLeft:"10px"}} className="text-muted fw-normal">If you want unlimited use for a weapon, set the Number of Uses to 0</span></Col>
            </Row>
            <Row className="border-top border-bottom">
                <Col xs={2} className="fw-bold"></Col>
                <Col xs={3} className="fw-bold">Name</Col>
                <Col xs={3} className="fw-bold">Active</Col>
                <Col xs={2} className="fw-bold">Lifetime</Col>
                <Col xs={2} className="fw-bold">No of Uses</Col>
            </Row>
            {weapons && weapons.map((weapon, index) =>
               
                <Row key={index} className="mt-2" style={{display : weapon.show === false ? "none":""}}>
                    <Col xs={2} className="text-center">
                        <img  alt="weapon" style={{width: "50px", marginTop: "6px"}} src={weapon.imagepath}/>   
                    </Col>
                    <Col xs={3} className="mt-auto mb-auto">{weapon.name}</Col>
                    <Col xs={3} className="mt-auto mb-auto">
                        <Form.Check inline label="On" name={`${weapon.key}group`} type="radio" id={`${weapon.name}-on`} onChange={(e)=> updateWeaponActive(1,weapon.key)} checked={gamesWeapons["weapon"+weapon.key].isActive === 1 ? true : false} disabled={disableForm}/>
                        <Form.Check inline label="Off" name={`${weapon.key}group`} type="radio" id={`${weapon.name}-on`} onChange={(e)=> updateWeaponActive(0,weapon.key)} checked={gamesWeapons["weapon"+weapon.key].isActive === 0 ? true : false} disabled={disableForm}/></Col>
                    <Col xs={2} className="mt-auto mb-auto">
                        {gamesWeapons["weapon"+weapon.key].showLifeTime === true &&
                        <Form.Control maxLength="5" type="number" size="sm" min={0} value={gamesWeapons["weapon"+weapon.key].lifetime} onKeyDown={e => inputKeydown(e)} onChange={e => updateWeaponLifeTime(e.target.value,weapon.key )} disabled={disableForm}></Form.Control>
                     }
                     </Col>
                    <Col xs={2} className="mt-auto mb-auto">
                        <Form.Control maxLength="5" type="number" size="sm" min={0} value={gamesWeapons["weapon"+weapon.key].totalUses} onKeyDown={e => inputKeydown(e)} onChange={e => updateWeaponUse(e.target.value,weapon.key )} disabled={disableForm}></Form.Control>
                    </Col>
                </Row>
                
            )}
            <Row className="mt-3 mb-3">
                <Col className="text-center"><Button onClick={saveData}>Save</Button> <Link to="/tournaments"><Button variant="secondary">Cancel</Button></Link></Col>
            </Row>
           
        </div>
    )
}

export default AddTournament;