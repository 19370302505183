import { useEffect, useState,useContext,useLayoutEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link,useLocation,useNavigate,useParams } from "react-router-dom";
import axios from "axios";
import UserContext from "../../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { hasPrivilege } from "../../../components/privileges";

const AddWeapon = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [pageTitle, setPageTitle] = useState("Edit")
    const [weaponName, setWeaponName] = useState("");
    const [weaponKey, setWeaponKey] = useState("");
    const [weaponDescription, setWeaponDescription] = useState("");
    const [weaponMessage, setWeaponMessage] = useState("");
    const [gamePageType,setGamePageType] = useState("new");
    const {userToken,databaseURL, privileges} = useContext(UserContext);
    const {id} = useParams();

    useEffect(() =>{
        if(hasPrivilege(privileges,"ADMIN_WEAPONS") === true){
            if(id){
                
                setGamePageType("edit");
                getWeaponData();
            }
        }
    },[])

    if(hasPrivilege(privileges,"ADMIN_WEAPONS") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }

    const getWeaponData = async() =>{ 
        return await axios
            .get(`${databaseURL}/weapons/${id}`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                console.log(response)
                if(response.status === 200){
                    setWeaponName(response.data.data[0].name)
                    setWeaponKey(response.data.data[0].key)
                    setWeaponDescription(response.data.data[0].description)
                    setWeaponMessage(response.data.data[0].message)

                    setPageTitle(`Edit ${response.data.data[0].key}`);
                    
                  } 
            })
            .catch((error)=>{
                console.log("Error getting Weapon data");
                enqueueSnackbar("Error getting Weapon Data", {variant: 'error'});
                return false;
            })
    }

    const generateBody = () =>{
        const body = (
            {
                name: weaponName, 
                description: weaponDescription,
                message: weaponMessage,             
          }
        );

        return body;
    }

    const saveData = async () =>{
        if(weaponName === ""){
            enqueueSnackbar("Please enter a 'Name'", {variant: 'error'});
            return;
        }
        if(weaponKey === ""){
            enqueueSnackbar("Please enter a 'key'", {variant: 'error'});
            return;
        }
        
        if(gamePageType === "new"){
            await axios
                .post(`${databaseURL}/weapons`, generateBody(),{headers:{
                    'Authorization':`Bearer ${userToken}`
                }})
                .then((response) => {

                    enqueueSnackbar("Game Client created successfully", {variant: 'success'});

                })
                .catch( async (error) => {
                    enqueueSnackbar("Game Client creation has failed", {variant: 'error'});
                    console.log(error);

                }) 
        }else{
            await axios
                .put(`${databaseURL}/weapons/${id}`, generateBody(),{headers:{
                    'Authorization':`Bearer ${userToken}`
                }})
                .then((response) => {
                    enqueueSnackbar("Weapon updated successfully", {variant: 'success'});
                    navigate('/weapons', { replace: true, state: { from: location } });

                })
                .catch( async (error) => {
                    enqueueSnackbar("Weapon updated has failed", {variant: 'error'});
                    console.log(error);

                }) 
        }
    }

    return (
        <div className="mt-4">
            <Row className="mb-4">
                <Col xs={6} >
                    <h5>{pageTitle}</h5>
                </Col>
                <Col xs={6} >
                    <div className="text-end"><Link to="/weapons"><Button size="sm">Back</Button></Link></div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Name
                </Col>
                <Col xs={9}>
                   
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setWeaponName(e.target.value )} value={weaponName}></Form.Control>
                               
                </Col>
            </Row>
            
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Description
                </Col>
                <Col xs={9}>
                   
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setWeaponDescription(e.target.value )} value={weaponDescription}></Form.Control>
                               
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Message
                </Col>
                <Col xs={9}>
                   
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setWeaponMessage(e.target.value )} value={weaponMessage}></Form.Control>
                   
                    <div style={{"paddingTop":"10px"}}>
                        This message is shown to the target player when you play the weapon.<br/>
                        For example:<br/>
                        [NAME] has stolen [POINTS] from you<br/>
                        <span style={{"color":"#ff0000"}}>[NAME]</span> and <span style={{"color":"#ff0000"}}>[POINTS] </span>will be replaced with your name and number of points lost.
                    </div>
                               
                </Col>
            </Row>
            <Row className="mt-3 mb-3">
                <Col className="text-center"><Button onClick={saveData}>Update</Button>  
                <Link to="/weapons"><Button  variant="secondary">Cancel</Button></Link></Col>
            </Row>
            
        </div>
    );
}
    
export default AddWeapon;