import axios from "axios";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { Col, Row, Button, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { hasPrivilege } from "../../../components/privileges";

const GameClients = () => {
    const [gameClients, setGameClients] = useState([]);
    const {userToken, databaseURL,privileges} = useContext(UserContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageNum,setPageNum] = useState(1);
   
    useEffect(() =>{
        if(hasPrivilege(privileges,"ADMIN_GAMECLIENTS") === true){
            getGameClients();
        }
    },[]);

    if(hasPrivilege(privileges,"ADMIN_GAMECLIENTS") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/tournaments"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }

    const getGameClients = async() =>{ 
        return await axios
            .get(`${databaseURL}/game-clients?page=${pageNum}`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setGameClients(response.data.data);
                  } 
            })
            .catch((error)=>{
                console.log("Error getting Game Clients");
                enqueueSnackbar("Error getting Game Clients", {variant: 'error'});
            
                return false;
            })
    }

    const onPaginationClick = (num) =>{
        setPageNum(num);
    }

    const getPages = () =>{
        let items = [];
        for(let num=1; num<=totalPages; num++){
            items.push(<Pagination.Item key={num} active={num === currentPage} onClick={()=>onPaginationClick(num)}>{num}</Pagination.Item> ) 
        } 
        return items;
    }

    return (
        <div className="mt-4">
            
            <Row className="mb-2">
                <Col xs={6} >
                    <h5>Game Clients</h5>
                </Col>
                <Col xs={6} >
                    <div className="text-end"><Link to="/addgameclient"><Button size="sm">Add</Button></Link></div>
                </Col>
            </Row>
            <Row className="pt-2 pb-2 border-bottom border-top">
                {/*<Col xs={2} className="fw-bold">Status</Col>*/}
                <Col xs={4} className="fw-bold">Game Name</Col>
                <Col xs={4} className="fw-bold">Version</Col>
                <Col xs={2} className="fw-bold text-center">Engine</Col>
                <Col xs={2} className="fw-bold text-end">Actions</Col>
            </Row>
            {gameClients && gameClients.map((client, index) =>               
            <Row key={index} className="pt-2 pb-2 border-bottom">
            
                <Col xs={4} className="mt-auto mb-auto">{client.gameName}</Col>
                <Col xs={4} className="mt-auto mb-auto">{client.assetsFolder.indexOf("/") >-1 ? client.assetsFolder.slice(client.assetsFolder.indexOf("/")+1,-1) : client.assetsFolder}</Col>
                <Col xs={2} className="mt-auto mb-auto text-center">{client.backendVersion === 1 ? "V2" : "V1"}</Col>
                <Col xs={2} className="mt-auto mb-auto text-end">
                    <Link to={`/editgameclient/${client.id}`} ><Button size="sm" className="mb-1 me-1" variant="secondary" >Edit</Button></Link> 
                </Col>
            </Row>
            )}
            {totalPages && totalPages > 1 &&
            <div className="pt-2"  style={{ display: "flex", justifyContent: "center" }}>
                <Pagination size="md">
                    
                    {getPages()}
                       
                </Pagination>
            </div>
        }
        
        </div>
    );
}
    
export default GameClients;