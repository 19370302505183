import { useEffect } from "react";
import { Pagination } from "react-bootstrap";
const Paginator = (props) =>{
    const {totalPages, currentPage,onPaginationClick} = props;
    
    const getPages = () =>{
        let items = [];
        let prevNav = null;
        let nextNav = null;
        let lastNav = null;
        let firstNav = null;
        if(totalPages <=1){
            return <></>;
        }
        
        if(currentPage === 1){
            firstNav = <Pagination.First key={"a"} disabled={true} />;
            prevNav = <Pagination.Prev key={"b"} disabled={true} />;
    
        }else{
            firstNav = <Pagination.First key={"a"} onClick={()=>onPaginationClick(1)} /> ;
            prevNav = <Pagination.Prev key={"b"} onClick={()=>onPaginationClick(currentPage-1)} />;
        }  

        if(currentPage === totalPages){
            nextNav = <Pagination.Next key={"g"} disabled={true} />;
            lastNav = <Pagination.Last key={"h"} disabled={true}/>;
        }else{
            nextNav = <Pagination.Next key={"g"} disabled={currentPage === totalPages} onClick={()=>onPaginationClick(currentPage+1)} />; 
            lastNav = <Pagination.Last key={"h"} disabled={currentPage === totalPages} onClick={()=>onPaginationClick(totalPages)} />; 
        }

        if(totalPages <=10){
            for(let num=1; num<=totalPages; num++){
                items.push(<Pagination.Item key={num} active={num === currentPage} onClick={()=>onPaginationClick(num)}>{num}</Pagination.Item> ) 
            } 
            items.unshift(prevNav);
            items.push(nextNav);
            return items;
        }

        if(currentPage <5){
            for(let num=1; num<=5; num++){
                items.push(<Pagination.Item key={num} active={num === currentPage} onClick={()=>onPaginationClick(num)}>{num}</Pagination.Item> ) 
            } 
            items.unshift(prevNav);
            items.unshift(firstNav);
            
            items.push(<Pagination.Ellipsis key={"e"}/>);
            items.push(<Pagination.Item key={"f"} onClick={()=>onPaginationClick(totalPages)} >{totalPages}</Pagination.Item>) 
            items.push(nextNav);
            items.push(lastNav);
            return items;
        }

        if(currentPage > totalPages-5){
            for(let num=totalPages-5; num<=totalPages; num++){
                items.push(<Pagination.Item key={num} active={num === currentPage} onClick={()=>onPaginationClick(num)}>{num}</Pagination.Item> ) 
            } 
            items.unshift(<Pagination.Ellipsis key={"c"}/>);
            items.unshift(<Pagination.Item key={"d"} onClick={()=>onPaginationClick(1)} >1</Pagination.Item>) 
            items.unshift(prevNav);
            items.unshift(firstNav);
    
            items.push(nextNav);
            items.push(lastNav);
            return items;
        }

        for(let num=currentPage-2; num<=currentPage+2; num++){
            items.push(<Pagination.Item key={num} active={num === currentPage} onClick={()=>onPaginationClick(num)}>{num}</Pagination.Item> ) 
        } 
        items.unshift(<Pagination.Ellipsis key={"c"}/>);
        items.unshift(<Pagination.Item key={"d"} onClick={()=>onPaginationClick(1)} >1</Pagination.Item>) 
        items.unshift(prevNav);
        items.unshift(firstNav);

        items.push(<Pagination.Ellipsis key={"e"}/>);
        items.push(<Pagination.Item key={"f"} onClick={()=>onPaginationClick(totalPages)} >{totalPages}</Pagination.Item>) 
        items.push(nextNav);
        items.push(lastNav);
        return items;
    }
    return(
        <Pagination size="md">{getPages()}</Pagination>
    )
}

export default Paginator;