import { useContext, useState } from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import UserContext from "./appContext";
import { PersonCircle } from 'react-bootstrap-icons';
import { hasAccesstoSystem, hasAccesstoUsers, hasPrivilege } from "./privileges";

/*
dashboard
Games (should be tournaments) === create and list Tournaments.  1
players == shows player list. 2
users == add/remove admin users.  3
     Roles == set up roles for admin users   6. (tech config)
     actions == shows actions performed in admin area. 9. (tech config)
Config
     site config == enables/disables slotmasters access.  8   (tech config)
     game clients == the games available to use in a tournament. 7.  (tech config)
     weapons == create and lists weapons  4   (tech config)
     companies == sets up company for operator   5. (tech config)
tournaments == to be combined with Games
*/

const Header = () =>{
    const {userName, onLogout,privileges} = useContext(UserContext);

    return(
        <Navbar expand="md" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="/"><img src={require('../assets/images/logo.png')} style={{width:"120px"}} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="sm-navbar-nav" />
                <Navbar.Collapse id="sm-navbar-nav">
                    <Nav className="ms-auto">
                        <NavLink to="/" className="nav-link ms-auto">Dashboard</NavLink>
                        
                        <NavLink to="/tournaments" className="nav-link ms-auto">Tournaments</NavLink>
                        
                        
                        {hasPrivilege(privileges,"ADMIN_PLAYERS") === true &&
                            <NavLink to="/players" className="nav-link ms-auto">Players</NavLink>
                        }
                        {hasAccesstoUsers(privileges) && 
                        <NavDropdown title="Users" id="users-nav-dropdown" align="end" flip="true" className="ms-auto text-end">
                            {hasPrivilege(privileges,"ADMIN_USERS") === true &&
                                <NavDropdown.Item style={{"padding":"0px"}}><NavLink to="/users" className="dropdown-item ms-auto">List</NavLink></NavDropdown.Item>
                            }
                            {hasPrivilege(privileges,"ADMIN_ROLES") === true &&
                                <NavDropdown.Item style={{"padding":"0px"}}><NavLink to="/roles" className="dropdown-item ms-auto">Roles</NavLink></NavDropdown.Item>
                            }
                            {hasPrivilege(privileges,"ADMIN_ACTIONS") === true &&
                                <NavDropdown.Item style={{"padding":"0px"}}><NavLink to="/actions" className="dropdown-item ms-auto">Actions</NavLink></NavDropdown.Item>
                            }
                        </NavDropdown>
                        }
                        {hasAccesstoSystem(privileges) && 
                        <NavDropdown title="System" id="system-nav-dropdown" align="end" flip="true" className="ms-auto text-end">
                            {hasPrivilege(privileges,"ADMIN_GAMECLIENTS") === true &&
                                <NavDropdown.Item style={{"padding":"0px"}}><NavLink to="/gameclients" className="dropdown-item">Game Clients</NavLink></NavDropdown.Item>
                            }
                            {hasPrivilege(privileges,"ADMIN_WEAPONS") === true &&
                                <NavDropdown.Item style={{"padding":"0px"}}><NavLink to="/weapons" className="dropdown-item">Weapons</NavLink></NavDropdown.Item>
                            }
                            {hasPrivilege(privileges,"ADMIN_SITECONFIG") === true &&
                                <NavDropdown.Item style={{"padding":"0px"}}><NavLink to="/siteconfig" className="dropdown-item">Site Config</NavLink></NavDropdown.Item>
                            }
                            {hasPrivilege(privileges,"ADMIN_COMPANIES") === true &&
                                <NavDropdown.Item style={{"padding":"0px"}}><NavLink to="/companies" className="dropdown-item">Companies</NavLink></NavDropdown.Item>
                            }
                        </NavDropdown>
                        }   
                        <NavDropdown title={<PersonCircle size={25}/>} align="end" flip="true" id="details-nav-dropdown" className="ms-auto text-end">
                            {hasPrivilege(privileges,"PROFILE") === true &&
                            <>
                                <NavDropdown.Item style={{"padding":"0px"}}><NavLink to="/account" className="dropdown-item ms-auto">Details</NavLink></NavDropdown.Item>
                                <NavDropdown.Divider />
                                </>
                            }
                            <div className="dropdown-item ms-auto" onClick={onLogout}>Logout</div>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;