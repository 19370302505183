import { Link,useParams } from "react-router-dom";
import { Col, Row, Form, Button } from "react-bootstrap";
import TournamentHeader from "./TournamentHeader";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import UserContext from "../../components/appContext";
import {enqueueSnackbar } from "notistack";
import Paginator from "../../components/Paginator";
import { getDateLocaleString, convertUctToLocalTime, convertLocalTimeToUtcDbString, getCurrentLocalTime } from "../../utils/timezone";


const SitAndSpinTransactions = () =>{
    const { id } = useParams();
    const {userToken,databaseURL, privileges} = useContext(UserContext);
    const [pageNumber, setPageNumber] = useState(1);
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageNum,setPageNum] = useState(1);
    const [totalPlayers,setTotalPlayers] = useState(0);
    const [totalWinners,setTotalWinners] = useState(0);
    const [totalGames,setTotalGames] = useState(0);
    const [totalBots,setTotalBots] = useState(0);

    const [isRealMoney, setIsRealMoney] = useState(false);

    useEffect(()=>{
        
            getLeaderBoardData();
        
    },[pageNum])

    const getLeaderBoardData = async(page) =>{
        return await axios
        .get(`${databaseURL}/reconciled-transactions/${id}?page=${pageNum}`,{
          headers: {
            'Authorization':`Bearer ${userToken}`
            }
          }
        )
        .then((response) => {
            
            if(response.status === 200){
                setLeaderboardData(response.data.data[0].reconciledTransactions.data);

                if(response.data.data[0].sitAndSpinTotals){
                    setTotalPlayers(response.data.data[0].sitAndSpinTotals.totalPlayers);
                    setTotalWinners(response.data.data[0].sitAndSpinTotals.totalWinners);
                    setTotalGames(response.data.data[0].sitAndSpinTotals.totalGames);
                    setTotalBots(response.data.data[0].sitAndSpinTotals.totalBots);
                }
                
                setCurrentPage(response.data.data[0].reconciledTransactions.currentPage);
                setTotalPages(response.data.data[0].reconciledTransactions.totalPages);
            } 

        })
        .catch(async (error) => {
            console.log(error);
            enqueueSnackbar("Error getting leaderboard details", {variant: 'error'});
            
        })
    }

    const onPaginationClick = (num) =>{
        setPageNum(num);
    }

    return(
        <div className="mt-4">
            <Row className="mb-1">
                <Col xs={12} >
                    <TournamentHeader id={id} isRealMoney={isRealMoney === true ? true : false} isSitAndSpin={true}></TournamentHeader>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={12} >
                    <h5>Sit And Spin Transactions ({id})</h5>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={12} >
                    <div><span className="fw-bold">Total Games:</span>{totalGames}</div>
                    <div><span className="fw-bold">Total Players:</span>{totalPlayers}</div>
                    <div><span className="fw-bold">Total Winners:</span>{totalWinners}</div>
                    <div><span className="fw-bold">Total Bots:</span>{totalBots}</div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col className="text-start">
                    <a href={`${databaseURL}/report/sitandspin/${id}`} className="pe-1"><Button>Battles Report CSV</Button></a> 
                    <a href={`${databaseURL}/report/sitandspin-players/${id}`} className="pe-1"><Button>Players Report CSV</Button></a> 
                    <a href={`${databaseURL}/report/sitandspin-awards/${id}`}><Button>Player Winners CSV</Button></a> 
                </Col>
            </Row>
            <Row className="pt-2 pb-2 border-bottom border-top">
                <Col xs={1} className="fw-bold">#</Col>
                <Col xs={3} className="fw-bold">PlayerID / RemoteID</Col>
                <Col xs={3} className="fw-bold">Date</Col>
                <Col xs={3} className="fw-bold">Award / transactionId</Col>
                <Col xs={2} className="fw-bold">Status</Col>
            </Row>
            {leaderboardData && leaderboardData.map((data, index) =>               
                <Row key={index} className="pt-2 pb-2 border-bottom">
                    <Col xs={1} className="mt-auto mb-auto">{data.id}</Col>
                    <Col xs={3} className="mt-auto mb-auto"><span className="fw-bold">{data.playerId}</span><br /><span className="text-muted" style={{fontSize:"0.8rem"}}>({data.remotePlayerId})</span> </Col>
                    <Col xs={3} className="mt-auto mb-auto">{getDateLocaleString(data.timestamp)}</Col>
                    {/*<Col xs={3} className="mt-auto mb-auto"><span className="fw-bold">{new Intl.NumberFormat("en-GB", { 
                                            style: 'currency',
                                            currency: data.currency
                                        }).format(data.amount/100)}</span> ({data.amountstr})<br /><span className="text-muted" style={{fontSize:"0.8rem"}}>({data.transactionId})</span></Col>*/}
                    <Col xs={3} className="mt-auto mb-auto">{data.amountstr}<br /><span className="text-muted" style={{fontSize:"0.8rem"}}>({data.transactionId})</span></Col>
                    <Col xs={2} className="mt-auto mb-auto text-truncate">{data.complete === 0 ? data.status : "complete"}</Col>
                    
                </Row>
            )}
            {totalPages && totalPages > 1 &&
            <div className="pt-2"  style={{ display: "flex", justifyContent: "center" }}>
                <Paginator totalPages={totalPages} currentPage={currentPage} onPaginationClick={onPaginationClick}/>

            </div>
        }
        </div>
    );
}
export default SitAndSpinTransactions;