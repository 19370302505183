import { useState, useLayoutEffect, useContext } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link,useLocation,useParams } from "react-router-dom";
import axios from "axios";
import UserContext from "../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { getDateLocaleString, convertUctToLocalTime, convertLocalTimeToUtcDbString, getCurrentLocalTime } from "../../utils/timezone";
import TournamentHeader from "./TournamentHeader";


const ViewTournament = () =>{

    const timeZone = -(new Date().getTimezoneOffset()*60*1000); //*60*1000
    const [title, setTitle] = useState("");
    const [company, setCompany] = useState(0);
    const [gameType, setGameType] = useState("Daily");
    const [gameMode, setGameMode] = useState(0);
    const [active, setActive] = useState(1);
    const [numPlayers, setNumPlayers] = useState(2);
    const [numWarchests, setNumWarchests] = useState(3);
    const [startDate, setStartDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endDate, setEndDate] = useState("");
    const [endTime, setEndTime] = useState("");
    const [winnings, setWinnings] = useState("0");
    const [firstPrize, setFirstPrize] = useState();
    const [secondPrize, setSecondPrize] = useState();
    const [thirdPrize, setThirdPrize] = useState();
    const [numPlays, setNumPlays] = useState(3);
    const [dailyReset, setDailyReset] = useState(0);
    const [gameTimeout, setGameTimeout] = useState(60);
    const [gameLength, setGameLength] = useState(120);
    const [countdown, setCountdown] = useState(10);
    const [spinTime, setSpinTime] = useState(2);
    const [gameClient, setGameClient] = useState("0");
    const [companies, setCompanies] = useState([]);
    const [enableBots, setEnableBots] = useState(false);
    const [botDelay, setBotDelay] = useState(0);
    const [waitForBot, setWaitForBot] = useState(0);
    const [botSpinTime, setBotSpinTime] = useState(0);
    const [endGamePopup, setEndGamePopup] = useState(false);
    const [journeyEndText, setJourneyEndText] = useState("");
    const [clickThroughURL, setClickThroughURL] = useState("");
    const [CTAText, setCTAText] = useState("");
    const [gamePageType,setGamePageType] = useState("new");
    const [pageTitle, setPageTitle] = useState("");
    const [gameClients, setGameClients] = useState([]);
    const [teams, setTeams] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [weapons, setWeapons] = useState([]);
    const {userToken,databaseURL, roles} = useContext(UserContext);
    


    const weaponIds = ["","archer","freeze","witch","dragon","peacemaker","sword","bomb","thief","raid","shield"]

    const { id } = useParams();

    const [gamesWeapons,setGameWeapons] = useState({
        weaponarcher:{weaponId: 1, isActive: 0, key: "archer", name: "Archer", lifetime: 10, totalUses: 0,showLifeTime:true},
        weaponfreeze:{weaponId: 2, isActive: 0, key: "freeze", name: "Freeze", lifetime: 10, totalUses: 0,showLifeTime:true},
        weaponwitch:{weaponId: 3, isActive: 0, key: "witch", name: "Witch", lifetime: 10, totalUses: 0,showLifeTime:true},
        weapondragon:{weaponId: 4, isActive: 0, key: "dragon", name: "dragon", lifetime: 10, totalUses: 0,showLifeTime:true},
        weaponpeacemaker:{weaponId: 5, isActive: 0, key: "peacemaker", name: "Peacemaker", lifetime: 10, totalUses: 0,showLifeTime:true},
        weaponsword:{weaponId: 6, isActive: 0, key: "sword", name: "Sword", lifetime: 10, totalUses: 0,showLifeTime:false},
        weaponbomb:{weaponId: 7, isActive: 0, key: "bomb", name: "Bomb", lifetime: 10, totalUses: 0,showLifeTime:false},
        weaponthief:{weaponId: 8, isActive: 0, key: "thief", name: "Thief", lifetime: 10, totalUses: 0,showLifeTime:false},
        weaponraid:{weaponId: 9, isActive: 0, key: "raid", name: "Raid", lifetime: 10, totalUses: 0,showLifeTime:false},
        weaponshield:{weaponId: 10, isActive: 0, key: "shield", name: "Shield", lifetime: 10, totalUses: 0,showLifeTime:true}
    });

    useLayoutEffect(() =>{
        getGameData();
        if(id){
            console.log(`id = ${id}`);
            setGamePageType("view");
            getGameDetails();
        }
    },[]);

    const getGameDetails = async() =>{ 
        return await axios
        .get(`${databaseURL}/games/${id}`,{
          headers: {
            'Authorization':`Bearer ${userToken}`
            }
          }
        )
        .then((response) => {
            
            if(response.status === 200){
                setGame(response.data.data[0]);
                setPageTitle(response.data.data[0].name);
                //const gameName = response.data.data[0].name;
                //setName(gameName);
                //userContext.setGameName(gameName);
                //userContext.setLeagueTableId(response.data.data[0].leagueTableId);
            } 

        })
        .catch(async (error) => {
            console.log(error);
            enqueueSnackbar("Error getting game details", {variant: 'error'});
            // in the case of invalid or expired access token, we re-authenticate the user
            
        })
    }

    const setGame = (data) =>{
        setTitle(data.name);
        setCompany(data.companyId);
        setGameMode(data.mode);
        setActive(data.isActive);
        setNumPlayers(data.numPlayers);
        setNumWarchests(data.numWarChests);
        let startTimes = data.startDate.split(" "); 
        setStartDate(startTimes[0]);
        setStartTime(startTimes[1]);
        let endTimes = data.endDate.split(" "); 
        setEndDate(endTimes[0]);
        setEndTime(endTimes[1]);
        setGameType(data.gameType);
        setWinnings(data.winnerType);
        setFirstPrize(data.prize1);
        setSecondPrize(data.prize2);
        setThirdPrize(data.prize3);
        setNumPlays(data.numPlays);
        setDailyReset(data.daily);
        setGameTimeout(data.timeout);
        setGameLength(data.lifetime);
        setCountdown(data.countdown);
        setSpinTime(data.secondsBetweenSpins);
        setGameClient(data.clientId);
        setEnableBots(data.secondsWaitForBot > 0 ? true : false);
        setBotDelay(data.botDelay);
        setWaitForBot(data.secondsWaitForBot);
        setBotSpinTime(data.secondsBetweenBotSpins);
        setEndGamePopup(data.journeyEndText ? true : false);
        setJourneyEndText(data.journeyEndText ? data.journeyEndText : "");
        setClickThroughURL(data.journeyEndClickThrough ?  data.journeyEndClickThrough : null);
        setCTAText(data.ctaText ? data.ctaText : "");

        
        if(data.weapons){
            const weapon = {...gamesWeapons};
            for(let i=0;i<data.weapons.length;i++){
                let weaponName=weaponIds[data.weapons[i].weaponId];
                weapon["weapon"+weaponName].lifetime = data.weapons[i].lifetime;
                weapon["weapon"+weaponName].isActive = data.weapons[i].isActive;
                weapon["weapon"+weaponName].totalUses = data.weapons[i].totalUses;
            }
            setGameWeapons(weapon);
        }
        
    }

    const getGameData = async() =>{ 
        return await axios
            .get(`${databaseURL}/games/game-form-data`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setCompanies(response.data.data[0].companies);
                    setGameClients(response.data.data[0].gameClients);
                    setTeams(response.data.data[0].teams); 
                    setLeagues(response.data.data[0].leagues);
                    setWeapons(response.data.data[0].weapons);
                  } 
            })
            .catch((error)=>{
                console.log("Error getting gameData");
                enqueueSnackbar("Error getting gameData", {variant: 'error'});
                return false;
            })
    }

    return(
        <div className="mt-4">
            <Row className="mb-1">
                <Col xs={12} >
                    <TournamentHeader id={id}/>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={12} >
                    <h5>{pageTitle}</h5>
                </Col>
            </Row>
            
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Active
                </Col>
                <Col xs={9}>
                    {active === 1 ? "On" : "Off"}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Game Name
                </Col>
                <Col xs={9}>
                    {title}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Company
                </Col>
                <Col xs={9}>

                {companies && companies.map((data,index) =>
                    <>{company === data.id ? data.name : ""}</>
                )}
                    
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Game Type
                </Col>
                <Col xs={9}>

                
                    <>{gameMode}</>

                   
                </Col>
            </Row>
            
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Number of Players
                </Col>
                <Col xs={9}>
                    {numPlayers}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Number Warchests
                </Col>
                <Col xs={9}>
                    {numWarchests}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Start Date & Time
                </Col>
                <Col xs={9}>
                    {getDateLocaleString(startDate+"T"+startTime)}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    End Date & Time
                </Col>
                <Col xs={9}>
                    {getDateLocaleString(endDate+"T"+endTime)}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Winnings
                </Col>
                <Col xs={9}>
                    {winnings === "points" &&
                        <>Winner gets Game Points only</>
                    }
                    {winnings === "prizes" &&
                        <>Winner gets Bonus Points only</>
                    }
                    {winnings === "both" &&
                        <>Winner gets Game and Bonus Points</>
                    }
                    
                    {winnings !== "points" && 
                    <>               
                        <Row className="mb-1 mt-2">
                            <Col xs={12}>Prizes</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12} md={"auto"}>
                                <>{firstPrize}</>
                            </Col>
                            <Col xs={12} md={"auto"}>
                                <>{secondPrize}</>
                            </Col>
                            <Col xs={12} md={"auto"}>
                                <>{thirdPrize}</>
                            </Col>
                        </Row>
                    </>  
                    }
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Number of Plays
                </Col>
                <Col xs={9}>
                    <>{numPlays}</>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Daily Reset
                </Col>
                <Col xs={9}>
                    {dailyReset === 1 ? "On" : "Off"}
                
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Spin Time
                </Col>
                <Col xs={9}>
                    {spinTime}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Countdown
                </Col>
                <Col xs={9}>
                    {countdown}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Game Length
                </Col>
                <Col xs={9}>
                    {gameLength}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Timeout
                </Col>
                <Col xs={9}>
                    {gameTimeout}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Game client
                </Col>
                <Col xs={9}>
                    {gameClients && gameClients.map((client,index) =>
                        <>{gameClient === client.id ? client.gameName : ""}</>
                    )}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Enable Bots
                </Col>
                <Col xs={9}>
                    {enableBots === true ? "On" : "Off"}
                    
                    {enableBots === true &&
                        <>
                        <Row className="mt-3 mb-3">
                            <Col xs={3} className="text-end">
                                Wait for bot
                            </Col>
                            <Col xs={9}>
                                {waitForBot}
                            
                            </Col>
                            
                        </Row>
                        <Row className="mb-3">
                            <Col xs={3} className="text-end">
                                Bot Spin time
                            </Col>
                            <Col xs={9}>
                                {botSpinTime}
                            </Col>
                            
                        </Row>
                        <Row className="mb-3">
                            <Col xs={3} className="text-end">
                                Bot Delay
                            </Col>
                            <Col xs={9}>
                                {botDelay === 1 ? "On" : "Off"}
                            </Col>
                            
                        </Row>
                        </>
                    }
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    End Game Popup
                </Col>
                <Col xs={9}>
                    {endGamePopup === true ? "On" : "Off"}
                    {endGamePopup === true &&
                        <>
                            <Row className="mt-3 mb-3">
                                <Col xs={3} className="text-end">
                                    Journey End Text
                                </Col>
                                <Col xs={9}>
                                    {journeyEndText}
                                </Col>
                                
                            </Row>
                            <Row className="mt-3 mb-3">
                                <Col xs={3} className="text-end">
                                    Click-through URL
                                </Col>
                                <Col xs={9}>
                                    {clickThroughURL}
                                </Col>
                                
                            </Row>
                            <Row className="mt-3 mb-3">
                                <Col xs={3} className="text-end">
                                    CTA Text
                                </Col>
                                <Col xs={9}>
                                    {CTAText}
                                </Col>
                                
                            </Row>
                        </>
                    }
                </Col>
            </Row>     

            <Row className="mt-3 mb-3">
                <Col className="text-center"><Button variant="secondary">Cancel</Button></Col>
            </Row>

            <Row className="mt-2 mb-2">
                <Col style={{fontSize:"20px"}} className="fw-bold">Weapons <span style={{fontSize:"10px", paddingLeft:"10px"}} className="text-muted fw-normal">If you want unlimited use for a weapon, set the Number of Uses to 0</span></Col>
            </Row>
            <Row className="border-top border-bottom">
                <Col xs={2} className="fw-bold"></Col>
                <Col xs={3} className="fw-bold">Name</Col>
                <Col xs={3} className="fw-bold">Active</Col>
                <Col xs={2} className="fw-bold">Lifetime</Col>
                <Col xs={2} className="fw-bold">No of Uses</Col>
            </Row>
            {weapons && weapons.map((weapon, index) =>
                <Row key={index} className="mt-2">
                    <Col xs={2} className="text-center">
                        <img  alt="weapon" style={{width: "50px", marginTop: "6px"}} src={process.env.PUBLIC_URL + `/assets/images/weapons/${weapon.key}Off.png`}/>   
                    </Col>
                    <Col xs={3} className="mt-auto mb-auto">{weapon.name}</Col>
                    <Col xs={3} className="mt-auto mb-auto">
                        {gamesWeapons["weapon"+weapon.key].isActive === 1 ? "On" : "Off"}</Col>
                    <Col xs={2} className="mt-auto mb-auto">
                        {gamesWeapons["weapon"+weapon.key].showLifeTime === true &&
                        <>{gamesWeapons["weapon"+weapon.key].lifetime}</>
                     }
                     </Col>
                    <Col xs={2} className="mt-auto mb-auto">
                        {gamesWeapons["weapon"+weapon.key].totalUses}
                    </Col>
                </Row>
            )}
            <Row className="mt-3 mb-3">
                <Col className="text-center"><Button variant="secondary">Cancel</Button></Col>
            </Row>
        </div>
    )
}

export default ViewTournament;