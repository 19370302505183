export const PrivilegeList = {
    ADMIN_ROLES :{
        "id":1,
        "name":'Manage Roles' 
    },
    ADMIN_USERS :{
        "id":2,
        "name":'Manage Users'
    },
    ADMIN_ACTIONS:{
        "id":3,
        "name":'View Action'
    },
    ADMIN_PROXY :{
        "id":4,
        "name":'Proxy another user'
    },
    ADMIN_SITECONFIG :{
        "id":5,
        "name":'Manage Site Config'
    },
    ADMIN_GAMES :{
        "id":6,
        "name":'Manage Games'
    },
    ADMIN_PLAYERS :{
        "id":7,
        "name":'Manage Players'
    },
    ADMIN_WEAPONS :{
        "id":8,
        "name":'Manage Weapons'
    },
    ADMIN_COMPANIES :{
        "id":9,
        "name":'Manage Companies'
    },
    PROFILE :{
        "id":10,
        "name":'Update profile & password'
    },
    ADMIN_GAMECLIENTS :{
        "id":11,
        "name":'Manage Game Clients'
    },
    ADMIN_GAMETABS :{
        "id":12,
        "name":'Manage Game Tabs'
    },
    ADMIN_FUNDS :{
        "id":13,
        "name":'Manage Funds Reconcilliation'
    },
    ADMIN_TOURNAMENTS :{
        "id":14,
        "name":'Tournament Reports'
    },
    ADMIN_VIEWGAMES :{
        "id":15,
        "name":'View Games Only'
    },
    ADMIN_LEAGUE_TABLES :{
        "id":16,
        "name":'View Monthly Leaderboard'
    },
    ADMIN_RECONCILE :{
        "id":17,
        "name":'Administer Reconcile'
    },
    ADMIN_MFA :{
        "id":18,
        "name":'MFA Required'
    },
    ACCESS :{
        "id":99,
        "name":'Basic access'
    }
}

export const hasPrivilege = (userPrivileges, privilege) =>{
    let hasAccess = false;
    for(let priv in userPrivileges ){
        if(userPrivileges[priv].privilegeId === PrivilegeList[privilege].id){
            hasAccess = true;
            break;
        }
    }

    return hasAccess;
}

export const hasAccesstoUsers = (userPrivileges) =>{
    const roles = ["ADMIN_USERS", "ADMIN_ROLES", "ADMIN_ACTIONS"];
    let hasAccess = false;
    for(let i=0;i<roles.length;i++){
        hasAccess = hasPrivilege(userPrivileges, roles[i]);
        if(hasAccess === true){
            break;
        }
    }
    return hasAccess;
}
export const hasAccesstoSystem = (userPrivileges) =>{
    const roles = ["ADMIN_GAMECLIENTS","ADMIN_WEAPONS", "ADMIN_SITECONFIG", "ADMIN_COMPANIES"];
    let hasAccess = false;
    for(let i=0;i<roles.length;i++){
        hasAccess = hasPrivilege(userPrivileges, roles[i]);
        if(hasAccess === true){
            break;
        }
    }
    return hasAccess;
}

