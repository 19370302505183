import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import App from './App';

const appVersion = "admin.0.0.19";
console.log(appVersion);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

