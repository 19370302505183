import TournamentHeader from "./TournamentHeader";
import { useEffect, useState,useContext,useLayoutEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link,useLocation,useNavigate,useParams } from "react-router-dom";
import axios from "axios";
import UserContext from "../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { hasPrivilege } from "../../components/privileges";


const TournamentAwards = () =>{
    const [pageTitle, setPageTitle] = useState("Awards");
    const [awardData, setAwardData] = useState([]);
    const [gameData, setGameData] = useState();
    const [awardTypes, setAwardTypes] = useState([]);
    const [inboxApplicable , setInboxApplicable] = useState(false);
    const {userToken,databaseURL, privileges} = useContext(UserContext);
    const [disableForm, setDisableForm] = useState(false);
    const { id } = useParams();

    useEffect(() =>{
        if(hasPrivilege(privileges,"ADMIN_GAMES") === true){
            getAwardTypeData();
            if(id){
                getAwardData();
            }
        }
    },[])

    if(hasPrivilege(privileges,"ADMIN_GAMES") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/tournaments"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }

    const getAwardData = async() =>{ 
        return await axios
            .get(`${databaseURL}/games/${id}/awards`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{

                if(response.data){
                    setPageTitle(`Awards For ${response.data.game.name}`);
                    setGameData(response.data.game);
                    if(response.data.gameAwards){
                        setAwardData(response.data.gameAwards)
                        setInboxApplicable(response.data.gameAwards.filter(award => award.inboxid && award.inboxid !== "").length > 0);
    
                    }
                }
            })
            .catch((error)=>{
                console.log("Error getting Award Data");
                enqueueSnackbar("Error getting Award Data", {variant: 'error'});
                return false;
            })
    }

    const getAwardTypeData = async() =>{ 
        return await axios
            .get(`${databaseURL}/games/awards-form-data`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                
                    //setCompanyKey(response.data.data[0].companyKey)
                if(response.status === 200){
                    setAwardTypes(response.data.data)
                    
                }
            })
            .catch((error)=>{
                console.log("Error getting Award Types");
                enqueueSnackbar("Error getting Award Types", {variant: 'error'});
                return false;
            })
    }

    const saveData = async() =>{
        await axios
        .post(`${databaseURL}/games/${id}/awards`, generateBody(),{headers:{
            'Authorization':`Bearer ${userToken}`
        }})
        .then((response) => {
            enqueueSnackbar("Game Awards saved successfully", {variant: 'success'});

            setPageTitle(`Awards For ${response.data.game.name}`);
            if(response.data.gameAwards){
                setAwardData(response.data.gameAwards)
            }
        })
        .catch( async (error) => {

            enqueueSnackbar("Game Awards save failed", {variant: 'error'});
            console.log(error);


        }
        ) 
    }

    const generateBody = () =>{
        const body = ({
            gameAwards: awardData.filter(award => award.minrange !=  null && award.maxrange != null && award.awardtext !=null)
    
        }
        );

        return body;
    }
    const addAward = () =>{
        const newEmptyAward = {};

        if(awardTypes && awardTypes.length > 0){
        newEmptyAward.type = awardTypes[0].text;
        }

        setAwardData(prev => [...prev, newEmptyAward]);
    }

    const removeAward = (gameAward) =>{
        const arr = [...awardData];
        const index = arr.indexOf(gameAward);
        if (index !== -1) {
            arr.splice(index, 1);
            setAwardData([...arr])
        }
    }

    const updateMinRange = (e,gameAward) =>{
        const arr = [...awardData];
        const index = arr.indexOf(gameAward);
        if (index !== -1) {
            arr[index].minrange = e.target.value;
            setAwardData([...arr])
        }
    }
    
    const updateMaxRange = (e,gameAward) =>{
        const arr = [...awardData];
        const index = arr.indexOf(gameAward);
        if (index !== -1) {
            arr[index].maxrange = e.target.value;
            setAwardData([...arr])
        }
    }

    const updateAwardText = (e,gameAward) =>{
        const arr = [...awardData];
        const index = arr.indexOf(gameAward);
        if (index !== -1) {
            arr[index].awardtext = e.target.value;
            setAwardData([...arr])
        }
    }

    const updateAwardValue = (e,gameAward) =>{
        const arr = [...awardData];
        const index = arr.indexOf(gameAward);
        if (index !== -1) {
            arr[index].value = e.target.value;
            setAwardData([...arr])
        }
    }
    const updateAwardType = (e,gameAward) =>{
        const arr = [...awardData];
        const index = arr.indexOf(gameAward);
        if (index !== -1) {
            arr[index].type = e.target.value;
            setAwardData([...arr])
        }
    }

    const updateAwardCode = (e,gameAward) =>{
        const arr = [...awardData];
        const index = arr.indexOf(gameAward);
        if (index !== -1) {
            arr[index].code = e.target.value;
            setAwardData([...arr])
        }
    }

    const updateAwardInboxId = (e,gameAward) =>{
        const arr = [...awardData];
        const index = arr.indexOf(gameAward);
        if (index !== -1) {
            arr[index].inboxid = e.target.value;
            setAwardData([...arr])
        }
    }
    const updateAwardInboxIdbyVal = (val,gameAward) =>{
        const arr = [...awardData];
        const index = arr.indexOf(gameAward);
        if (index !== -1) {
            arr[index].inboxid = val;
            setAwardData([...arr])
        }
    }

    const handleInboxApplicable = (val) =>{
        setInboxApplicable(val);
        if(val === false){
            for(let award in awardData){
                updateAwardInboxIdbyVal("",awardData[award])
            }
        }
    }
    

    const inputKeydown =(evt)=>{
        switch(evt.key){
            case "e":
            case "E":
            case "-":
            case "+":
            case "/":
            case "*":
            case ".":
                evt.preventDefault();
                return false;
        }
    }

    return(
        <div className="mt-4">
            <Row className="mb-1">
                <Col xs={12} >
                    <TournamentHeader id={id} isRealMoney={gameData && gameData.realPlay === 1 ? true : false} isSitAndSpin={gameData && gameData.mode === 3 ? true : false}></TournamentHeader>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={12} >
                    <h5>{pageTitle}</h5>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={12} >
                <Form.Check onChange={e => {handleInboxApplicable(e.target.checked)}} checked={inboxApplicable} type="checkbox" id="inboxApplicable" label={"Inbox applicable"} disabled={disableForm}/> 
                   
                </Col>
            </Row>
            <Row className="pt-2 pb-2 border-bottom border-top">
                <Col xs={1} className="fw-bold">Min</Col>
                <Col xs={1} className="fw-bold">Max</Col>
                <Col xs={4} className="fw-bold">Award</Col>
                <Col xs={1} className="fw-bold">Value</Col>
                <Col xs={2} className="fw-bold">Type</Col>
                {inboxApplicable === true ?
                    <>
                    <Col xs={1} className="fw-bold">Code</Col>
                    <Col xs={1} className="fw-bold">InboxId</Col>
                    </>
                :
                    <Col xs={2} className="fw-bold">Code</Col>
                }
                <Col xs={1} className="fw-bold"></Col>
            </Row>
            {awardData && awardData.map((award, index) =>               
            <Row key={index} className="pt-2 pb-2 border-bottom">
            
                <Col xs={1} className="mt-auto mb-auto"><Form.Control style={{maxWidth:"200px"}} className="mb-1" maxLength="25" type="number" min={0} onKeyDown={e => inputKeydown(e)}  onChange={(e) => updateMinRange(e, award)} size="sm" value={award.minrange} disabled={disableForm}></Form.Control></Col>
                <Col xs={1} className="mt-auto mb-auto"><Form.Control style={{maxWidth:"200px"}} className="mb-1" maxLength="25" type="number" min={0} onKeyDown={e => inputKeydown(e)} onChange={(e) => updateMaxRange(e, award)}  size="sm" value={award.maxrange} disabled={disableForm}></Form.Control></Col>
                <Col xs={4} className="mt-auto mb-auto"><Form.Control className="mb-1" maxLength="25" type="text" onChange={(e) => updateAwardText(e, award)}  size="sm" value={award.awardtext} disabled={disableForm}></Form.Control></Col>
                <Col xs={1} className="mt-auto mb-auto"><Form.Control className="mb-1" maxLength="25" type="text" onChange={(e) => updateAwardValue(e, award)}  size="sm" value={award.value} disabled={disableForm}></Form.Control></Col>
                
                <Col xs={2} className="mt-auto mb-auto">
                
                {awardTypes && awardTypes.length>0 ?
                <Form.Select style={{width:"auto"}} onChange={(e) => updateAwardType(e, award)} aria-label="gameType-select" size="sm" disabled={disableForm}>
                    {awardTypes.map((atype, i)=>
                        <option key={i} value={atype.text} selected={award.type === atype.text ? true : false}>{atype.text}</option>
                    )}
                    
                </Form.Select>
                :
                <Form.Control className="mb-1" maxLength="25" type="text" size="sm" onChange={(e) => updateAwardType(e, award)} value={award.type} disabled={disableForm}></Form.Control>
                
                }   
                </Col>
                {inboxApplicable === true ?
                    <>
                    <Col xs={1} className="mt-auto mb-auto"><Form.Control className="mb-1" type="text" size="sm" value={award.code} onChange={(e) => updateAwardCode(e, award)} disabled={disableForm} ></Form.Control></Col>
                    <Col xs={1} className="mt-auto mb-auto"><Form.Control className="mb-1" type="text" size="sm" value={award.inboxid} onChange={(e) => updateAwardInboxId(e, award)} disabled={disableForm} ></Form.Control></Col>
                
                    </>
                :
                
                    <Col xs={2} className="mt-auto mb-auto"><Form.Control className="mb-1" type="text" size="sm" value={award.code} onChange={(e) => updateAwardCode(e, award)} disabled={disableForm} ></Form.Control></Col>
                }
                {disableForm === false &&
                <Col xs={1} className="mt-auto mb-auto"><Button size="sm" className="mb-1 me-1" variant="secondary" onClick={() => removeAward(award)}>Remove</Button></Col>
                }

            </Row>
            )}
             <Row className="mt-3 mb-3">
             {disableForm === false &&
                <Col className="text-center"><Button variant="secondary" onClick={addAward}>Add Award</Button> <Button onClick={saveData}>Save Award</Button></Col>
             }
            </Row>
        </div>
    );
}
export default TournamentAwards;