import { useEffect, useState,useContext,useLayoutEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link,useLocation,useNavigate,useParams } from "react-router-dom";
import axios from "axios";
import UserContext from "../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { PrivilegeList, hasPrivilege } from "../../components/privileges";

const NewRole = () =>{
    const navigate = useNavigate();
    const location = useLocation();
    const [pageTitle, setPageTitle] = useState("New Role");
    const [gamePageType,setGamePageType] = useState("new");
    const [roleName, setRoleName] = useState("");
 
    const [userPrivileges, setUserPrivileges] = useState({
        privilege1:{ id:1, set:false },
        privilege2:{ id:2, set:false },
        privilege3:{ id:3, set:false },
        privilege4:{ id:4, set:false },
        privilege5:{ id:5, set:false },
        privilege6:{ id:6, set:false },
        privilege7:{ id:7, set:false },
        privilege8:{ id:8, set:false },
        privilege9:{ id:9, set:false },
        privilege10:{ id:10, set:false },
        privilege11:{ id:11, set:false },
        privilege12:{ id:12, set:false },
        privilege13:{ id:13, set:false },
        privilege14:{ id:14, set:false },
        privilege15:{ id:15, set:false },
        privilege16:{ id:16, set:false },
        privilege17:{ id:17, set:false },
        privilege18:{ id:18, set:false },
        privilege99:{ id:99, set:false },

})

    const {userToken,databaseURL, privileges} = useContext(UserContext);
    const {id} = useParams();
    
    useEffect(() =>{
        if(hasPrivilege(privileges,"ADMIN_ROLES") === true){
            if(id){
                setGamePageType("edit");
                getRoleData();
            }
        }
    },[])

    if(hasPrivilege(privileges,"ADMIN_ROLES") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }

    const getRoleData = async() =>{ 
        return await axios
            .get(`${databaseURL}/roles/${id}`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
   
                if(response.status === 200){
                    setRoleName(response.data.data[0].name);

                    const userPrivsData =response.data.data[0].rolePrivileges;
                    const userPriv = {...userPrivileges};
                    for(let i=0;i<userPrivsData.length;i++){
                        userPriv["privilege"+userPrivsData[i].privilegeId].set = true;
                    }
                    setUserPrivileges(userPriv);
                    
                    setPageTitle(`Edit Role ${response.data.data[0].name}`);
                  } 
            })
            .catch((error)=>{
                console.log("Error getting Role");
                enqueueSnackbar("Error getting Role", {variant: 'error'});
                return false;
            })
    }

    const isPrivilegeSet = (pID) =>{
        return userPrivileges["privilege"+pID].set;
    }

    const handleChange = (event)=> {
        if(event){
            const userPriv = {...userPrivileges};
            userPriv["privilege"+event.target.id].set = event.target.checked;
            setUserPrivileges(userPriv);  
        }
    }

    const generateBody = () =>{
        let privilegeData = [];
        //for(let i=0;i<userPrivileges.length;i++){
        for(let priv in userPrivileges ){
            if(userPrivileges[priv].set === true){
                privilegeData.push(userPrivileges[priv].id)
            }
        }

        const body = (
            {
                name: roleName, 
                privileges: privilegeData
            }
        );
        console.log(body);
        return body;
    }

    const saveData = async () =>{
        if(roleName === ""){
            enqueueSnackbar("Please enter a 'Name'", {variant: 'error'});
            return;
        }

        if(gamePageType === "new"){
            await axios
                .post(`${databaseURL}/roles`, generateBody(),{headers:{
                    'Authorization':`Bearer ${userToken}`
                }})
                .then((response) => {
                    //navigate(`/games/${response.data.data[0].id}`, {state: { data: response.data.data[0] }});
                    enqueueSnackbar("Role created successfully", {variant: 'success'});
                    navigate('/roles', { replace: true, state: { from: location } });

                })
                .catch( async (error) => {
                    enqueueSnackbar("Role creation has failed", {variant: 'error'});
                    console.log(error);

                }) 
        }else{
            await axios
                .put(`${databaseURL}/roles/${id}`, generateBody(),{headers:{
                    'Authorization':`Bearer ${userToken}`
                }})
                .then((response) => {
                    //navigate(`/games/${response.data.data[0].id}`, {state: { data: response.data.data[0] }});
                    enqueueSnackbar("Role updated successfully", {variant: 'success'});
                    navigate('/roles', { replace: true, state: { from: location } });

                })
                .catch( async (error) => {
                    enqueueSnackbar("Role updated has failed", {variant: 'error'});
                    console.log(error);

                }) 
        }
    }

    return(
        <div className="mt-4">  
            <Row className="mb-4">
                <Col xs={6} >
                    <h5>{pageTitle}</h5>
                </Col>
                <Col xs={6} >
                    <div className="text-end"><Link to="/roles"><Button size="sm">Back</Button></Link></div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Role Name
                </Col>
                <Col xs={9}>
                   
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setRoleName(e.target.value )} value={roleName}></Form.Control>
                               
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Basic Access
                </Col>
                <Col xs={9}>
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ACCESS.id)} type="checkbox" id={PrivilegeList.ACCESS.id} label={PrivilegeList.ACCESS.name}/></div>      
                    <div><Form.Check onChange={handleChange}  checked={isPrivilegeSet(PrivilegeList.PROFILE.id)} type="checkbox" id={PrivilegeList.PROFILE.id} label={PrivilegeList.PROFILE.name}/></div>     
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Admin
                </Col>
                <Col xs={9}>
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_PLAYERS.id)} type="checkbox" id={PrivilegeList.ADMIN_PLAYERS.id} label={PrivilegeList.ADMIN_PLAYERS.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_GAMES.id)} type="checkbox" id={PrivilegeList.ADMIN_GAMES.id} label={PrivilegeList.ADMIN_GAMES.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_USERS.id)} type="checkbox" id={PrivilegeList.ADMIN_USERS.id} label={PrivilegeList.ADMIN_USERS.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_ACTIONS.id)} type="checkbox" id={PrivilegeList.ADMIN_ACTIONS.id} label={PrivilegeList.ADMIN_ACTIONS.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_PROXY.id)} type="checkbox" id={PrivilegeList.ADMIN_PROXY.id} label={PrivilegeList.ADMIN_PROXY.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_FUNDS.id)} type="checkbox" id={PrivilegeList.ADMIN_FUNDS.id} label={PrivilegeList.ADMIN_FUNDS.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_TOURNAMENTS.id)} type="checkbox" id={PrivilegeList.ADMIN_TOURNAMENTS.id} label={PrivilegeList.ADMIN_TOURNAMENTS.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_VIEWGAMES.id)} type="checkbox" id={PrivilegeList.ADMIN_VIEWGAMES.id} label={PrivilegeList.ADMIN_VIEWGAMES.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_LEAGUE_TABLES.id)} type="checkbox" id={PrivilegeList.ADMIN_LEAGUE_TABLES.id} label={PrivilegeList.ADMIN_LEAGUE_TABLES.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_RECONCILE.id)} type="checkbox" id={PrivilegeList.ADMIN_RECONCILE.id} label={PrivilegeList.ADMIN_RECONCILE.name}/></div>        
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    HBAdmin
                </Col>
                <Col xs={9}>
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_WEAPONS.id)} type="checkbox" id={PrivilegeList.ADMIN_WEAPONS.id} label={PrivilegeList.ADMIN_WEAPONS.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_ROLES.id)} type="checkbox" id={PrivilegeList.ADMIN_ROLES.id} label={PrivilegeList.ADMIN_ROLES.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_COMPANIES.id)} type="checkbox" id={PrivilegeList.ADMIN_COMPANIES.id} label={PrivilegeList.ADMIN_COMPANIES.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_SITECONFIG.id)} type="checkbox" id={PrivilegeList.ADMIN_SITECONFIG.id} label={PrivilegeList.ADMIN_SITECONFIG.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_GAMECLIENTS.id)} type="checkbox" id={PrivilegeList.ADMIN_GAMECLIENTS.id} label={PrivilegeList.ADMIN_GAMECLIENTS.name}/></div>    
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_RECONCILE.id)} type="checkbox" id={PrivilegeList.ADMIN_RECONCILE.id} label={PrivilegeList.ADMIN_RECONCILE.name}/></div>    
      
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Login
                </Col>
                <Col xs={9}>
                    <div><Form.Check onChange={handleChange} checked={isPrivilegeSet(PrivilegeList.ADMIN_MFA.id)} type="checkbox" id={PrivilegeList.ADMIN_MFA.id} label={PrivilegeList.ADMIN_MFA.name}/></div>    
                </Col>
            </Row>

            <Row className="mt-3 mb-3">
                <Col className="text-center"><Button onClick={saveData}>Save</Button> <Link to="/roles"><Button  variant="secondary">Cancel</Button></Link></Col>
            </Row>
        </div>
    )
}

export default NewRole;