import { useEffect, useState } from "react";
import UserContext from "./appContext";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { getDeviceId } from "../utils/deviceIdHelper";
import {enqueueSnackbar } from "notistack";

const AuthProvider =  ({children}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showLogin, setShowLogin] = useState(false);
    const [showMFA, setShowMFA] = useState(false);
    const [loggedIn, setLoggedIn ] = useState(false);
    const [userToken,setUserToken ] = useState();
    const [refreshToken,setRefreshToken ] = useState();
    const [userName, setUserName] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [userEmail, setUserEmail] = useState();
    const [userId, setUserId] = useState();
    const [role, setRole] = useState();
    const [privileges, setPrivileges] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const [databaseURL, setDatabaseURL] = useState(window._slotmastersenv_ && window._slotmastersenv_.REACT_APP_DATABASE_URL ?  window._slotmastersenv_.REACT_APP_DATABASE_URL : process.env.REACT_APP_DATABASE_URL);
    
    const authenticateUser = async() =>{
        const hbAdminToken = localStorage.getItem("hbAdminToken")
        const hbAdminRefreshToken = localStorage.getItem("hbAdminRefreshToken")
        const savedAccessToken = hbAdminToken === "undefined" ? "" : JSON.parse(hbAdminToken);
        const savedRefreshToken = hbAdminRefreshToken === "undefined" ? "" : JSON.parse(hbAdminRefreshToken);

        if(savedAccessToken && savedRefreshToken){
            return await axios
            .post(`${databaseURL}/refresh`, {accessToken: savedAccessToken, refreshToken: savedRefreshToken, deviceId: getDeviceId()} )
            .then((response) => {
                if(response.status !== 200){
                    rediectToLoginPage();
                    return false;
                } else {
                    setUserToken(response.data.data[0].accessToken);
                    setRefreshToken(response.data.data[0].refreshToken);
                    setLogInAndRedirectUser(response.data.data[0]);
                    if(!loggedIn) { enqueueSnackbar("Welcome back " + response.data.data[0].user.username, {variant: 'success'}); }
                    
                    return true;
                }
            })
            .catch((error) => {
                
                rediectToLoginPage();
                return false;
            })
            
            } else {
            rediectToLoginPage();
            return false;
            }
        
    }

    const handleMFA = async(mfacode) =>{
        if(mfacode === ''){
            setErrorMessage("Please Enter the code sent to your email");
            enqueueSnackbar("Please Enter the code sent to your email", {variant: 'error'});
            return false;
        }

        return await axios
            .post(`${databaseURL}/mfa`,{code: mfacode, deviceId: getDeviceId()},{headers:{
                'Authorization':`Bearer ${userToken}`
            }})
            .then((response) => {
              if(response.status === 200){
                setErrorMessage("");
                  localStorage.removeItem("hbAdminToken")
                  localStorage.removeItem("hbAdminRefreshToken")
                  localStorage.setItem("hbAdminToken", JSON.stringify(userToken));
                  localStorage.setItem("hbAdminRefreshToken", JSON.stringify(refreshToken));
                  setUserToken(userToken);
                    setRefreshToken(refreshToken);
                  setLogInAndRedirectUser(response.data.data[0].authResponse);
                  enqueueSnackbar("Welcome back " + response.data.data[0].authResponse.user.username, {variant: 'success'});
                  setShowMFA(false);
                  return true;
              }else{
                setErrorMessage(response.data.error);
                  enqueueSnackbar(response.data.error, {variant: 'error'});
                  return false;
              }
            })
            .catch((error) => {
                setErrorMessage("Invalid credentials");
                enqueueSnackbar("Invalid credentials", {variant: 'error'});
                return false;
            })
    }

    const handleLogin = async(userName,userPassword) =>{
        if(userName === ''){
            setErrorMessage("Username is not valid");
            enqueueSnackbar("Username is not valid", {variant: 'error'});
            return false;
        }
  
        if(userPassword === ''){
            setErrorMessage("Please Enter a password");
            enqueueSnackbar("Please Enter a password", {variant: 'error'});
            return false;
        }
        setShowMFA(false);
        return await axios
            .post(`${databaseURL}/login`,{username: userName, password:userPassword, deviceId: getDeviceId()})
            .then((response) => {

              if(response.status !== 200){
   
                  setErrorMessage(response.data.error);
                  enqueueSnackbar(response.data.error, {variant: 'error'});
                  return false;
                
              }else{

                if(response.data.status === "MFA code input required"){
                    //setShowLogin(false);
                    setUserToken(response.data.authResponse.accessToken);
                    setRefreshToken(response.data.authResponse.refreshToken);
                    setShowMFA(true);
                }else{
                    setErrorMessage("");
                  //setUserData(response.data);
                  localStorage.removeItem("hbAdminToken")
                  localStorage.removeItem("hbAdminRefreshToken")
                  localStorage.setItem("hbAdminToken", JSON.stringify(response.data.accessToken));
                  localStorage.setItem("hbAdminRefreshToken", JSON.stringify(response.data.refreshToken));
                  setUserToken(response.data.accessToken);
                  setRefreshToken(response.data.refreshToken);
                  setLogInAndRedirectUser(response.data);
                  enqueueSnackbar("Welcome back " + response.data.user.username, {variant: 'success'});

                  return true;
                }
              }
            })
            .catch((error) => {
                setErrorMessage("Invalid credentials");
                enqueueSnackbar("Invalid credentials", {variant: 'error'});
                return false;
            })
  
    }

    const setLogInAndRedirectUser = (data) =>{

        setUserData(data);
        setLoggedIn(true);
        setShowLogin(false);
        let origin = location.state?.from?.pathname || location.pathname;
    
        if(origin === "/login"){ origin = "/"; }

        navigate(origin);
      }

    const rediectToLoginPage = () => {
        setLoggedIn(false);
        setShowLogin(true);
        navigate('/login', { replace: true, state: { from: location } });
      };

    const setUserData = (data) =>{ 

        setUserName(data.user.username);
        setFirstName(data.user.firstname);
        setLastName(data.user.lastname);
        setUserEmail(data.user.email);
        setUserId(data.user.id);
        if(data.role){
            setRole(data.role.name);
            setPrivileges(data.role.privileges);
        }

    }

    const handleLogout = () =>{
        localStorage.removeItem("hbAdminToken")
        localStorage.removeItem("hbAdminRefreshToken")
        setShowLogin(true);
        navigate('/login');
      }

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }
    useEffect(()=>{
        //setShowLogin(true);
        authenticateUser();
    },[])
  

    const value = {
        showLogin,
        showMFA,
        userToken,
        userId,
        userName,
        firstName,
        lastName,
        loggedIn,
        userEmail,
        databaseURL,
        role,
        privileges,
        onLogin:handleLogin,
        onLogout:handleLogout,
        onMFA: handleMFA,
        errorMessage
    }

    return(
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )

}

export default AuthProvider;