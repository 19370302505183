import { useEffect, useState,useContext,useLayoutEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link,useLocation,useNavigate,useParams } from "react-router-dom";
import axios from "axios";
import UserContext from "../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { hasPrivilege } from "../../components/privileges";

const SiteConfig = () => {
    const [siteTitle, setSiteTitle] = useState("");
    const [loginRequired, setLoginRequired] = useState("0");
    const [enableSitAndSpin, setEnableSitAndSpin] = useState("0");
    const [enableRealMoney, setEnableRealMoney] = useState("0");
    const [enableEndGamePopup, setEnableEndGamePopup] = useState("0");
    const [enableEndGamePostEvent, setEnableEndGamePostEvent] = useState("0");
    const [disableLiveTournament, setDisableLiveTournament] = useState("0");
    const [enableCurrencyFiltering, setEnableCurrencyFiltering] = useState("0");
    const {userToken, databaseURL, privileges} = useContext(UserContext);
    
    useEffect(() =>{
        if(hasPrivilege(privileges,"ADMIN_SITECONFIG") === true){
            getSiteConfigs();
        }
      },[]);

      if(hasPrivilege(privileges,"ADMIN_SITECONFIG") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }

      const getSiteConfigs = async() =>{ 
        return await axios
            .get(`${databaseURL}/site-configs`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    response.data.data.forEach(config =>{
                        if(config.configKey === "title"){
                            setSiteTitle(config.configValue);
                        }else if(config.configKey === "LoginRequired"){
                            setLoginRequired(config.configValue);
                        }else if(config.configKey === "enableSitAndSpin"){
                            setEnableSitAndSpin(config.configValue);
                        }else if(config.configKey === "enableRealMoney"){
                            setEnableRealMoney(config.configValue);
                        }else if(config.configKey === "enableEndGamePopup"){
                            setEnableEndGamePopup(config.configValue);
                        }else if(config.configKey === "enableEndGamePostEvent"){
                            setEnableEndGamePostEvent(config.configValue);
                        }else if(config.configKey === "disableLiveTournament"){
                            setDisableLiveTournament(config.configValue);
                        }else if(config.configKey === "enableCurrencyFiltering"){
                            setEnableCurrencyFiltering(config.configValue);
                        }
                        
                    })
                } 
            })
            .catch((error)=>{
                console.log("Error getting Site Config Data");
                enqueueSnackbar("Error getting Site Config Data", {variant: 'error'});
            
                return false;
            })
    }

    const generateBody = () =>{
        const body = (
            {
                siteConfigs: [
                  {configKey: "title", configValue: siteTitle},
                  {configKey: "LoginRequired", configValue: loginRequired}, 
                  {configKey: "enableSitAndSpin", configValue: enableSitAndSpin}, 
                  {configKey: "enableRealMoney", configValue: enableRealMoney},
                  {configKey: "enableEndGamePopup", configValue: enableEndGamePopup},
                  {configKey: "enableEndGamePostEvent", configValue: enableEndGamePostEvent},
                  {configKey: "disableLiveTournament", configValue: disableLiveTournament},
                  {configKey: "enableCurrencyFiltering", configValue: enableCurrencyFiltering}
                ],
              }
        );

        return body;
    }

    const saveData = async () =>{
        if(siteTitle === ""){
            enqueueSnackbar("Please enter a 'Site Title'", {variant: 'error'});
            return;
        }

    
        await axios
            .put(`${databaseURL}/site-configs`, generateBody(),{headers:{
                'Authorization':`Bearer ${userToken}`
            }})
            .then((response) => {
                //navigate(`/games/${response.data.data[0].id}`, {state: { data: response.data.data[0] }});
                enqueueSnackbar("Site configs updated successfully", {variant: 'success'});
            })
            .catch( async (error) => {
                enqueueSnackbar("Site configs updated has failed", {variant: 'error'});
                console.log(error);

            }) 
        
    }

    return (
        <div className="mt-4">
            <Row className="mb-4">
                <Col xs={12} >
                    <h5>Site Settings</h5>
                </Col>
                
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Site Title
                </Col>
                <Col xs={9}>
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setSiteTitle(e.target.value )} value={siteTitle}></Form.Control>   
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Login Required
                </Col>
                <Col xs={9}>
                    <Form.Check inline label="On" checked={loginRequired === "1" ? true : false} name="activegroup" type="radio" id='acive-on' onChange={(e)=> setLoginRequired("1")} />
                    <Form.Check inline label="Off" checked={loginRequired === "0" ? true : false} name="activegroup" type="radio" id='active-off' onChange={(e)=> setLoginRequired("0")} />
                    
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Enable Sit and Spin
                </Col>
                <Col xs={9}>
                    <Form.Check inline label="On" checked={enableSitAndSpin === "1" ? true : false} name="enableSitAndSpingroup" type="radio" id='enableSitAndSpin-on' onChange={(e)=> setEnableSitAndSpin("1")} />
                    <Form.Check inline label="Off" checked={enableSitAndSpin === "0" ? true : false} name="enableSitAndSpingroup" type="radio" id='enableSitAndSpin-off' onChange={(e)=> setEnableSitAndSpin("0")} />
                    
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Enable Real Money
                </Col>
                <Col xs={9}>
                    <Form.Check inline label="On" checked={enableRealMoney === "1" ? true : false} name="enableRealMoneygroup" type="radio" id='enableRealMoney-on' onChange={(e)=> setEnableRealMoney("1")} />
                    <Form.Check inline label="Off" checked={enableRealMoney === "0" ? true : false} name="enableRealMoneygroup" type="radio" id='enableRealMoney-off' onChange={(e)=> setEnableRealMoney("0")} />
                    
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Disable Tournament If live
                </Col>
                <Col xs={9}>
                    <Form.Check inline label="On" checked={disableLiveTournament === "1" ? true : false} name="disableLiveTournamentgroup" type="radio" id='disableLiveTournament-on' onChange={(e)=> setDisableLiveTournament("1")} />
                    <Form.Check inline label="Off" checked={disableLiveTournament === "0" ? true : false} name="disableLiveTournamentgroup" type="radio" id='disableLiveTournament-off' onChange={(e)=> setDisableLiveTournament("0")} />
                    
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Enable Currency Filtering
                </Col>
                <Col xs={9}>
                    <Form.Check inline label="On" checked={enableCurrencyFiltering === "1" ? true : false} name="enableCurrencyFilteringgroup" type="radio" id='enableCurrencyFiltering-on' onChange={(e)=> setEnableCurrencyFiltering("1")} />
                    <Form.Check inline label="Off" checked={enableCurrencyFiltering === "0" ? true : false} name="enableCurrencyFilteringgroup" type="radio" id='enableCurrencyFiltering-off' onChange={(e)=> setEnableCurrencyFiltering("0")} />
                    
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Enable End Game Popup
                </Col>
                <Col xs={9}>
                    <Row className="mb-3">
                        <Col>
                        <Form.Check inline label="On" checked={enableEndGamePopup === "1" ? true : false} name="enableEndGamePopupgroup" type="radio" id='enableEndGamePopup-on' onChange={(e)=> setEnableEndGamePopup("1")} />
                        <Form.Check inline label="Off" checked={enableEndGamePopup === "0" ? true : false} name="enableEndGamePopupgroup" type="radio" id='enableEndGamePopup-off' onChange={(e)=> setEnableEndGamePopup("0")} />
                        </Col>
                    </Row>
                    {enableEndGamePopup === "1" &&
                    <Row className="mb-3">
                        <Col xs={3} className="text-end">
                            Enable Post Events
                        </Col>
                        <Col xs={9}>
                            <Form.Check inline label="On" checked={enableEndGamePostEvent === "1" ? true : false} name="enableEndGamePostEvent" type="radio" id='enableEndGamePostEvent-on' onChange={(e)=> setEnableEndGamePostEvent("1")} />
                            <Form.Check inline label="Off" checked={enableEndGamePostEvent === "0" ? true : false} name="enableEndGamePostEvent" type="radio" id='enableEndGamePostEvent-off' onChange={(e)=> setEnableEndGamePostEvent("0")} />
                            
                        </Col>
                    </Row>
                    }
                </Col>
            </Row>
            
            <Row className="mt-3 mb-3">
                <Col className="text-center"><Button onClick={saveData}>Update</Button></Col>
            </Row>
        </div>
    );  
}
    
export default SiteConfig;