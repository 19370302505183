import { createContext } from 'react';

const UserContext = createContext({
    userToken:'',
    refreshToken:'',
    databaseURL:'',
    userId:'',
    userName:'',
    userEmail:'',
    firstName:'',
    lastName:'',
    privileges:[],
    role:'',
    authenticated:true,
    loggedIn:false,
});

export default UserContext; 