import { useContext, useEffect, useLayoutEffect, useState } from "react";
import UserContext from "../../components/appContext";
import axios from "axios";
import {enqueueSnackbar } from "notistack";
import { Col, Row, Form, Button, Badge, Pagination } from "react-bootstrap";
import { getDateLocaleString, convertUctToLocalTime, convertLocalTimeToUtcDbString, getCurrentLocalTime } from "../../utils/timezone";
import { Link, useNavigate, useParams } from "react-router-dom";
import Paginator from "../../components/Paginator";

const GamePlays = () => {

    const [gamePlays, setGamePlays] = useState([]);
    const [player, setPlayer] = useState();
    const {userToken,databaseURL,roles} = useContext(UserContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageNum,setPageNum] = useState(1);
    const navigate = useNavigate();

    const { id} = useParams();

    useEffect(() =>{ 
        if(id){
            getGamePlays();
        }
    },[pageNum])

    const getGamePlays = async()=>{

        return await axios 
            .get(`${databaseURL}/players/${id}/gameplays?page=${pageNum}`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setGamePlays(response.data.data[0].gameplays.data);
                    setPlayer(response.data.data[0].player);
                    setCurrentPage(response.data.data[0].gameplays.currentPage);
                    setTotalPages(response.data.data[0].gameplays.totalPages);
                } 
            })
            .catch((error)=>{
                console.log("Error getting Games");
                return false;
            })
    }

    const onPaginationClick = (num) =>{
        setPageNum(num);
    }

    return(
        <div className="mt-4">
        <Row className="mb-2">
            <Col xs={6} >
                <h5>Games Played for {player && player.name ? player.name : ""} ({player && player.id ? player.id : ""})</h5>
            </Col>
            <Col xs={6} >
                    <div className="text-end"><Button size="sm" onClick={() => navigate(-1)}>Back</Button></div>
                </Col>
        </Row>
        <Row className="pt-2 pb-2 border-bottom border-top">
            <Col xs={1} className="fw-bold">ID</Col>
            <Col xs={4} className="fw-bold">Game(ID)/Date</Col>
            <Col xs={3} className="fw-bold">Player/Position</Col>
            <Col xs={3} className="fw-bold">Winner</Col>
            <Col xs={1} className="fw-bold">Action</Col>
        </Row>
        {gamePlays && gamePlays.map((play, index) =>               
        <Row key={index} className="pt-2 pb-2 border-bottom">
            <Col xs={1} className="mt-auto mb-auto">{play.id}</Col>
            <Col xs={4} className="mt-auto mb-auto"><b>{play.name}</b> ({play.gameId})<br/><span className="text-muted">{getDateLocaleString(play.dateCreated)}</span></Col>
            <Col xs={3} className="mt-auto mb-auto"><b>{play.playerName}</b> <span className="text-muted" style={{"fontSize":"12px"}}>({play.totalWinnings} points)</span><br/>Position: <b>{play.place}</b></Col>
            <Col xs={3} className="mt-auto mb-auto">{play.winner}</Col>
            <Col xs={1} className="mt-auto mb-auto"><Link to={`/gamedetails/${play.id}/${id}`} ><Button size="sm" className="mb-1 me-1" >View</Button></Link> 
            </Col>
        </Row>
        )}
        {totalPages && totalPages > 1 &&
        <div className="pt-2"  style={{ display: "flex", justifyContent: "center" }}>
           
            <Paginator totalPages={totalPages} currentPage={currentPage} onPaginationClick={onPaginationClick}/>
        </div>
    }
    </div>
    )
}

export default GamePlays;