import { useEffect, useState,useContext,useLayoutEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link,useLocation,useNavigate,useParams } from "react-router-dom";
import axios from "axios";
import UserContext from "../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { hasPrivilege } from "../../components/privileges";

const NewUser = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [pageTitle, setPageTitle] = useState("Add User");
    const [gamePageType,setGamePageType] = useState("new");
    const {userToken,databaseURL, privileges} = useContext(UserContext);
    const [roleId, setRoleId] = useState(1);
    const [isActive, setIsActive] = useState(1);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [isTwoFactorActive, setIsTwoFactorActive] = useState(0);
    const [role, setRole] = useState();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [userRoles, setUserRoles] = useState([]);
    
    const {id} = useParams();

    useEffect(() =>{
        if(hasPrivilege(privileges,"ADMIN_USERS") === true){
            getUserRoles();
            if(id){
                setPageTitle(`Edit ${id}`);
                setGamePageType("edit");
                getUserData();
            }

        }
    },[])

    const getUserRoles = async()=>{

        return await axios 
            .get(`${databaseURL}/roles`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setUserRoles(response.data.data);
                } 
            })
            .catch((error)=>{
                console.log("Error getting Roles");
                enqueueSnackbar("Error getting Roles", {variant: 'error'});
                return false;
            })
    }

    if(hasPrivilege(privileges,"ADMIN_USERS") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }

    const getUserData = async() =>{ 
        return await axios
            .get(`${databaseURL}/users/${id}`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setRoleId(response.data.data[0].roleId)
                    setIsActive(response.data.data[0].isActive)
                    setFirstname(response.data.data[0].firstname)
                    setLastname(response.data.data[0].lastname)
                    setEmail(response.data.data[0].email)
                    setMobile(response.data.data[0].mobile)
                    setIsTwoFactorActive(response.data.data[0].isTwoFactorActive)
                    setRole(response.data.data[0].role)
                    setUsername(response.data.data[0].username)
                    setPageTitle(`Edit ${response.data.data[0].username} ${id}`);
                    
                  } 
            })
            .catch((error)=>{
                console.log("Error getting userData");
                enqueueSnackbar("Error getting userData", {variant: 'error'});
                return false;
            })
    }

    const generateBody = () =>{

        const body = (
            {
                firstname: firstname, 
                lastname: lastname, 
                email: email,
                username: username, 
                password: password,
                mobile: mobile,
                roleId: roleId,
            
          }
        );

        return body;
    }

    const saveUser = async () =>{
        if(email === ""){
            enqueueSnackbar("Please enter a 'Email'", {variant: 'error'});
            return;
        }
        if(username === ""){
            enqueueSnackbar("Please enter a 'Username'", {variant: 'error'});
            return;
        }
        if(gamePageType === "new" && password === ""){
            enqueueSnackbar("Please enter a 'Paswword'", {variant: 'error'});
            return;
        }
        if(gamePageType === "new"){
            await axios
                .post(`${databaseURL}/users`, generateBody(),{headers:{
                    'Authorization':`Bearer ${userToken}`
                }})
                .then((response) => {
                    //navigate(`/games/${response.data.data[0].id}`, {state: { data: response.data.data[0] }});
                    enqueueSnackbar("User created successfully", {variant: 'success'});
                    navigate('/users', { replace: true, state: { from: location } });

                })
                .catch( async (error) => {
                    enqueueSnackbar("User creation has failed", {variant: 'error'});
                    console.log(error);

                }) 
        }else{
            await axios
                .put(`${databaseURL}/users/${id}`, generateBody(),{headers:{
                    'Authorization':`Bearer ${userToken}`
                }})
                .then((response) => {
                    //navigate(`/games/${response.data.data[0].id}`, {state: { data: response.data.data[0] }});
                    enqueueSnackbar("User updated successfully", {variant: 'success'});
                    navigate('/users', { replace: true, state: { from: location } });

                })
                .catch( async (error) => {
                    enqueueSnackbar("User updated has failed", {variant: 'error'});
                    console.log(error);

                }) 
        }
    }


    return (
        <div className="mt-4">
            <Row className="mb-4">
                <Col xs={6} >
                    <h5>{pageTitle}</h5>
                </Col>
                <Col xs={6} >
                    <div className="text-end"><Link to="/users"><Button size="sm">Back</Button></Link></div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                First Name
                </Col>
                <Col xs={9}>
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setFirstname(e.target.value )} value={firstname}></Form.Control>   
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Last Name
                </Col>
                <Col xs={9}>                  
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setLastname(e.target.value )} value={lastname}></Form.Control>               
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Email
                </Col>
                <Col xs={9}> 
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setEmail(e.target.value )} value={email}></Form.Control>              
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Mobile
                </Col>
                <Col xs={9}>
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setMobile(e.target.value )} value={mobile}></Form.Control>              
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Login Name
                </Col>
                <Col xs={9}>  
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setUsername(e.target.value )} value={username}></Form.Control>              
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Password
                </Col>
                <Col xs={9}>  
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setPassword(e.target.value )} value={password}></Form.Control>              
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                    Role
                </Col>
                <Col xs={9}>
                    <Form.Select style={{width:"auto"}} aria-label="gameType-select" size="sm" onChange={(e)=> setRoleId(e.target.value) }>
                    {userRoles && userRoles.map((role, index) => 
                        <option value={role.id} selected={roleId === role.id ? true : false}>{role.name}</option>
                    )}
                        {/*<option value={1} selected={roleId === 1 ? true : false}>Admin</option>
                        <option value={2} selected={roleId === 2 ? true : false}>Partner Admin</option>
                        <option value={3} selected={roleId === 3 ? true : false}>Customer Support</option>*/}
                    </Form.Select>
                   
                </Col>
            </Row>
            <Row className="mt-3 mb-3">
                <Col className="text-center"><Button onClick={saveUser}>Save</Button> <Link to="/users"><Button variant="secondary">Cancel</Button></Link></Col>
            </Row>
        </div>
    );
}
    
export default NewUser;