import { useContext, useEffect, useLayoutEffect, useState } from "react";
import UserContext from "../../components/appContext";
import axios from "axios";
import {enqueueSnackbar } from "notistack";
import { Col, Row, Form, Button, Badge, Pagination } from "react-bootstrap";
import { getDateLocaleString, convertUctToLocalTime, convertLocalTimeToUtcDbString, getCurrentLocalTime,convertUctToLocalTimeNoTZ } from "../../utils/timezone";
import { Link } from "react-router-dom";
import Paginator from "../../components/Paginator";
import { hasPrivilege } from "../../components/privileges";

const Tournaments = () =>{
    const [companies, setCompanies] = useState([]);
    const {userToken,databaseURL,privileges} = useContext(UserContext);
    const [showFilter, setShowFilter] = useState(1);
    const [orderByFilter, setOrderByFilter] = useState(2);
    const [companyFilter, setCompanyFilter] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageNum,setPageNum] = useState(1);
    const [tournaments, setTournaments] = useState([]);

    useEffect(() =>{ 
        getAllCompanies();
        getTournaments();
    },[companyFilter,orderByFilter,showFilter,pageNum])

    const getAllCompanies = async() =>{ 
        return await axios
            .get(`${databaseURL}/companies`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setCompanies(response.data.data);
                  } 
            })
            .catch((error)=>{
                console.log("Error getting Companies");
                enqueueSnackbar("Error getting Companies", {variant: 'error'});
                return false;
            })
    }

    const getTournaments = async()=>{

        return await axios 
            .get(`${databaseURL}/games?page=${pageNum}&show=${showFilter}&orderby=${orderByFilter}&company-id=${companyFilter}`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setTournaments(response.data.data);
                    setCurrentPage(response.data.currentPage);
                    setTotalPages(response.data.totalPages);
                } 
            })
            .catch((error)=>{
                console.log("Error getting Games");
                return false;
            })
    }

    const onPaginationClick = (num) =>{
        setPageNum(num);
    }

    const getPages = () =>{
        let items = [];
        for(let num=1; num<=totalPages; num++){
            items.push(<Pagination.Item key={num} active={num === currentPage} onClick={()=>onPaginationClick(num)}>{num}</Pagination.Item> ) 
        } 
        return items;
    }

    const renderTournamentStatus = (isActive, startDate, endDate) => {
        const badgeWidth = "90px";
        if(isActive === 0){
          return <Badge pill bg="none" style={{"backgroundColor": "#d65b58", "paddingLeft":"18px", "paddingRight":"18px"}}>In Active</Badge>
        } else{
    
          const convertedStartDate = convertUctToLocalTimeNoTZ(startDate);
          const convertedEndDate = convertUctToLocalTimeNoTZ(endDate);
    
          if(convertedStartDate > getCurrentLocalTime()) {
            // upcoming
            return <Badge pill bg="none" style={{"backgroundColor": "#52b2bf", "paddingLeft":"14px", "paddingRight":"14px"}} label="Upcoming">Upcoming</Badge>
 
          } else if(convertedStartDate < getCurrentLocalTime() && convertedEndDate > getCurrentLocalTime()){
            // currently active
            return <Badge pill bg="none" style={{"backgroundColor": "greenyellow", "color":"black", "paddingLeft":"26px", "paddingRight":"26px"}}>Active</Badge>
          } else if (convertedEndDate <= getCurrentLocalTime()){
            // finished one
            return <Badge pill bg="none" style={{"backgroundColor": "green", "paddingLeft":"12px", "paddingRight":"12px"}}>Completed</Badge>
          }
    
        }
      }

    return(
        <div className="mt-4">
            <Row className="mb-2">
                <Col xs={6} >
                    <h5>Tournaments <span  style={{fontSize:"1rem", fontWeight:"400"}} className="text-muted">(Timezone: {window._slotmastersenv_ && window._slotmastersenv_.REACT_APP_TIME_ZONE ? window._slotmastersenv_.REACT_APP_TIME_ZONE : "Local"})</span></h5>
                </Col>
                <Col xs={6} >
                    {hasPrivilege(privileges,"ADMIN_VIEWGAMES") !== true &&
                    <div className="text-end"><Link to="/addtournament"><Button size="sm">Add</Button></Link></div>
                    }
                </Col>
            </Row>
            <Row className="mb-2">
                <Col>
                    
                </Col>
                <Col xs="auto">
                    <div style={{fontSize:"0.8rem"}} className="text-right">By Status</div>
                    <Form.Select value={showFilter} onChange={(e)=> setShowFilter(e.target.value)} size="sm" aria-label="default" style={{width:"auto"}}>
                        <option value={1}>All</option>
                        <option value={2}>Active</option>
                        <option value={3}>Completed</option>
                        <option value={4}>Upcoming</option>
                        <option value={5}>In-Active</option>
                    </Form.Select>
                </Col>
                <Col xs="auto">
                    <div style={{fontSize:"0.8rem"}}>Order By</div>
                    <Form.Select value={orderByFilter} onChange={(e)=> setOrderByFilter(e.target.value) } size="sm" aria-label="default" style={{width:"auto"}}>
                        <option value={3}>Date ascending</option>
                        <option value={2}>Date descending</option>
                        <option value={5}>Name ascending</option>
                        <option value={6}>Name descending</option>
                    </Form.Select>
                </Col>
                <Col xs="auto">
                    <div style={{fontSize:"0.8rem"}}>By Company</div>
                    <Form.Select value={companyFilter} onChange={(e)=> setCompanyFilter(e.target.value) } size="sm" aria-label="default" style={{width:"auto"}}>
                        <option value={0}>All</option>
                        {companies && companies.map((company,index) =>
                            <option value={company.id} key={index} >{company.name}</option>   
                        )}
                    </Form.Select>
                </Col>
            </Row>
            <Row className="pt-2 pb-2 border-bottom border-top">
                {/*<Col xs={2} className="fw-bold">Status</Col>*/}
                <Col xs={3} className="fw-bold">Name</Col>
                <Col xs={5} className="fw-bold">Dates</Col>
                <Col xs={4} className="fw-bold">Actions</Col>
            </Row>
            {tournaments && tournaments.map((tournament, index) =>               
            <Row key={index} className="pt-2 pb-2 border-bottom">
            {/*<Col xs={2} className="mt-auto mb-auto">{renderTournamentStatus(tournament.isActive,tournament.startDate,tournament.endDate)}</Col>*/}
                <Col xs={3} className="mt-auto mb-auto">{tournament.name}<br/>{renderTournamentStatus(tournament.isActive,tournament.startDate,tournament.endDate)} 
                {tournament.realPlay === 1 &&
                     <Badge pill bg="none" style={{"backgroundColor": "#ff0000"}}>Real Money</Badge>
                }
                {tournament.mode === 3 &&
                     <Badge pill bg="none" style={{"backgroundColor": "#ffc107", "color":"#000000"}}>Sit And Spin</Badge>
                }
                </Col>
                <Col xs={5} className="mt-auto mb-auto">{getDateLocaleString(tournament.startDate)} - {getDateLocaleString(tournament.endDate)}</Col>
                <Col xs={4} className="mt-auto mb-auto">
                    {hasPrivilege(privileges,"ADMIN_VIEWGAMES") === true &&
                    <Link to={`/viewtournament/${tournament.id}`} ><Button size="sm" className="mb-1 me-1" >View</Button></Link> 
                    }
                    {hasPrivilege(privileges,"ADMIN_VIEWGAMES") !== true &&
                        <>
                        <Link to={`/edittournament/edit/${tournament.id}`} ><Button size="sm" className="mb-1 me-1" >Edit</Button></Link> 
                        <Link to={`/duplicatetournament/duplicate/${tournament.id}`} ><Button size="sm" className="mb-1 me-1" >Duplicate</Button></Link> 
                        </>
                    } 

                    {tournament.mode === 3 ?
                        <Link to={`/sitAndSpinTransactions/${tournament.id}`}><Button size="sm" className="mb-1 me-1">Leaderboard</Button></Link> 

                    :
                     <Link to={`/tournamentLeaderboard/${tournament.id}`}><Button size="sm" className="mb-1 me-1">Leaderboard</Button></Link> 
                    }
                     
                     {tournament.realPlay === 1 &&
                     <>
                     <Link to={`/tournamentTransactions/${tournament.id}`}><Button size="sm" className="mb-1 me-1">Transactions</Button></Link> 
                     <Link to={`/tournamentReconcile/${tournament.id}`}><Button size="sm" className="mb-1 me-1">Reconcile</Button></Link> 
                    </>
                     }
                     {tournament.mode === 3 &&
                     <>
                     <Link to={`/SitAndSpinStatus/${tournament.id}`}><Button size="sm" className="mb-1 me-1">Status</Button></Link> 
                    </>
                     }
                     
                    {hasPrivilege(privileges,"ADMIN_RECONCILE") === true &&
                     <Link to={`/tournamentReconcile/${tournament.id}`}><Button size="sm" className="mb-1 me-1">Reconcile</Button></Link> 
                   
                    }
                </Col>
            </Row>
            )}
            {totalPages && totalPages > 1 &&
            <div className="pt-2"  style={{ display: "flex", justifyContent: "center" }}>
                <Paginator totalPages={totalPages} currentPage={currentPage} onPaginationClick={onPaginationClick}/>

            </div>
        }
        </div>
    )
}

export default Tournaments;