import { useContext, useEffect, useLayoutEffect, useState } from "react";
import UserContext from "../../components/appContext";
import axios from "axios";
import {enqueueSnackbar } from "notistack";
import { Col, Row, Form, Button, Badge, Pagination } from "react-bootstrap";
import { getDateLocaleString, convertUctToLocalTime, convertLocalTimeToUtcDbString, getCurrentLocalTime,formatTime } from "../../utils/timezone";
import { Link, useNavigate, useParams } from "react-router-dom";
import Paginator from "../../components/Paginator";
import moment from 'moment-timezone';

const GameDetails = () =>{
    const [gameDetails, setGameDetails] = useState();
    const [gameHistory, setGameHistory] = useState([]);
    const [gamePlayers, setGamePlayers] = useState([]);
    const {userToken,databaseURL,roles} = useContext(UserContext);
    const navigate = useNavigate();

    const { id,gameid } = useParams();

    useEffect(() =>{ 
        if(id){
            getGameDetails();
        }
    },[])

    const getGameDetails = async()=>{

        return await axios 
            .get(`${databaseURL}/gameplays/${id}`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){

                    setGameDetails(response.data.data[0].gameplay);
                    setGameHistory(response.data.data[0].gameplayHistory);
                    setGamePlayers(response.data.data[0].players)
                } 
            })
            .catch((error)=>{
                console.log("Error getting Games");
                return false;
            })
    }

    return(
        <div className="mt-4">
        <Row className="mb-2">
            <Col xs={6} >
                <h5>Game {gameDetails && gameDetails.name} ({gameDetails && gameDetails.id})</h5>
            </Col>
            <Col xs={6} >
                    {/*<div className="text-end"><Link to={`/gameplays/${playerid}`}><Button size="sm">Back</Button></Link></div>*/}
                    <div className="text-end"><Button size="sm" onClick={() => navigate(-1)}>Back</Button></div>
                </Col>
        </Row>
        <Row className="mb-2">
            <Col xs={12} >
                <div>Game played on {gameDetails && getDateLocaleString(gameHistory[0].playDate)}</div>
                <div>Players:</div>
                <ul>
                {gamePlayers && gamePlayers.map((player, index) => 
                    <li key={index}>{player.name} ({player.playerId}) - {player.points} points</li>
                )}
                </ul>
            </Col>
            
        </Row>
        <Row className="mb-2">
            <Col className="text-start">
                <a href={`${databaseURL}/report/export-players`} className="pe-1"><Button>Battle Export CSV</Button></a> 
            </Col>
        </Row>
        <Row className="pt-2 pb-2 border-bottom border-top">
            <Col xs={2} className="fw-bold">Action</Col>
            <Col xs={2} className="fw-bold">Player</Col>
            <Col xs={2} className="fw-bold">Target</Col>
            <Col xs={2} className="fw-bold">Score</Col>
            <Col xs={2} className="fw-bold">Stops</Col>
            <Col xs={2} className="fw-bold">Time</Col>
        </Row>
        {gameHistory && gameHistory.map((data, index) =>               
        <Row key={index} className="pt-2 pb-2 border-bottom">
            <Col xs={2} className="mt-auto mb-auto">{index}) {data.name}</Col>
            <Col xs={2} className="mt-auto mb-auto"><b>{data.playerName}</b></Col>
            <Col xs={2} className="mt-auto mb-auto"><span style={{"color":"#ff0000"}}>{data.targetPlayerName ? data.targetPlayerName :""}</span></Col>
            <Col xs={2} className="mt-auto mb-auto">{data.playerTotalWinnings}</Col>
            <Col xs={2} className="mt-auto mb-auto">{data.stops}</Col>
            <Col xs={2} className="mt-auto mb-auto">{formatTime(data.playDate)}</Col>
        </Row>
        )}
        
    </div>
    )
}

export default GameDetails;