import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import AuthProvider from './components/AuthProvider';
import Login from './pages/Login';
import Layout from './pages/Layout';
import Tournaments from './pages/tournaments/Tournaments';
import AddTournament from './pages/tournaments/addTournament';
import ViewTournament from './pages/tournaments/viewTournament';
import Players from './pages/players/Players';
import UserList from './pages/Users/UserList';
import UserRoles from './pages/Users/Roles';
import UserActions from './pages/Users/Actions';
import GameClients from './pages/system/gameClients/GameClient';
import Companies from './pages/system/companies/Companies';
import SiteConfig from './pages/system/SiteConfig';
import Weapons from './pages/system/weapons/Weapons';
import { SnackbarProvider } from 'notistack';
import TournamentAwards from './pages/tournaments/TournamentAwards';
import TournamentLeaderboard from './pages/tournaments/TournamentLeaderboard';
import TournamentHistory from './pages/tournaments/TournamentHistory';
import AddGameClient from './pages/system/gameClients/addGameClient';
import NewUser from './pages/Users/NewUser';
import AddWeapon from './pages/system/weapons/addweapon';
import AddCompany from './pages/system/companies/addCompany';
import NewRole from './pages/Users/NewRole';
import AccountDetails from './pages/accountDetails';
import GamePlays from './pages/players/gamePlays';
import GameDetails from './pages/players/gameDetails';
import TournamentTransactions from './pages/tournaments/TournamentTransactions';
import TournamentReconcile from './pages/tournaments/TournamentReconcile';
import SitAndSpinStatus from './pages/tournaments/SitAndSpinStatus';
import SitAndSpinAwards from './pages/tournaments/SitAndSpinAwards';
import SitAndSpinTransactions from './pages/tournaments/SitAndSpinTransactions';



const App = () => {
  return (
    <div className="App">
      <SnackbarProvider />
      <BrowserRouter><AuthProvider>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
              <Route index element={<Dashboard />} />
              <Route path="tournaments" element={<Tournaments />} />
              <Route path="addtournament" element={<AddTournament />} />
              <Route path="edittournament/:pagetype/:id" element={<AddTournament />} />
              <Route path="duplicatetournament/:pagetype/:id" element={<AddTournament />} />
              <Route path="viewtournament/:id" element={<ViewTournament />} />
              <Route path="tournamentAwards/:id" element={<TournamentAwards />} />
              <Route path="tournamentLeaderboard/:id" element={<TournamentLeaderboard />} />
              <Route path="tournamentTransactions/:id" element={<TournamentTransactions />} />
              <Route path="tournamentReconcile/:id" element={<TournamentReconcile />} />
              <Route path="tournamentHistory/:id" element={<TournamentHistory />} />
              <Route path="sitAndSpinStatus/:id" element={<SitAndSpinStatus />} />
              <Route path="sitAndSpinAwards/:id" element={<SitAndSpinAwards />} />
              <Route path="sitAndSpinTransactions/:id" element={<SitAndSpinTransactions />} />
              <Route path="players" element={<Players />} />
              <Route path="gameplays/:id" element={<GamePlays />} />
              <Route path="gamedetails/:id/:gameid" element={<GameDetails />} />
              <Route path="users" element={<UserList />} />
              <Route path="adduser" element={<NewUser />} />
              <Route path="adduser/:id" element={<NewUser />} />
              <Route path="roles" element={<UserRoles />} />
              <Route path="addrole" element={<NewRole />} />
              <Route path="editrole/:id" element={<NewRole />} />
              <Route path="actions" element={<UserActions />} />
              <Route path="gameclients" element={<GameClients />} />
              <Route path="addgameclient" element={<AddGameClient />} />
              <Route path="editgameclient/:id" element={<AddGameClient />} />
              <Route path="weapons" element={<Weapons />} />
              <Route path="editweapon/:id" element={<AddWeapon />} />
              <Route path="companies" element={<Companies />} />
              <Route path="addcompany" element={<AddCompany />} />
              <Route path="editcompany/:id" element={<AddCompany />} />
              <Route path="siteconfig" element={<SiteConfig />} />
              <Route path="account" element={<AccountDetails />} />
            </Route>
          </Routes>
      </AuthProvider></BrowserRouter>

    </div>
  );
}

export default App;
