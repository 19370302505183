import { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import UserContext from "../components/appContext";

const Login = () => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [mfaCode, setMfaCode] = useState('');
    const {showLogin, onLogin, errorMessage, showMFA, onMFA} = useContext(UserContext);
    const [submitPressed, setSubmitPressed] = useState(false);

    //slotmasters
    //87K80X:)HB

    const handleSubmit = (event) => {
        onLogin(userName,password); 
    };

    const handleMFA = (event) => {
        onMFA(mfaCode); 
    };
    return (
        <>
        {showLogin &&
        <div className="ms-auto me-auto border border-1 p-4 mt-3 rounded" style={{maxWidth:"400px", width:"100%"}}>
            <h3>Slotmasters</h3>
            {showMFA === true ?  
                <Form>
                    <div>
                        Enter your One Time Passcode you were emailed
                    </div>
                <Form.Group className="mb-3" controlId="formGroumMFA">
                    <Form.Label>Code</Form.Label>
                    <Form.Control type="text" placeholder="Enter Code" value={mfaCode} onChange={e => setMfaCode(e.target.value )}/>
                </Form.Group>
                
                <div className="text-end">
                <Button variant="primary" onClick={handleMFA}>
                    Login
                </Button></div>
            </Form>
                :
            <Form>
                <Form.Group className="mb-3" controlId="formGroumEmail">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="Username" placeholder="Enter Username" value={userName} onChange={e => setUserName(e.target.value )}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="=formGroupPassowrd">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter Password" value={password} onChange={e => setPassword(e.target.value)} />
                </Form.Group>
                <div className="text-end">
                <Button variant="primary" onClick={handleSubmit}>
                    Login
                </Button></div>
            </Form>
            }
        </div>
        }
       
        </>
    );
}
    
export default Login;