import { useEffect, useState,useContext,useLayoutEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link,useLocation,useNavigate,useParams } from "react-router-dom";
import axios from "axios";
import UserContext from "../../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { hasPrivilege } from "../../../components/privileges";

const AddCompany = () =>{
    const navigate = useNavigate();
    const location = useLocation();
    const [pageTitle, setPageTitle] = useState("Add Company");
    const [gamePageType,setGamePageType] = useState("new");
    const {userToken,databaseURL, privileges} = useContext(UserContext);

    const [companyKey, setCompanyKey] = useState("");
    const [name, setName] = useState("");
    const [walleturl, setWalleturl] = useState("");
    const [dc, setDc] = useState("");
    const [lobbyurl, setLobbyurl] = useState("");
    const [faq, setFaq] = useState("");
    const [language, setLanguage] = useState("default");
    const [template, setTemplate] = useState("default");
    const [botname, setBotname] = useState("");
    const [namemask, setNamemask] = useState("");
    const [maxactivecount, setMaxactivecount] = useState(3);
    const [maxupcomingcount, setMaxupcomingcount] = useState(3);
    const [maxcompletedcount, setMaxcompletedcount] = useState(3);
    const [namemaskOpt, setNamemaskOpt] = useState(0);

    const {id} = useParams();

    useEffect(() =>{
        if(hasPrivilege(privileges,"ADMIN_COMPANIES") === true){
            if(id){
                setPageTitle(`Edit`);
                setGamePageType("edit");
                getCompanyData();
            }
        }
    },[])

    if(hasPrivilege(privileges,"ADMIN_COMPANIES") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/tournaments"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }

    const getCompanyData = async() =>{ 
        return await axios
            .get(`${databaseURL}/companies/${id}`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setCompanyKey(response.data.data[0].companyKey)
                    setName(response.data.data[0].name)
                    setWalleturl(response.data.data[0].walleturl)
                    setDc(response.data.data[0].dc)
                    setLobbyurl(response.data.data[0].lobbyurl)
                    setFaq(response.data.data[0].faq)
                    setLanguage(response.data.data[0].language)
                    setTemplate(response.data.data[0].template)
                    setBotname(response.data.data[0].botname)
                    setNamemask(response.data.data[0].namemask);
                    setNamemaskOpt(response.data.data[0].namemask === "" ? 0 : 1);
                    setMaxactivecount(response.data.data[0].maxactivecount);
                    setMaxupcomingcount(response.data.data[0].maxupcomingcount);
                    setMaxcompletedcount(response.data.data[0].maxcompletedcount);
                    setPageTitle(`Edit ${response.data.data[0].name}`);                      
                  } 
            })
            .catch((error)=>{
                console.log("Error getting Company Data");
                enqueueSnackbar("Error getting  Company Data", {variant: 'error'});
                return false;
            })
    }

    const generateBody = () =>{
        const body = (
            {
                companyKey: companyKey, 
                name: name, 
                walleturl: walleturl,
                dc: dc, 
                lobbyurl: lobbyurl,
                faq: faq,
                language: language,
                template: template,
                botname: botname,
                namemask: namemask,
                maxactivecount:maxactivecount,
                maxupcomingcount:maxupcomingcount,
                maxcompletedcount:maxcompletedcount
          }
        );

        return body;
    }

    const saveCompany = async () =>{
        if(name === ""){
            enqueueSnackbar("Please enter a 'Name'", {variant: 'error'});
            return;
        }

        if(gamePageType === "new"){
            await axios
                .post(`${databaseURL}/companies`, generateBody(),{headers:{
                    'Authorization':`Bearer ${userToken}`
                }})
                .then((response) => {
                    //navigate(`/games/${response.data.data[0].id}`, {state: { data: response.data.data[0] }});
                    enqueueSnackbar("Company created successfully", {variant: 'success'});
                    navigate('/companies', { replace: true, state: { from: location } });

                })
                .catch( async (error) => {
                    enqueueSnackbar("Company creation has failed", {variant: 'error'});
                    console.log(error);

                }) 
        }else{
            await axios
                .put(`${databaseURL}/companies/${id}`, generateBody(),{headers:{
                    'Authorization':`Bearer ${userToken}`
                }})
                .then((response) => {
                    //navigate(`/games/${response.data.data[0].id}`, {state: { data: response.data.data[0] }});
                    enqueueSnackbar("Company updated successfully", {variant: 'success'});
                    navigate('/companies', { replace: true, state: { from: location } });

                })
                .catch( async (error) => {
                    enqueueSnackbar("Company updated has failed", {variant: 'error'});
                    console.log(error);

                }) 
        }
    }

    return(
        <div className="mt-4">
            <Row className="mb-4">
                <Col xs={6} >
                    <h5>{pageTitle}</h5>
                </Col>
                <Col xs={6} >
                    <div className="text-end"><Link to="/companies"><Button size="sm">Back</Button></Link></div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Name
                </Col>
                <Col xs={9}>
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setName(e.target.value )} value={name}></Form.Control>   
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Language
                </Col>
                <Col xs={9}>
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setLanguage(e.target.value )} value={language}></Form.Control>   
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Template
                </Col>
                <Col xs={9}>
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setTemplate(e.target.value )} value={template}></Form.Control>   
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                FAQ
                </Col>
                <Col xs={9}>
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setFaq(e.target.value )} value={faq}></Form.Control>   
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Data Centre
                </Col>
                <Col xs={9}>
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setDc(e.target.value )} value={dc}></Form.Control>   
                </Col>
            </Row>
            
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Wallet Endpoint
                </Col>
                <Col xs={9}>
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setWalleturl(e.target.value )} value={walleturl}></Form.Control>   
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Lobby URL
                </Col>
                <Col xs={9}>
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setLobbyurl(e.target.value )} value={lobbyurl}></Form.Control>   
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Bot name
                </Col>
                <Col xs={9}>
                    <Form.Control type="text" size="sm" style={{maxWidth:"600px",width:"100%"}} onChange={e => setBotname(e.target.value)} value={botname}></Form.Control>  
                    <div style={{fontSize:"10px"}} className="text-muted">Rename BOT player - leave blank for default 'BOT'</div> 
                </Col>
            </Row>
            
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Enable Name Masking
                </Col>
                <Col xs={9}>
                    <Form.Check inline label="On" checked={namemaskOpt === 1 ? true : false} name="activegroup" type="radio" id='acive-on' onChange={(e)=> setNamemaskOpt(1)} />
                    <Form.Check inline label="Off" checked={namemaskOpt === 0 ? true : false} name="activegroup" type="radio" id='active-off' onChange={(e)=> {setNamemaskOpt(0); setNamemask("")}} />
                    
                </Col>
            </Row>
            {namemaskOpt === 1 &&
                   
            <Row className="mt-3 mb-3">
                <Col xs={3} className="text-end">
                    Mask
                </Col>
                <Col xs={9}>
                    <Form.Control style={{maxWidth:"200px"}} className="mb-1" maxLength="25" type="text"  size="sm" onChange={e => setNamemask(e.target.value )} value={namemask}></Form.Control>
                    
                </Col>
                
            </Row>
                    
                    
            }
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Active Games On Lobby
                </Col>
                <Col xs={9}>
                    <Form.Control type="number" size="sm" style={{maxWidth:"200px",width:"100%"}} onChange={e => setMaxactivecount(e.target.value )} value={maxactivecount}></Form.Control>   
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Upcoming Games On Lobby
                </Col>
                <Col xs={9}>
                    <Form.Control type="number" size="sm" style={{maxWidth:"200px",width:"100%"}} onChange={e => setMaxupcomingcount(e.target.value )} value={maxupcomingcount}></Form.Control>   
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3} className="text-end">
                Completed Games On Lobby
                </Col>
                <Col xs={9}>
                    <Form.Control type="number" size="sm" style={{maxWidth:"200px",width:"100%"}} onChange={e => setMaxcompletedcount(e.target.value )} value={maxcompletedcount}></Form.Control>   
                </Col>
            </Row>
            
            
            <Row className="mt-3 mb-3">
                <Col className="text-center"><Button onClick={saveCompany}>Save</Button> <Link to="/companies"><Button variant="secondary">Cancel</Button></Link></Col>
            </Row>
            
        </div>
    )
}

export default AddCompany