import axios from "axios";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../components/appContext";
import {enqueueSnackbar } from "notistack";
import { Col, Row, Form, Button, Badge, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { hasPrivilege } from "../../components/privileges";

const UserList = () => {
    const [users, setUsers] = useState([]);
    const {userToken, databaseURL,privileges} = useContext(UserContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageNum,setPageNum] = useState(1);
   

    useEffect(() =>{
        if(hasPrivilege(privileges,"ADMIN_USERS") === true){
            getUsers();
        }
    },[]);

    if(hasPrivilege(privileges,"ADMIN_USERS") !== true){
        
        return(<div className="mt-4"><Row className="mb-4">
            <Col xs={6} >
                <h5>You don't have access to this page</h5>
            </Col>
            <Col xs={6} >
            
                <div className="text-end"><Link to="/"><Button size="sm">Back</Button></Link></div>
            
            </Col>
        </Row></div>)
    }

    const getRole = (userid) =>{
        switch(userid){
            case 1:
                return "Admin";
            case 2:
                return "ParterAdmin";
            case 3:
                return "customer support";
            default:
                return userid;
        }
    }

    const getUsers = async() =>{ 
        return await axios
            .get(`${databaseURL}/users`,{
                headers:{
                    'Authorization':`Bearer ${userToken}`
                }
            })
            .then((response) =>{
                if(response.status === 200){
                    setUsers(response.data.data);
                  } 
            })
            .catch((error)=>{
                console.log("Error getting Users");
                enqueueSnackbar("Error getting Users", {variant: 'error'});
            
                return false;
            })
    }

    return (
        <div className="mt-4">
            <Row className="mb-2">
                <Col xs={6} >
                    <h5>Manage Users</h5>
                </Col>
                <Col xs={6} >
                    <div className="text-end"><Link to="/adduser"><Button size="sm">Add</Button></Link></div>
                </Col>
            </Row>
            <Row className="pt-2 pb-2 border-bottom border-top">
                {/*<Col xs={2} className="fw-bold">Status</Col>*/}
                <Col xs={4} className="fw-bold">Users</Col>
                <Col xs={4} className="fw-bold">Login Name (Email)</Col>
                <Col xs={2} className="fw-bold">Roles</Col>
                <Col xs={2} className="fw-bold  text-end">Actions</Col>
            </Row>
            {users && users.map((user, index) =>               
            <Row key={index} className="pt-2 pb-2 border-bottom">
            
                <Col xs={4} className="mt-auto mb-auto">{user.firstname} {user.lastname}</Col>
                <Col xs={4} className="mt-auto mb-auto">{user.username}<br/><span style={{fontSize:"0.9rem"}} className="text-muted">{user.email}</span></Col>
                <Col xs={2} className="mt-auto mb-auto">{getRole(user.roleId)}</Col>
                <Col xs={2} className="mt-auto mb-auto text-end">
                    <Link to={`/adduser/${user.id}`} ><Button size="sm" className="mb-1 me-1" variant="secondary">Edit</Button></Link> 
                </Col>
            </Row>
            )}
            
        </div>
    );
}
    
export default UserList;